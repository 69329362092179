<div class="container-fluid ">
    <h3><br>Packs/Groups</h3>
    <hr>
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Filters
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Country</label>
                    <select class="form-control" [formControl]="packForm.get('country')" (change)="countriesChange()">
                        <option value="{{item.code}}" *ngFor="let item of countryList">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Status</label>
                    <select class="form-control" [formControl]="packForm.get('status')">
                        <option value="{{item.id}}" *ngFor="let item of statusList">{{item.name}}</option>
                    </select>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>State</label>
                    <select class="form-control" [formControl]="packForm.get('state')">
                        <option value="{{item.code}}" *ngFor="let item of stateList">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Search</label>
                    <input type="text" class="form-control" [formControl]="packForm.get('search')">
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>&nbsp;</label>
                    <button type="submit " class="btn btn-primary form-control col-12" [disabled]="!countriesListLoaded" (click)="onSearch()">See
                        Results</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Packs
            <app-page-size [value]="pageSize" (changeEvent)="onPageSizeChange($event)" class="float-right" *ngIf="searchWasMade" style="font-size: 1.2em; margin-top: 1px; margin-left:4px;"></app-page-size>
        </div>
        <div class="d-flex justify-content-center" style="padding:10px;" *ngIf="!listLoaded && searchWasMade">
            <div class="spinner-border " role="status">
                <span class="sr-only">...</span>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <p *ngIf="!searchWasMade" class="text-center" style="margin-top: 20px;">
                    Press "See Results" for search
                </p>
                <app-table *ngIf="listLoaded && searchWasMade" [sortColumn]='{name: sortColumn.table, direction: sortDirection}' [data]="packList" (sortClickEvent)="sortClick($event)" (buttonClickEvent)="btnTableClicked($event)" (selectChangeEvent)="editFeedbackSelect($event)"
                    (textareaKeyupEvent)="editFeedback($event)" [columns]="[
                {  
                  title:'Pack Name',
                  name:'_name',
                  type: 'a',
                  sortable: true
                 },
                 {  
                  title:'ID',
                  name:'id',
                  sortable: true
                 },
                 {  
                  title:'Creation Date',
                  name:'_creationDate',
                  sortable: true
                 },
                 {  
                  title:'Country',
                  name:'creationCountryName',
                  sortable: true
                 },
                 {  
                  title:'State',
                  name:'creationStateName',
                  sortable: true
                 },
                 {  
                  title:'Last Run Date',
                  name:'_lastRunDate',
                  sortable: true
                 },
                 {  
                  title:'Current members',
                  name:'usersCount',
                  sortable: true
                 },
                 {  
                  title:'Lifetime members',
                  name:'lifetimeUsers',
                  sortable: true
                 },
                 {  
                  title:'Runs Coming Up',
                  name:'activeRunsCount',
                  sortable: true
                 },
                 {  
                  title:'Pack Creator',
                  name:'_packCreator',
                  type: 'a',
                  sortable: true
                 },
                 {  
                  title:'Email of Creator',
                  name:'alphaUsername',
                  sortable: true
                 },
                 {  
                  title:'Status',
                  name:'_status',
                  sortable: true
                 },
                 {
                        title:'',
                        name:'_buttonEdit',
                        type: 'button'
                 },
                {
                    title:'Actions',
                    name:'_actions',
                    headerAlign: 'center'
                },
                 {
                        title:'',
                        name:'_buttonDelete',
                        type: 'button'
                 } 
                 ]">
                </app-table>
            </div>
            <app-paging *ngIf="listLoaded" [totalCount]="totalCount" [pageLength]="packList.length" [pageNumber]="pageNumber" [totalPages]="totalPages" (pageChangeEvent)="onPageChange($event)">
            </app-paging>
        </div>
    </div>
</div>