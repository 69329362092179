export class PacksRequestModel {
    page: number;
    pageSize: number;
    data = {
        filter: {
            status: null,
            Country: null,
            State: null,
            searchField: '',
        },
        recipients: '',
    };
    sort:Sort[] = [];
}

export class Sort{
    field: string;
    desc: boolean;
}