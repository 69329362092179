import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { GuestGuard } from '../guards/guest.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [GuestGuard]
  },
];

export const AuthenticationRoutes = RouterModule.forChild(routes);
