<div class="main-container">
    <div class="header">
        <h5 class="modal-title">Subscriptions</h5>
        <span type="button" class="header-close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </span>
    </div>
    <div class="row actions-container subscription-container">
        <app-subscription-table [subscriptions]="[subscription]"
                                   [showMenu]="false"
                                   [description]="description"></app-subscription-table>
    </div>
    <div>
        <mat-progress-bar mode="indeterminate" *ngIf="isAnyLoading()"></mat-progress-bar>
    </div>
    <div class="row actions-container">
        <div class="col-lg-12">
            <div class="col-lg-12 col-sm-12 text-center">
                <button mat-button class="btn  btn-border-radius m-l-15 submit-btn"
                        [ngClass]="{'submit-warning': subscription.stripeSubscriptionId && !subscription.isScheduled}"
                        (click)="save(true)" [disabled]="isAnyLoading()" *ngIf="
                            !subscription.stripeSubscriptionId || subscription.isScheduled ||(!subscription.isScheduled &&
                            isNoScheduledSubscription)">
                    <ng-container *ngIf="subscription.stripeSubscriptionId && !subscription.isScheduled">
                        Confirm (Cancel Immediately - QA Use, Only)
                    </ng-container>
                    <ng-container *ngIf="!(subscription.stripeSubscriptionId && !subscription.isScheduled)">
                        Confirm (Cancel Immediately)
                    </ng-container>
                </button>
                <button mat-button class="btn  btn-border-radius m-l-15 submit-btn"
                        *ngIf="subscription.stripeSubscriptionId && !subscription.isScheduled"
                        (click)="save(false)" [disabled]="isAnyLoading()">
                    Confirm (Cancel at the end of current billing month)
                </button>
                <button mat-button class="btn btn-border-radius m-l-15"
                        (click)="close()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
