<div class="container-fluid ">
    <br>
    <h3>
        <a [routerLink]="['/runs']" style="text-decoration: none; color: #212529; opacity: 0.5;">
            Runs
        </a>
        <span>/ Run Library</span>
    </h3>
    <hr>
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Filters
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Country</label>
                    <select class="form-control" [formControl]="runsForm.get('startCountry')">
                        <option value="">All</option>
                        <option *ngFor="let item of withUserCountryList"
                                [value]="item.code">
                            {{item.name}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>State</label>
                    <select class="form-control" [formControl]="runsForm.get('startState')">
                        <option value="">All</option>
                        <option *ngFor="let item of stateList"
                                [value]="item.code">
                            {{item.name}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Search</label>
                    <input type="text" class="form-control" [formControl]="runsForm.get('search')">
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12 offset-md-8" style="float:left;">
                    <label>&nbsp;</label>
                    <button type="submit"
                            class="btn btn-primary form-control col-12"
                            (click)="onSearch()">
                        See Results
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Runs
            <app-page-size *ngIf="searchWasMade"
                           [value]="pageSize"
                           (changeEvent)="onPageSizeChange($event)"
                           class="float-right"
                           style="font-size: 1.2em; margin-top: 1px; margin-left:4px;">
            </app-page-size>
        </div>
        <div class="d-flex justify-content-center" style="padding:10px;" *ngIf="isLoading && searchWasMade">
            <div class="spinner-border " role="status">
                <span class="sr-only">...</span>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <p *ngIf="!searchWasMade" class="text-center" style="margin-top: 20px;">
                    Press "See Results" for search
                </p>
                <app-table *ngIf="!isLoading && searchWasMade"
                           [sortColumn]='{name: sort.column, direction: sort.direction}'
                           [data]="runList"
                           (sortClickEvent)="onSortClick($event)"
                           (buttonClickEvent)="onTableButtonClicked($event)"
                           [columns]="runTableColumnConfig">
                </app-table>
            </div>
            <app-paging *ngIf="!isLoading && searchWasMade"
                        [totalCount]="totalCount"
                        [pageLength]="runList.length"
                        [pageNumber]="page"
                        [totalPages]="totalPages"
                        (pageChangeEvent)="onPageChange($event)">
            </app-paging>
        </div>
    </div>
</div>

<div *ngIf="runModalConfig?.show" class="wp-modal">
    <div class="wp-modal-content justify-content-center col-10" style="margin-top: -80px; padding-bottom: 15px;">
        <div class="wp-modal-header">
            <h3 class="col-12">Edit
                <span class="wp-modal-close" (click)="runModalConfig.show = false">&times;</span>
            </h3>
        </div>
        <div class="wp-modal-body" style="max-height :600px; overflow: auto; overflow-x: scroll;">
            <app-runsdetails [importExportOnly]="true"
                             [hideNote]="true"
                             [importedRunData]="runModalConfig?.runData"
                             (exportData)="onReceiveUpdatedRunData($event)">
            </app-runsdetails>
        </div>
    </div>
</div>
