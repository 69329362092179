import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResult } from 'src/app/models/shared/iresult';
import { UserListRequestModel } from '../model/userlist.requestmodel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemusersService {

  private env: any = environment;

  constructor(private service: ApiService, private http: HttpClient) { }

  getUsers(model: UserListRequestModel): Observable<IResult> {
    return this.service.post('users/filtered/get',
      model
    );
  }

  getUserDetails(userId: string): Observable<IResult> {
    return this.service.post('user/get/by/id',
      { data: { id: userId } }
    );
  }

  processStatus(id) {
    return this.service.post('user/status/process',
      { data: { id } }
    );
  }

  permanentlyDelete(id) {
    return this.service.post('users/get/account/delete/permanently',
      { data: { id } }
    );
  }

  rolledBackProcessStatus(id) {
    return this.service.post('user/status/process/delete/restore',
      { data: { id } }
    );
  }

  deletePermanentUser(id): Observable<IResult> {
    return this.service.post('users/delete',
      {
        data: {
          id,
        }
      }
    );
  }

  getUserPremiumStatus(userId: string): Observable<IResult> {
    return this.service.post('users/payments/info',
      {
        page: 1,
        pageSize: 1,
        data: {
          filter: {
            id: userId
          },
          recipients: []
        }
      }
    );
  }

  makeBasic(userId: string): Observable<IResult> {
    return this.service.post('payment/make/user/basic',
      {
        data: { userId: userId }
      }
    )
  }

  makepremium(userId: string, lastPaymentDate: Date, nextPaymentDate: Date): Observable<IResult> {
    return this.service.post('payment/make/user/premium',
      {
        data:
        {
          vendorType: 4,
          subscriptionType: 3,
          amountPayable: 0,
          userId: userId,
          lastPaymentDate: lastPaymentDate,
          nextPaymentDate: nextPaymentDate,
          premiumTillDate: ""
        }
      }
    )
  }

  addAdminUser(username: string, firstName: string, lastName: string, password: string, phone: string, phoneCountryCode: string): Observable<IResult> {
    return this.service.post('user/register/admin',
      {
        data: { username, firstName, lastName, password, phone, phoneCountryCode }
      }
    );
  }

  deleteUser(id: string, status: string): Observable<IResult> {
    return this.service.post('user/update/info',
      {
        data: { id, status }
      }
    );
  }

  updateUserDetails(id: string, firstName: string, lastName: string, phone?: string, phoneCountryCode?: string): Observable<IResult> {
    const data = {
      id,
      firstName,
      lastName,
      phone,
      phoneCountryCode
    };
    return this.service.post('user/update/info', { data });
  }

  suspendUser(id: string, suspendReason: string): Observable<IResult> {
    return this.service.post('user/suspend',
      {
        data: {
          id: id,
          suspensionReason: suspendReason
        }
      }
    );
  }

  activateUser(id: string): Observable<IResult> {
    return this.service.post('user/activate',
      {
        data: {
          id: id
        }
      }
    );
  }

  editWhiteList(userName: string, status: number): Observable<IResult> {
    //1 in whitelist,
    return this.service.post('user/edit/whitelist',
      {
        data: {
          username: userName,
          status: status
        }
      }
    );
  }

  unblockUser(id: string): Observable<IResult> {
    return this.service.post('user/unblock',
      {
        data: {
          id: id
        }
      }
    );
  }

  getUserSubscriptions(userId: string, isActive?: number): Observable<IResult> {
    return this.service.post('payment/subscriptions',
      { data: { userId: userId, isActive } }
    );
  }

  getUserOrganizations(userId: string): Observable<IResult> {
    return this.service.post('users/get/organization',
      { data: { id: userId } }
    );
  }

  getUserRuns(userId: string, name: string, pageSize: string, sortField: string, desc: boolean): Observable<IResult> {
    return this.service.post('run/search/userToRun',
      {
        data: {
          userId: userId,
          name: name
        },
        pageSize: pageSize,
        sort: [{ field: sortField, desc: desc }]
      }
    );
  }

  getRunFileUrl(runId: string, token: string): string {
    return this.env.apiUrl + `run/locations/file/${token}/${runId}`;
  }

  getUserPayments(userId: string, page: number, pageSize: string, sortField: string, action: string, desc: boolean, otherFilters = {}):
    Observable<IResult> {
    const filter = Object.assign({
        userId,
        searchField: action
      },
      otherFilters
    );
    return this.service.post('payments/get/filtered',
      {
        data: {
          filter
        },
        page: page,
        pageSize: pageSize,
        sort: [{ field: sortField, desc: desc }]
      }
    );
  }


  getUserInvites(inviterId: string, page: number, pageSize: string, sortField: string, packName: string, desc: boolean): Observable<IResult> {
    return this.service.post('pack/get/invitations/filtered',
      {
        data: {
          filter:
          {
            inviterId: inviterId,
            searchField: packName
          }
        },
        page: page,
        pageSize: pageSize,
        sort: [{ field: sortField, desc: desc }]
      }
    );
  }

  getUserPacks(userId: string, page: number, pageSize: string, sortField: string, packName: string): Observable<IResult> {
    return this.service.post('pack/user/get/filtered',
      {
        data: { filter: { userId: userId, packName: packName } },
        page: page,
        pageSize: pageSize,
        sort: [{ field: sortField, desc: true }]
      }
    );
  }

  resetPassword(id) {
    return this.service.post('users/get/account/reset/password',
      { data: { id } }
    );
  }

}

