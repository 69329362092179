import {CommonState} from '../states/common/common.state';
import {CommonActions, ECommonActions} from '../actions/common.actions';

export function commonReducers(state = CommonState, action: CommonActions) {
    switch (action.type) {
        case ECommonActions.SetSidebarCollapseExpand: {
            return {
                ...state,
                menuExpanded: action.payload
            };
        }
        case ECommonActions.SetTopbarCollapseExpand: {
            return {
                ...state,
                topbarExpanded: action.payload
            };
        }
        case ECommonActions.SetLoading: {
            const loading = state.loading + (action.payload ? 1 : -1);
            return {
                ...state,
                loading
            };
        }
        default:
            return state;
    }
}