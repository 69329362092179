import { IUser } from '../../../app/models/user/user';

// export interface IUserState {
//     IUser: IUser
// }
export const UserState: IUser = {
    token: null,
    data: null,
    authenticated: false,
    authenticating: false,
    error: null
}