import {Component, OnInit} from '@angular/core';
import MenuItems from '../../shared/data/MenuItems';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../store/states/app.state';
import * as commonActions from '../../../store/actions/common.actions';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {

  menuItems = [];
  menuExpanded = true;
  topbarExpanded = true;

  constructor(private _store: Store<IAppState>) {
  }

  ngOnInit() {
    this.menuItems = MenuItems;
    this._store.select('common').subscribe(commonState => {
      this.menuExpanded = commonState.menuExpanded;
      this.topbarExpanded = commonState.topbarExpanded;
    });
  }

  hasChildren(index) {
    return this.menuItems && this.menuItems.length > 0 && this.menuItems[index] &&
      this.menuItems[index].children && this.menuItems[index].children.length > 0;
  }

  toggleMenu() {
    this._store.dispatch(new commonActions.SetSidebarCollapseExpand(!this.menuExpanded));
    this._store.dispatch(new commonActions.SetTopbarCollapseExpand(false));
  }

  toggleTopBar() {
    this._store.dispatch(new commonActions.SetSidebarCollapseExpand(false));
    this._store.dispatch(new commonActions.SetTopbarCollapseExpand(!this.topbarExpanded));
  }

}
