import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedComponent } from './feed.component';
import { SharedModule } from 'src/app/shared.module';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { DpDatePickerModule } from 'ng2-date-picker';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        QuillModule,
        DpDatePickerModule
    ],
  declarations: [
    FeedComponent,
  ]
})
export class FeedModule {
}
