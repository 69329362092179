import { Component, OnInit, OnDestroy } from '@angular/core';
import { PacksService } from './service/packs.service';
import { Subscription } from 'rxjs';
import { CountriesResponseModel } from 'src/app/models/shared/countries/countries.responsemodel';
import { CountriesRequestModel } from 'src/app/models/shared/countries/countries.requestmodel';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/api/shared.service';
import { PacksResponseModel } from './model/packs.responsemoldel'
import { PacksRequestModel } from './model/packs.requestmodel'
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-packs',
  templateUrl: './packs.component.html',
  styleUrls: ['./packs.component.css'],
  providers: [DatePipe]
})
export class PacksComponent implements OnInit, OnDestroy {

  packForm: UntypedFormGroup;
  countriesListLoaded: boolean = false; 

  statusList: { id: number, name: string }[];

  countryList: CountriesResponseModel[];
  countryrequest = new CountriesRequestModel;
  countryListSubscription = new Subscription;
  stateList: CountriesResponseModel[];
  statesActive: boolean = false;
  stateListSubscription = new Subscription;
  deleteSubscription = new Subscription;


  packListRequestModel = new PacksRequestModel();
  packListSubscription = new Subscription();
  searchWasMade = false;
  listLoaded = false;
  packList: PacksResponseModel[] = [];
  sortColumn = { post: 'creationTime', table: '_creationTime' };
  sortDirection = true;
  //pagination
  pageNumber = 1;
  pageSize = 10;
  totalCount = 0;
  totalPages = 0;

  constructor(private service: PacksService, private sharedService: SharedService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.packForm = new UntypedFormGroup({
      country: new UntypedFormControl('', [Validators.required]),
      state: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
      status: new UntypedFormControl('', [Validators.required]),
      search: new UntypedFormControl('', [Validators.required]),
    });

    this.statusList = [
      { id: null, name: 'All' },
      { id: 1, name: 'Active' },
      { id: 2, name: 'Deleted' }
    ];
    this.packForm.get('status').setValue(null);

    this.countryListSubscription = this.sharedService.getCountries(this.countryrequest).subscribe(data => {
      this.countryList = data.result.data;
      this.countryList.unshift(
        {
          code: null,
          name: 'All'
        }
      );
      this.packForm.get('country').setValue(null);
      this.packForm.get('state').setValue(null);
      this.countriesListLoaded = true;
    });
  }

  countriesChange() {
    if (this.packForm.controls.country.value === 'US') {
      this.countriesListLoaded = false;

      this.stateListSubscription = this.sharedService.getStates(this.countryrequest).subscribe(data => {
        this.stateList = data.result.data;
        this.stateList.unshift(
          {
            code: null,
            name: 'All',

          }
        );
        this.packForm.get('state').enable();
        this.countriesListLoaded = true;
      });
    } else {
      this.packForm.get('state').setValue(null);
      this.packForm.get('state').disable();
    }
  }

  onSearch() {
    this.pageNumber = 1;
    this.getPacksList();
  }

  getPacksList() {
    this.searchWasMade = true;
    this.listLoaded = false;
    this.packListRequestModel.sort = [];
    this.packListRequestModel.sort.push({ field: this.sortColumn.post, desc: this.sortDirection })
    this.packListRequestModel.pageSize = this.pageSize;
    this.packListRequestModel.page = this.pageNumber;

    this.packListRequestModel.data.filter.Country = this.packForm.controls.country.value  === '' ? null : this.packForm.controls.country.value;
    this.packListRequestModel.data.filter.State = this.packForm.controls.state.value === '' ? null : this.packForm.controls.state.value;
    this.packListRequestModel.data.filter.status = this.packForm.controls.status.value;
    this.packListRequestModel.data.filter.searchField = this.packForm.controls.search.value;

    this.packListSubscription = this.service.getPacks(this.packListRequestModel).subscribe(data => {
      this.packList = data.result.data;
      this.packList.map(x => {
        x._name = { value: x.name, href:  `/packdetails/${x.id}` , target: '_blank', span:  x.status == 2};
        x._creationDate = this.datePipe.transform(x.creationTime, 'MM.dd.yyyy');
        x._lastRunDate = this.datePipe.transform(x.lastRunDate, 'MM.dd.yyyy');
        x._packCreator = { value: x.alphaFirstName + ' ' + x.alphaLastName, href: `/userdetails/${x.alphaId}`, target: '_blank' };
        x._status = this.statusList.find(s => s.id == x.status).name;
        x._buttonEdit = { value: 'Edit', class: 'btn btn-success btn-block', visible: x.status !== 2 };
        x._buttonDelete = { value: 'Delete', class: 'btn btn-danger btn-block' , visible: x.status !== 2 };
      });

      this.totalCount = data.result.totalCount;
      this.totalPages = Math.ceil(this.totalCount / this.pageSize);
      this.listLoaded = true;
    })
  }

  onPageChange(pageNumber: number) {
    if (this.pageNumber === pageNumber) {
      return false;
    }
    this.pageNumber = pageNumber || 1;
    this.getPacksList();
    return false;
  }

  onPageSizeChange(event) {
    this.pageSize = event.value
    if (this.listLoaded) {
      this.pageNumber = 1;
      this.getPacksList();
    }
  }

  sortClick(event) {
    this.sortDirection = event.sort.direction === true;
    switch (event.sort.column) {
      case '_name':
        this.sortColumn.post = 'name';
        this.sortColumn.table = '_name';
        break;
      case '_creationDate':
        this.sortColumn.post = 'creationTime';
        this.sortColumn.table = '_creationDate';
        break;
      case '_lastRunDate':
        this.sortColumn.post = 'lastRunDate';
        this.sortColumn.table = '_lastRunDate';
        break;
      case '_packCreator':
        this.sortColumn.post = 'alphaFirstName';
        this.sortColumn.table = '_packCreator';
        break;
      case '_status':
        this.sortColumn.post = 'status';
        this.sortColumn.table = '_status';
        break;
      default:
        this.sortColumn.post = event.sort.column;
        this.sortColumn.table = event.sort.column;
        break;
    }
    this.getPacksList();
  }

  btnTableClicked(event) {
    switch (event.column.name) {
      case '_buttonDelete':
        Swal.fire({
          title: 'Warning',
          text: "Do you really want to delete Pack: " + event.record.name,
          confirmButtonColor: 'red',
          confirmButtonText: 'delete',
          showCancelButton: true,
          cancelButtonColor: 'blue'
        }).then(data => {
          if (data.value) {
            this.delete(event.record.id);
          }
        })
        break;
      case '_buttonEdit':
        this.edit(event.record.id);
        break;
      default:
        break;
    }
  }

  delete(packId) {
    this.deleteSubscription = this.service.delete(packId).subscribe(data => {
      if (data.success) {
        Swal.fire({
          title: 'Success',
          text: "Pack deleted",
        });
        this.getPacksList();
      }
    })
  }

  edit(packId) {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    window.open(baseUrl + '/packdetails/' + packId, '_blank');
  }

  ngOnDestroy() {
    this.countryListSubscription.unsubscribe();
    this.stateListSubscription.unsubscribe();
    this.packListSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
  }
}
