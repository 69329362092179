import { Component, OnInit, Input, Output } from '@angular/core';
import { CountriesRequestModel } from 'src/app/models/shared/countries/countries.requestmodel';
import { CountriesResponseModel } from 'src/app/models/shared/countries/countries.responsemodel';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserListRequestModel } from '../model/userlist.requestmodel';
import { UserListResponseModel } from '../model/userlist.responsemodel';
import { SystemusersService } from '../service/systemusers.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MustMatchValidator } from '../../../shared/validators/must-match.validator';
import { SharedService } from 'src/app/services/api/shared.service';

@Component({
  selector: 'app-systemusers',
  templateUrl: './systemusers.component.html',
  styleUrls: ['./systemusers.component.css'],
  providers: [DatePipe]
})
export class SystemusersComponent implements OnInit {

  @Input() requestAdmins: boolean = false;
  @Input() buttons: boolean = false;

  term: string;
  statusList: { id: number, name: string }[];
  whiteList: { id: number, name: string }[];
  countryList: CountriesResponseModel[];
  countryrequest = new CountriesRequestModel;
  stateList: CountriesResponseModel[];
  statesActive: boolean = false;

  countryListSubscription = new Subscription;
  countriesListLoaded: boolean = false;
  stateListSubscription = new Subscription;
  userListSubscription = new Subscription;

  filterForm: UntypedFormGroup;
  usersRequestModel = new UserListRequestModel();
  usersResponseModel = new Array<UserListResponseModel>();
  usersLoaded: boolean = false;
  sortColumn = { post: 'firstName', table: '_fullName' };
  sortDirection = false;
  searchWasMade = false;

  // pagination
  pageNumber = 1;
  pageSize = 10;
  totalCount = 0;
  totalPages = 0;

  userForm: UntypedFormGroup;
  modalConfig = {
    show: false,
    addOrEdit: true,
    wasValidated: false,
    title: '',
    isLoading: false
  };

  constructor(
    private service: SystemusersService,
    private sharedService: SharedService,
    private datePipe: DatePipe
  ) {
    this.filterForm = new UntypedFormGroup({
      status: new UntypedFormControl('', [Validators.required]),
      whitelist: new UntypedFormControl('', [Validators.required]),
      search: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      dateFrom: new UntypedFormControl('', [Validators.required]),
      dateTo: new UntypedFormControl('', [Validators.required]),
      state: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
    });
  }

  ngOnInit() {
    this.statusList = [
      { id: null, name: 'All' },
      { id: 1, name: 'Active' },
      { id: 2, name: 'Deleted' },
      { id: 3, name: 'Suspended' },
      { id: 6, name: 'Not Yet Confirmed' },
      { id: 20, name: 'Blocked' },
    ];
    if (!this.requestAdmins) {
      this.filterForm.get('status').setValue(1);
    }

    this.whiteList = [
      { id: null, name: 'All' },
      { id: 1, name: 'Yes' },
      { id: 2, name: 'No' },
    ];

    if (!this.requestAdmins) {
      this.filterForm.get('whitelist').setValue(2);
    }
    this.countryListSubscription = this.sharedService.getCountries(this.countryrequest).subscribe(data => {
      this.countryList = data.result.data;
      this.countryList.unshift(
        {
          code: null,
          name: 'All'
        }
      );
      if (!this.requestAdmins) {
        this.filterForm.get('country').setValue('');
        this.sortColumn = { post: 'registrationDate', table: '_registrationDate' };
        this.sortDirection = true;
      }

      this.countriesListLoaded = true;
    });
  }

  /**
   * @todo retrieve states if selected country is US
   */
  countriesChange() {
    if (this.filterForm.controls.country.value === 'US') {
      this.countriesListLoaded = false;
      this.stateListSubscription = this.sharedService.getStates(this.countryrequest).subscribe(data => {
        this.stateList = data.result.data;
        this.stateList.unshift(
          {
            code: '',
            name: 'All',

          }
        );
        this.filterForm.get('state').enable();
        this.countriesListLoaded = true;
      });
    } else {
      this.filterForm.get('state').setValue('');
      this.filterForm.get('state').disable();
    }
  }

  /**
   * @todo retrieve users list for table
   */
  onSubmitFilter() {
    this.pageNumber = 1;
    this.getUsersList();
  }

  getUsersList() {
    this.searchWasMade = true;
    this.usersLoaded = false;
    this.usersRequestModel.data.status = this.filterForm.controls.status.value === '20' ? null : this.filterForm.controls.status.value;
    this.usersRequestModel.data.isBlocked = this.filterForm.controls.status.value === '20' ? 1 : null;
    this.usersRequestModel.data.whitelist = this.filterForm.controls.whitelist.value || null;
    this.usersRequestModel.data.searchField = this.filterForm.controls.search.value;
    this.usersRequestModel.data.creationCountry = this.filterForm.controls.country.value || null;
    this.usersRequestModel.data.creationState = this.filterForm.controls.state.value === '' ? null : this.filterForm.controls.state.value;
    this.usersRequestModel.data.dateFrom = this.filterForm.controls.dateFrom.value || null;
    this.usersRequestModel.data.dateTo = this.filterForm.controls.dateTo.value || null;
    this.usersRequestModel.pageSize = this.pageSize.toString();
    this.usersRequestModel.page = this.pageNumber;
    this.usersRequestModel.data.isAdmin = this.requestAdmins;
    this.usersRequestModel.sort[0].desc = this.sortDirection;
    this.usersRequestModel.sort[0].field = this.sortColumn.post;
    if (this.requestAdmins) {
      this.usersRequestModel.data.status = '1';
    } else {
      this.usersRequestModel.data.secondarySort = 'firstName';
    }

    this.userListSubscription = this.service.getUsers(this.usersRequestModel).subscribe(data => {
      this.usersResponseModel = data.result.data;

      this.usersResponseModel.map(x => {
        x._fullName = { value: x.firstName + ' ' + x.lastName, class: 'text-secondary' };
        x._registrationDate = this.datePipe.transform(x.registrationDate, 'MM.dd.yyyy');
        x._lastSessionUseTime = this.datePipe.transform(x.lastSessionUseTime, 'MM.dd.yyyy');
        if (!this.requestAdmins) {
          x._buttonDetails = { value: 'Details', class: 'btn btn-success' };
        } else {
          x._buttonEdit = { value: 'Edit', class: 'btn btn-primary' };
          x._buttonDelete = { value: 'Delete', class: 'btn btn-danger' };
        }
      });
      this.totalCount = data.result.totalCount;
      this.totalPages = Math.ceil(this.totalCount / this.pageSize);
      this.usersLoaded = true;
    });
  }

  onPageChange(pageNumber: number) {
    if (this.pageNumber === pageNumber) {
      return false;
    }
    this.pageNumber = pageNumber || 1;
    this.getUsersList();
    return false;
  }

  onPageSizeChange(event) {
    this.pageSize = event.value;
    if (this.usersLoaded) {
      this.pageNumber = 1;
      this.getUsersList();
    }
  }

  detailsClicked(event) {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    window.open(baseUrl + '/userdetails/' + event.record.id, '_blank');
  }

  sortClick(event) {
    this.sortDirection = event.sort.direction === true;
    switch (event.sort.column) {
      case '_fullName':
        this.sortColumn.post = 'firstName';
        this.sortColumn.table = '_fullName';
        break;
      case '_registrationDate':
        this.sortColumn.post = 'registrationDate';
        this.sortColumn.table = '_registrationDate';
        break;
      case '_lastSessionUseTime':
        this.sortColumn.post = 'lastSessionUseTime';
        this.sortColumn.table = '_lastSessionUseTime';
        break;
      default:
        this.sortColumn.post = event.sort.column;
        this.sortColumn.table = event.sort.column;
        break;
    }
    this.getUsersList();
  }

  editClicked(event) {
    switch (event.column.name) {
      case '_buttonEdit':
        this.onAdminAddOrEdit(false, event.record);
        break;
      case '_buttonDelete':
        this.onAdminDelete(event.record.id);
        break;
      default:
        break;
    }
  }

  onAdminAddOrEdit(addOrEdit: boolean, record?: any) {
    const fields: any = {
      id: new UntypedFormControl(addOrEdit ? '' : record.id),
      firstName: new UntypedFormControl(addOrEdit ? '' : (record.firstName || ''), [Validators.required]),
      lastName: new UntypedFormControl(addOrEdit ? '' : (record.lastName || ''), [Validators.required]),
      phoneCountryCode: new UntypedFormControl(addOrEdit ? '' : (record.phoneCountryCode || '')),
      phone: new UntypedFormControl(addOrEdit ? '' : (record.phone || ''))
    };
    if (addOrEdit) {
      fields.email = new UntypedFormControl('', [Validators.required, Validators.email]);
      fields.password = new UntypedFormControl('', [Validators.required, Validators.minLength(8)]);
      fields.passwordConfirm = new UntypedFormControl('', [Validators.required, Validators.minLength(8), MustMatchValidator('password')]);
    } else {
      if (record.phoneCountryCode) {
        fields.phone = new UntypedFormControl(addOrEdit ? '' : (record.phone || ''), Validators.required);
      }
    }
    this.userForm = new UntypedFormGroup(fields);

    this.userForm.get('phoneCountryCode').valueChanges.subscribe(value => {
      const phoneControl = this.userForm.get('phone');
      if (value) {
        phoneControl.setValidators(Validators.required);
      } else {
        phoneControl.clearValidators();
      }
      this.userForm.get('phone').updateValueAndValidity();
    });

    this.modalConfig = {
      addOrEdit,
      show: true,
      wasValidated: false,
      title: addOrEdit ? '' : (record.firstName || '') + ' ' + (record.lastName || ''),
      isLoading: false
    };
  }

  onAdminAddOrEditSubmit() {
    if (this.userForm.valid) {
      const values = this.userForm.value;
      this.modalConfig.isLoading = true;
      if (this.modalConfig.addOrEdit) {
        this.service.addAdminUser(
          values.email,
          values.firstName,
          values.lastName,
          values.password,
          values.phone || null,
          values.phoneCountryCode || null
        ).subscribe(response => {
          if (response.result.data[0]) {
            this.modalConfig.show = false;
            this.onSubmitFilter();
            Swal.fire(
              'Administrator added successfully',
              '',
              'success'
            );
          }
        }, () => {
          this.modalConfig.isLoading = false;
        });
      } else {
        this.service.updateUserDetails(
          values.id,
          values.firstName,
          values.lastName,
          values.phone || null,
          values.phoneCountryCode || null
        ).subscribe(response => {
          if (response.result.data[0]) {
            this.modalConfig.show = false;
            Swal.fire(
              'Administrator updated successfully',
              '',
              'success'
            );
            this.onSubmitFilter();
          }
        }, () => {
          this.modalConfig.isLoading = false;
        });
      }
    }
  }

  onAdminDelete(id) {
    Swal.fire({
      title: 'Do you really want to delete user?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.service.deleteUser(id, '2').subscribe(response => {
          if (response.result.data[0]) {
            Swal.fire(
              'Deleted!',
              'Administrator deleted successfully',
              'success'
            );
            this.onSubmitFilter();
          }
        });
      }
    });
  }

  onCloseModal() {
    this.modalConfig.show = false;
  }

  ngOnDestroy() {
    this.stateListSubscription.unsubscribe();
    this.countryListSubscription.unsubscribe();
    this.userListSubscription.unsubscribe();
  }
}
