import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAppState } from 'src/store/states/app.state';
import { Store, select } from '@ngrx/store';
import * as requestActions from '../../../../store/actions/request.actions';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    /**
     * @param string
     */
    private token:string = null;

    /**
     *
     * @param _store
     */
    constructor(private _store: Store<IAppState>) {
        this._store.select('user').subscribe(({token}) => {
            this.token = token;
        });
    }

    private cloneFormData(formData) {
      const cloneFormData = new FormData();
      formData.forEach((value, key) => {
        cloneFormData.append(key, value);
      });
      return cloneFormData;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       /**
        * set requesting true
        */
        this._store.dispatch(new requestActions.Setrequesting);
        let newBody;
        if (request.body instanceof FormData) {
          newBody = this.cloneFormData(request.body);
          newBody.append('token', this.token);
        } else {
          newBody =  Object.assign(request.body ? request.body : {},
            { token: this.token });
        }

        const duplicate = request.clone({
          headers: request.headers.set('access_token', this.token).set('Authorization', this.token),
          body: newBody // Object.assign(request.body ? request.body : {}, { token: this.token })
        });

        /**
         * set requesting false
         */
        this._store.dispatch(new requestActions.RemoveRequesting)
        return this.token ? next.handle(duplicate) : next.handle(request);
    }
}
