export default [
  {
    id: 1,
    title: 'Home',
    icon: 'home',
    route: '/welcome'
  },
  {
    title: 'WolfPack',
    icon: 'home',
    children: [
      {
        title: 'Users',
        icon: 'user',
        route: '/users/system'
      },
      {
        title: 'Feedback',
        icon: 'comment-alt',
        route: '/feedback'
      },
      {
        title: 'Administrators',
        icon: 'users-cog',
        route: '/users/admin'
      },
      {
        title: 'Packs/Groups',
        icon: 'users',
        route: '/packs'
      },
      {
        title: 'Runs',
        icon: 'motorcycle',
        route: '/runs'
      },
      {
        title: 'What`s New',
        icon: 'newspaper',
        route: '/whatsnew'
      },
     /* {
        title: 'System Administration',
        icon: 'cog',
        route: '/administration'
      },*/
      /*{
        title: 'FAQ',
        icon: 'question-circle',
        route: '/faq'
      },*/
      {
        title: 'Feed Management',
        icon: 'cog',
        route: '/feed'
      }
    ]
  },
  {
    title: 'WolfPack Central',
    icon: 'home',
    children: [
      {
        title: 'Subscribers',
        icon: 'user',
        route: '/users/subscribers'
      }
    ]
  }
];
