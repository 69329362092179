import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class LogService {

  private doLog(msg, logFn) {
    if (!environment.production) {
      logFn = logFn || console.log;
      logFn(new Date());
      logFn(msg);
    }
  }

  log(msg) {
    this.doLog(msg, console.log);
  }

  warn(msg) {
    this.doLog(msg, console.warn);
  }

  error(msg) {
    this.doLog(msg, console.error);
  }

}
