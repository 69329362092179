import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { Observable } from 'rxjs';
import { IResult } from 'src/app/models/shared/iresult';
import { PacksRequestModel } from '../model/packs.requestmodel';

@Injectable({
  providedIn: 'root'
})
export class PacksService {

  constructor(private service: ApiService) { }

  getPacks(model: PacksRequestModel): Observable<IResult> {
    return this.service.post('pack/get/filtered',
      {
        data: {
          filter: {
            creationCountry: model.data.filter.Country,
            creationState: model.data.filter.State,
            searchField: model.data.filter.searchField,
            status: model.data.filter.status,
            secondarySort: null,
            dateFrom: null,
            dateTo: null,
            whitelist: "2",
          },
          recipients: null
        },
        page: model.page,
        pageSize: model.pageSize.toString(),
        sort: [{ field: model.sort[0].field, desc: model.sort[0].desc }]
      }
    );
  }

  delete(packId: number): Observable<IResult> {
    return this.service.post('pack/delete',
      {
        data: { id: packId }
      }
    )
  }

  details(packId: number): Observable<IResult> {
    return this.service.post('pack/get',
      {
        data: { id: packId }
      }
    )
  };

  update(packId: number, name: string, alphaId: string): Observable<IResult> {
    return this.service.post('pack/edit',
      {
        data: { id: packId, name: name, alpha: { id: alphaId } }
      }
    )
  };
}
