import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SharedService } from '../../../services/api/shared.service';
import { RunsService } from '../service/runs.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-run-library',
  templateUrl: './run-library.component.html',
  styleUrls: ['./run-library.component.scss'],
  providers: [DatePipe]
})
export class RunLibraryComponent implements OnInit, OnDestroy {

  runsForm: UntypedFormGroup;
  countryList = [];
  withUserCountryList = [];
  countryMap = new Map();
  stateList = [];
  runList = [];
  totalCount = 0;
  runTableColumnConfig = [
    {
      title: 'Run Name',
      name: 'name',
      sortable: true
    },
    {
      title: 'Creation Date',
      name: '_creationDate',
      sortable: true
    },
    {
      title: 'Start Country',
      name: '_startCountryName',
      sortable: true
    },
    {
      title: 'Start Point (Address)',
      name: 'startingPointName',
      sortable: true
    },
    {
      title: 'Destination Country',
      name: '_endCountryName',
      sortable: true
    },
    {
      title: 'Destination Address',
      name: 'endingPointName',
      sortable: true
    },
    {
      title: 'Rating',
      name: 'rating',
      sortable: true
    },
    {
      title: 'Number of users providing ratings',
      name: 'totalUsersGaveRating',
      sortable: true
    },
    {
      title: 'Scenic score',
      name: 'scenic',
      sortable: true
    },
    {
      title: 'Twisty Score',
      name: 'twisty',
      sortable: true
    },
    {
      title: 'Actions',
      name: '_actions',
      headerAlign: 'center',
      direction: 'horizontal',
      type: 'buttonGroup',
      buttons: [
        {
          name: '_buttonEdit'
        },
        {
          name: '_buttonDelete'
        }
      ]
    }
  ];
  searchWasMade = false;
  isLoading = false;
  sort = {
    column: 'creationDate',
    direction: true
  };
  page = 1;
  pageSize = 10;
  totalPages: number;

  runModalConfig = {
    show: false,
    runData: {}
  };

  loadCountryListSubscription: any;
  loadWithUsersCountryListSubscription: any;
  loadStateListSubscription: any;
  loadRunsSubscription: any;
  updateRunSubscription: any;
  deleteSubscription: any;

  constructor(private sharedService: SharedService,
              private runsService: RunsService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.runsForm = new UntypedFormGroup({
      startCountry: new UntypedFormControl(''),
      startState: new UntypedFormControl({value: '', disabled: true}),
      endCountry: new UntypedFormControl(''),
      endState: new UntypedFormControl({value: '', disabled: true}),
      search: new UntypedFormControl('')
    });
    this.runsForm.get('startCountry').valueChanges.subscribe(val => {
      const state = this.runsForm.get('startState');
      state.setValue('');
      if (val === 'US') {
        state.enable();
      } else {
        state.disable();
      }
    });
    this.runsForm.get('endCountry').valueChanges.subscribe(val => {
      const state = this.runsForm.get('endState');
      state.setValue('');
      if (val === 'US') {
        state.enable();
      } else {
        state.disable();
      }
    });
    //
    this.loadCountryListSubscription = this.sharedService.getAllCountries().subscribe(response => {
      if (response.success) {
        this.countryList = response.result.data;
        this.countryList.forEach(c => {
          this.countryMap.set(c.code, c.name);
        });
      }
    });
    this.loadWithUsersCountryListSubscription = this.sharedService.getRunCountries().subscribe(response => {
      if (response.success) {
        this.withUserCountryList = response.result.data;
      }
    });
    this.loadStateListSubscription = this.sharedService.getRunStates().subscribe(response => {
      if (response.success) {
        this.stateList = response.result.data;
      }
    });
  }

  onSearch() {
    this.page = 1;
    this.searchWasMade = true;
    this.sort = {
      column: 'creationDate',
      direction: true
    };
    this.loadRuns();
  }

  loadRuns() {
    if (this.loadRunsSubscription) {
      this.loadRunsSubscription.unsubscribe();
    }
    //
    const payload = {
      page: this.page,
      pageSize: this.pageSize,
      data: {
        filter: {
          name: this.runsForm.get('search').value,
          startCountry: this.runsForm.get('startCountry').value || null,
          startState: this.runsForm.get('startState').value || null,
          endCountry: this.runsForm.get('endCountry').value || null,
          endState: this.runsForm.get('endState').value || null,
          status: 1,
          type: 2
        },
        recipients: []
      },
      sort: [{
        field: this.sort.column || 'creationDate',
        desc: this.sort.direction || false
      }]
    };
    //
    this.runList = [];
    this.totalCount = 0;
    this.isLoading = true;
    this.loadRunsSubscription = this.runsService.getCurationRuns(payload).subscribe(response => {
      this.runList = response.result.data;
      this.totalCount = response.result.totalCount;
      this.totalPages = Math.ceil(this.totalCount / this.pageSize);
      this.isLoading = false;
      this.runList.forEach(x => {
        x._creationDate = x.creationDate ? this.datePipe.transform(x.creationDate, 'MM.dd.yyyy') : '';
        x._startCountryName = this.countryMap.get(x.startCountry);
        x._endCountryName = this.countryMap.get(x.endCountry);

        x._actions = {
          buttons: [
            {
              name: '_buttonEdit',
              value: 'Edit',
              class: 'btn btn-warning btn-block'
            },
            {
              name: '_buttonDelete',
              value: 'Delete',
              class: 'btn btn-danger btn-block'
            }
          ]
        };
      });
    });
  }

  onEditRun(record) {
    this.runModalConfig.show = true;
    record.waypoints = record.curationWaypoints;
    this.runModalConfig.runData = record;
  }

  onReceiveUpdatedRunData(runData) {
    const payload = {
      avoidFerries: runData.avoidFerries,
      avoidHighways: runData.avoidHighways,
      avoidTolls: runData.avoidTolls,
      curationWaypoints: (runData.waypoints || []).map((x, i) => ({
        lat: x.lat,
        lon: x.lon,
        ord: i + 1,
        ordLetter: i + 1,
        title: x.title,
        country: x.country,
        state: x.state
      })),
      distance: runData.distance,
      endingPointLatitude: runData.endingPointLatitude,
      endingPointLongitude: runData.endingPointLongitude,
      endingPointName: runData.endingPointName,
      id: runData.id,
      name: runData.name,
      startingPointLatitude: runData.startingPointLatitude,
      startingPointLongitude: runData.startingPointLongitude,
      startingPointName: runData.startingPointName,
      startCountry: runData.startCountry,
      startState: runData.startState,
      endCountry: runData.endCountry,
      endState: runData.endState
    };
    //
    this.updateRunSubscription = this.runsService.updateCurationRun(payload).subscribe(response => {
      if (response.success) {
        this.runModalConfig.show = false;
        this.loadRuns();
        //
        Swal.fire(
          'Run updated Successfully',
          '',
          'success'
        );
      }
    });
  }

  onDeleteRun(id) {
    Swal.fire({
      title: 'Do you really want to delete Run?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.deleteSubscription = this.runsService.deleteCurationRun(id).subscribe(response => {
          if (response && response.success) {
            Swal.fire(
              'Successfully Deleted',
              '',
              'success'
            );
            this.loadRuns();
          }
        });
      }
    });
  }

  onSortClick(e) {
    this.sort.direction = e.sort.direction === true;
    switch (e.sort.column) {
      case '_creationDate':
        this.sort.column = 'creationDate';
        break;
      case '_startCountryName':
        this.sort.column = 'startCountry';
        break;
      case '_endCountryName':
        this.sort.column = 'endCountry';
        break;
      default:
        this.sort.column = e.sort.column;
        break;
    }
    this.loadRuns();
  }

  onTableButtonClicked(e) {
    switch (e.column.name) {
      case '_actions':
        if (e.btnName === '_buttonEdit') {
          this.onEditRun(e.record);
        } else if (e.btnName === '_buttonDelete') {
          this.onDeleteRun(e.record.id);
        }
        break;
      default:
        break;
    }
  }

  onPageSizeChange(e) {
    this.pageSize = parseInt(e.value, 10);
    this.page = 1;
    this.loadRuns();
  }

  onPageChange(pageNumber: number) {
    if (this.page === pageNumber) {
      return false;
    }
    this.page = pageNumber || 1;
    this.loadRuns();
    return false;
  }

  //

  ngOnDestroy() {
    if (this.loadCountryListSubscription) {
      this.loadCountryListSubscription.unsubscribe();
    }
    if (this.loadWithUsersCountryListSubscription) {
      this.loadWithUsersCountryListSubscription.unsubscribe();
    }
    if (this.loadStateListSubscription) {
      this.loadStateListSubscription.unsubscribe();
    }
    if (this.loadRunsSubscription) {
      this.loadRunsSubscription.unsubscribe();
    }
    if (this.updateRunSubscription) {
      this.updateRunSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

}
