import { Pipe, PipeTransform } from '@angular/core';
import {PostStatusEnum} from '../../enums/post-status.enum';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: number): string {
    return PostStatusEnum[value];
  }

}
