export class SingleRunUpdateModel {
    alphaUserId: string;
    avoidFerries: number;
    avoidHighways: number;
    avoidTolls: number;
    endingPointLatitude: number;
    endingPointLongitude: number;
    id: string;
    name: string;
    note: string;
    startingPointLatitude: number;
    startingPointLongitude: number;
    startDate: number;
    endDate: number;
    waypoints: WayPoint[] = [];
    isSolo: number;
}

export class WayPoint {
    country: string;
    id: number;
    lat: number;
    lon: number;
    ord: number;
    ordLetter: number;
    reached: number;
    runId: number;
    state: string;
    status: number;
    title: string;
}