import { Injectable }  from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/store/states/app.state';
import * as userActions from 'src/store/actions/user.actions';
import { IUser } from './models/user/user';

@Injectable()
export class AppInitService {
 
    constructor(private store: Store<IAppState>) {
    }
    
    /**
     * app initializer 
     */
    Init() {
        return new Promise<void>((resolve, reject) => {
            this.checkUser();
            resolve();
        });
    }

    /**
     * check localstorage for user data
     */
    checkUser(){
        let user = localStorage.getItem("user");
        if (user) { //user exists
           var userobj: IUser = JSON.parse(user);
            if (userobj.token) { //token exists
                this.store.dispatch(new userActions.SetUserData(userobj)); //set user from localstorage to store
            }
        }
    }
}