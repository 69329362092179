import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SubscriptionTiersService } from '../../../services/subscription-tiers.service';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../../services/organization.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import Swal from 'sweetalert2';
import {BillingTypeEnum} from '../../../enums/billing-type.enum';
import {MatDialog} from '@angular/material/dialog';
import {StatusStringEnum} from '../../../enums/status-string.enum';
import {toSubscriptionCancellationReasonTypeEnum} from '../../../enums/subscription-cancellation-reason-type.enum';
import {Router} from '@angular/router';
import {
  SubscriptionSuspendConfirmationComponent
} from '../../../components/organization-details/components/subscription-suspend-confirmation/subscription-suspend-confirmation.component';
import {
  SubscriptionActivationConfirmationComponent
} from '../../../components/organization-details/components/subscription-activation-confirmation/subscription-activation-confirmation.component';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class SubscribersComponent implements OnInit, OnDestroy {
  searchForm;
  isLoading = false;
  pageNumber = 0;
  pageSize = 30;
  totalElements = 0;
  totalPages = 0;
  sortColumn = {
    name: '_organizationName',
    direction: true
  };
  resultList = [];
  searchWasMade = false;
  subscriptionTiers = [];
  statuses = [
    {
      value: null,
      label: 'All'
    },
    {
      value: 'SUSPENDED',
      label: 'Suspended'
    },
    {
      value: 'ACTIVE',
      label: 'Active'
    }
  ];
  subscriptionStatuses = [
    {
      value: null,
      label: 'All'
    },
    {
      value: '1',
      label: 'Active subscriptions'
    },
    {
      value: '0',
      label: 'Inactive subscriptions'
    },
  ];
  whitelist = [
    {
      value: null,
      label: 'All'
    },
    {
      value: 'REGULAR',
      label: 'No'
    },
    {
      value: 'WHITE_LISTED',
      label: 'Yes'
    }];
  subscriptionTiersConverter = [''];
  subscriptionTiersSubscription = new Subscription();
  subscriptionTierUpdate = new Subscription();
  subscriptionBillingUpdate = new Subscription();
  organizationSubscription;
  organizationForm: UntypedFormGroup;
  organizationModalConfig = {
    show: false,
    wasValidated: false,
    isLoading: false
  };

  constructor(private organizationService: OrganizationService,
              public dialog: MatDialog,
              private subscriptionTiersService: SubscriptionTiersService,
              private datePipe: DatePipe,
              private router: Router,
              private titleCasePipe: TitleCasePipe) {
  }

  ngOnInit() {
    this.searchForm = new UntypedFormGroup({
      registrationDateFrom: new UntypedFormControl(''),
      registrationDateTo: new UntypedFormControl(''),
      tierId: new UntypedFormControl(0),
      orgName: new UntypedFormControl(''),
      whitelist: new UntypedFormControl(undefined),
      status: new UntypedFormControl(undefined),
      subscriptionStatus: new UntypedFormControl(undefined)
    });
    this.subscriptionTiersSubscription = this.subscriptionTiersService.getData().subscribe(
      res => {
        if (res.result.data) {
          this.subscriptionTiers = res.result.data;
          this.subscriptionTiers.forEach(x => {
            this.subscriptionTiersConverter[x.id] = x.name;
          });
        }
      }
    );
  }

  private processSubscriptionMessage(subscription) {
    return `<div>
            <span>${subscription?.paidPeriodStartDate ? this.datePipe.transform(subscription?.paidPeriodStartDate, 'MM/dd/yyyy') : ''}</span>
            <span>-</span>
            <span>${this.datePipe.transform(subscription?.paidPeriodEndDateBuffer, 'MM/dd/yyyy')}</span>
            <span>${subscription?.organizationSubscriptionTierName}</span>
            <span><b>${toSubscriptionCancellationReasonTypeEnum(subscription?.cancelReason)}
                ${this.datePipe.transform(subscription?.scheduledCancelDate, 'MM/dd/yyyy') || ''} </b></span>
        </div><br/>`;
  }

  dataConvert() {
    this.resultList = this.resultList.map(x => {
      x._organizationName = {
        value: x.name,
        href: `/org-details/${x.id}`,
        target: '_blank'
      };
      x._fullName = x.creator ? x.creator.firstName + ' ' + x.creator.lastName : '';
      x._username = x.creator ? x.creator.username : '';
      x._creationDate = x.creationDate ? this.datePipe.transform(x.creationDate, 'MM/dd/yyyy') : '';
      x._tier = x.subscriptionTierName;
      x._subscriptionStatus = x.subscriptionStatus;
      const isActive = x.status === 'ACTIVE';
      x._buttonSuspend = {
        value: isActive ? 'Suspend' : 'Activate',
        class: 'btn btn-' + (isActive ? 'danger' : 'success')
      };
      x._subscriptionDetails = {
        value: 'Billing',
        class: 'btn btn-info'
      };
      x._paymentCollectionUpdateStatus = {
        value: 'View payment collection status',
        class: 'btn btn-' + ('success'),
        disabled: !x.stripeCustomerId || x.status !== StatusStringEnum.ACTIVE,
        tooltip: {
          disabled: 'Stripe customer id is not found or status of organization is not ACTIVE',
          info: 'Payment collection is used for Stripe customers, use if no need collect usage for sending invoice'
        }
      };
      x._billingType = {
        value: x.billingType || 'Not Defined',
        class: 'btn btn-' + ('success'),
        tooltip: {
          info: 'AUTO_STRIPE - if Stripe customer, in other case - AUTO; MANUAL type - we don\'t suspend organization with MANUAL type'
        }
      };
      return x;
    });
  }

  onSearch() {
    const queryParams = {
      ...this.searchForm.value,
      page: this.pageNumber, // pages from this endpoint starts from 0
      size: this.pageSize
    };
    if (queryParams.whitelist === 'null') {
      delete queryParams.whitelist;
    }
    if (queryParams.statua === 'null') {
      delete queryParams.statua;
    }
    if (queryParams.subscriptionStatus === 'null') {
      delete queryParams.subscriptionStatus;
    }
    queryParams.tierId = parseInt(queryParams.tierId, 10) || null;
    if (this.sortColumn.direction !== null) {
      switch (this.sortColumn.name) {
        case '_organizationName':
          queryParams.sort = 'name';
          break;
        case '_username':
          queryParams.sort = 'creator.username';
          break;
        case '_creationDate':
          queryParams.sort = 'creationDate';
          break;
        case '_tier':
          queryParams.sort = 'subscriptionTierName';
          break;
        default:
          queryParams.sort = this.sortColumn.name;
      }
      queryParams.sort += ',' + (this.sortColumn.direction ? 'asc' : 'desc');
    }
    //
    this.isLoading = true;
    this.searchWasMade = true;
    if (this.organizationSubscription) {
      this.organizationSubscription.unsubscribe();
    }
    this.organizationSubscription = this.organizationService.getOrganizations(queryParams).subscribe(
      response => {
        const data = response.result.data[0];
        if (data) {
          this.resultList = data.content;
          this.dataConvert();
          this.totalElements = data.totalElements;
          this.totalPages = data.totalPages;
        }
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionTiersSubscription.unsubscribe();
    if (this.subscriptionBillingUpdate) {
      this.subscriptionBillingUpdate.unsubscribe();
    }
    if (this.organizationSubscription) {
      this.organizationSubscription.unsubscribe();
    }
  }

  onPageChange(pageNumber: number) {
    pageNumber = pageNumber ? pageNumber - 1 : 0;
    if (this.pageNumber === pageNumber) {
      return;
    }
    this.pageNumber = pageNumber;
    this.onSearch();
  }

  onPageSizeChange(e) {
    this.pageSize = e.value;
    if (!this.isLoading && this.searchWasMade) {
      this.pageNumber = 0;
      this.onSearch();
    }
  }

  onSort(e) {
    this.sortColumn = {
      name: e.sort.column,
      direction: e.sort.direction
    };
    this.onSearch();
  }

  updateStatus = (id, status, record, result, callback?, errorcallback?) => {
    this.organizationService.activateSuspend(id, status, result?.forceUpdate, result.priceId).subscribe(response => {
      if (response.success) {
        this.resultList.forEach(x => {
          if (x.id === record.id) {
            x.status = status;
          }
          return x;
        });
        this.dataConvert();
        Swal.fire(
          'Organization was successfully updated',
          '',
          'success'
        );
        if (callback) {
          callback();
        }
        this.onSearch();
      }
    }, () => {
      if (errorcallback) {
        errorcallback();
      }
    });
  }

  showOrganizationSuspendSubscriptions(updateStatus, record) {
    this.dialog.open(SubscriptionSuspendConfirmationComponent, {
      data: {
        record,
        updateStatus,
      },
      disableClose: true,
    });
  }

  onSubscriberButtonClick({event, column, record}) {
    if (column.name === '_buttonSuspend') {
      const status = record.status;
      const newStatus = status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';
      if (status === 'ACTIVE') {
        this.showOrganizationSuspendSubscriptions((result, callback, errorcallback) => {
          this.updateStatus(record.id, newStatus, record, result, callback, errorcallback);
        }, {
          ...record,
          organizationId: record.id,
        });
      } else {
        this.showOrganizationActivationSubscriptions((result, callback, errorcallback) => {
          this.updateStatus(record.id, newStatus, record, result, callback, errorcallback);
        }, {
          ...record,
          organizationId: record.id,
        });
      }
    } else if (column.name === '_billingType') {
      Swal.fire({
          title: 'Billing',
          input: 'select',
          inputValue: record.billingType,
          inputOptions: record.stripeCustomerId ?
            {
              AUTO_STRIPE: BillingTypeEnum.AUTO_STRIPE,
              MANUAL: BillingTypeEnum.MANUAL
            } : {
              AUTO: BillingTypeEnum.AUTO,
            },
        inputPlaceholder: 'Select billing type',
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve();
            } else {
              resolve('You need to select Billing Type');
            }
          });
        }
      }).then((result) => {
        if (result.value) {
          const id = record.id;
          this.subscriptionBillingUpdate =
            this.organizationService.updateBillingType(id, result.value).subscribe(
            res => {
              this.onSearch();
            }
          );
        }
      });
    }
  }

  private showOrganizationActivationSubscriptions(updateStatus, record) {
    this.dialog.open(SubscriptionActivationConfirmationComponent, {
      data: {
        tiers: this.subscriptionTiers,
        record,
        selectedSubscriptions: [],
        updateStatus
      },
      disableClose: true,
    });
  }

}
