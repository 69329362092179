import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-subscription-table',
  templateUrl: './subscription-table.component.html',
  styleUrls: ['./subscription-table.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class SubscriptionTableComponent implements OnInit, OnDestroy {
  @Input()
  subscriptions;
  @Input()
  showMenu = true;
  @Input()
  loading = {};
  @Input()
  description;
  now = new Date();
  @Output()
  resumePaymentCollection = new EventEmitter<any>();
  @Output()
  pausePaymentCollection = new EventEmitter<any>();
  @Output()
  cancelSubscription = new EventEmitter<any>();
  @Output()
  stopCancelSubscription = new EventEmitter<any>();

  constructor() {
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {

  }

  copyId(id) {
    navigator.clipboard.writeText(id);
  }
}
