<div>
    {{ description }}
</div>
<table class="table">
    <thead>
    <tr>
        <th scope="col" class="text-center">From</th>
        <th scope="col" class="text-center">To</th>
        <th scope="col" class="text-center">Subscription tier</th>
        <th scope="col" class="text-center">Details</th>
        <th scope="col" class="text-center">Stripe ID</th>
        <th scope="col" class="text-center"></th>
        <th scope="col" class="text-center"></th>
    </tr>
    </thead>
    <tbody>
        <tr *ngFor="let subscription of subscriptions">
            <td class="text-center">{{subscription?.paidPeriodStartDate | date}}</td>
            <td class="text-center">{{subscription?.paidPeriodEndDateBuffer | date}}</td>
            <td class="text-center">{{subscription?.organizationSubscriptionTierName}}</td>
            <td class="text-center">
                <div *ngIf="subscription?.cancelDate" class="text-details">
                    <span><b>Cancel date: </b></span>
                    <span>{{subscription?.cancelDate | date}}</span>
                </div>
                <div *ngIf="subscription?.cancelComments" class="text-details">
                    <span><b>Cancel comments: </b></span>
                    <span>{{subscription?.cancelComments}}</span>
                </div>
            </td>
            <td class="text-center">
                <div class="td-button-container">
                    {{subscription?.stripeSubscriptionId || subscription?.stripeScheduledSubscriptionId}}
                    <button *ngIf="subscription?.stripeSubscriptionId || subscription?.stripeScheduledSubscriptionId"
                            class="copy-small-icon" mat-icon-button
                            type="button"
                            (click)="copyId(subscription?.stripeSubscriptionId || subscription?.stripeScheduledSubscriptionId)" matTooltip="Copy">
                        <mat-icon class="copy-small-icon">content_copy</mat-icon>
                    </button>
                </div>
            </td>
            <td class="text-center">
                <app-label type="active" title="Active"
                           *ngIf="subscription.isActive && ((now >= subscription.paidPeriodStartDate) && (now <= subscription.paidPeriodEndDateBuffer))">
                </app-label>
                <app-label
                    type="scheduled" title="Scheduled"
                    *ngIf="subscription.isActive && subscription.isScheduled">
                </app-label>
                <app-label type="cancellation" title="Collection paused" *ngIf="subscription.paymentCollectionStatus">
                </app-label>
                <app-label type="paused" [title]="'Cancel on ' + ((subscription?.cancelDate || subscription?.scheduledCancelDate) | date)"
                           *ngIf="subscription.isActive && subscription.isCancelAtPeriodEnd">
                </app-label>
                <app-label type="cancellation" [title]="'Canceled'"
                           *ngIf="!subscription.isActive">
                </app-label>
            </td>
            <td class="text-center" *ngIf="showMenu && subscription">
                <button mat-button [matMenuTriggerFor]="menu" type="button" *ngIf="subscription.isActive"
                        [disabled]="loading.subscription || loading.organization || subscription.loading">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="!subscription?.cancelDate && subscription?.isActive && !subscription.isCancelAtPeriodEnd"
                            (click)="cancelSubscription.emit(subscription)">Cancel subscription</button>
                    <button mat-menu-item *ngIf="!subscription?.cancelDate && subscription?.isActive && subscription.isCancelAtPeriodEnd"
                            (click)="stopCancelSubscription.emit(subscription)">Remove Scheduled Cancellation</button>
                    <ng-container *ngIf="subscription?.paymentCollectionStatus">
                        <button mat-menu-item (click)="resumePaymentCollection.emit(subscription)">Resume Payment Collection</button>
                    </ng-container>
                    <ng-container *ngIf="!subscription?.paymentCollectionStatus">
                        <button mat-menu-item (click)="pausePaymentCollection.emit(subscription)">Pause payment collection</button>
                    </ng-container>
                </mat-menu>
            </td>
        </tr>
    </tbody>
</table>
