<div class="container-fluid" style="padding-top:20px;" *ngIf="packDetails">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Pack
        </div>
        <div class="card-body">
            <form [formGroup]="packForm" (ngSubmit)="onSubmitFilter()">
                <div class="form-row">
                    <div class="form-group col-md-2 col-sm-12">
                        <img [src]="domSanitizer.bypassSecurityTrustResourceUrl(packDetails.pictureUrl)" class="col-sm-12" />
                    </div>
                    <div class="form-group col-md-5 col-sm-12">
                        <div class="form-group col-12">
                            <label>Name</label>
                            <input type="text" class="form-control" [formControl]="packForm.get('name')" />
                        </div>
                        <div class="form-group col-12">
                            <label>Alpha</label>
                            <select class="form-control" [formControl]="packForm.get('alphaId')">
                <option value="{{item.id}}" *ngFor="let item of packDetails.activeUsers">{{item.username}}</option>
              </select>
                        </div>
                        <div class="form-group col-md-6 col-sm-12 float-left">
                            <button type="button" class="btn btn-primary form-control col-12" (click)="update()">Update</button>
                        </div>
                        <div class="form-group col-md-6 col-sm-12 float-left">
                            <button type="button" class="btn btn-danger form-control col-12" (click)="delete()">Delete</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="container-fluid " *ngIf="packDetails">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            <span *ngIf="!requestAdmins">List of Users in Pack</span>
            <button *ngIf="requestAdmins" (click)="onAdminAddOrEdit(true)" class="btn btn-success float-left">Add New
      </button>
            <app-page-size [value]="pageSize" (changeEvent)="onPageSizeChange($event)" *ngIf="searchWasMade" class="float-right" style="font-size: 1.2em; margin-top: 1px; margin-left:4px;"></app-page-size>
        </div>
        <div class="d-flex justify-content-center" style="padding:10px;" *ngIf="!usersLoaded && searchWasMade">
            <div class="spinner-border " role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- users -->
        <div class="card-body" style="margin-top:-21px;">
            <div class="table-responsive">
                <app-table [data]="packDetails.activeUsers" [columns]="[
                                        
                                         {
                                             title:'Name',
                                             name:'_name',
                                             type: 'a',
                                             sortable: false
                                          },
                                          {
                                            title:'ID',
                                            name:'id',
                                            sortable: false
                                         },
                                          {
                                            title:'EMail',
                                            name:'username',
                                            sortable: false
                                         }
                                          ]">
                </app-table>
            </div>
        </div>
    </div>
</div>