import {Component, OnInit} from '@angular/core';
import {OrganizationService} from '../../services/organization.service';
import {ActivatedRoute} from '@angular/router';
import {LogService} from '../../services/log.service';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {BillingTypeEnum} from '../../enums/billing-type.enum';
import { SubscriptionType } from 'src/app/enums/subscription.type';
import {MatDialog} from '@angular/material/dialog';
import {
  SubscriptionSuspendConfirmationComponent
} from './components/subscription-suspend-confirmation/subscription-suspend-confirmation.component';
import {
  SubscriptionActivationConfirmationComponent
} from './components/subscription-activation-confirmation/subscription-activation-confirmation.component';
import {SubscribersTierComponent} from './components/subscription-tier/subscribers-tier.component';
import {
  SubscriptionCancellationConfirmationComponent
} from './components/subscription-cancellation-confirmation/subscription-cancellation-confirmation.component';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent implements OnInit {

  isLoading = true;
  organization: any;
  managers = 0;
  inWhiteList = false;
  whiteListSubscription: Subscription;
  loaded = { subscriptionDates: false, processed: false, organizationDetails: false };
  SubscriptionType = SubscriptionType;
  now = new Date().getTime();
  loading = {
    tiers: false,
    subscription: false,
    subscriptionActive: false,
    save: false,
    organization: false,
  };
  subscriptions: any[] = [];
  activeSubscriptionsTierPricesId: number[] = [];
  activeSubscriptions: any[] = [];
  subscriptionSettings = {
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0
  };
  constructor(private organizationService: OrganizationService,
              private activeRoute: ActivatedRoute,
              private logger: LogService,
              private dialog: MatDialog, ) {
  }

  ngOnInit() {
    const orgId = this.activeRoute.snapshot.paramMap.get('orgId');
    this.organizationService.getById(parseInt(orgId, 10)).subscribe(res => {
      if (res.result && res.result.data && res.result.data[0]) {
        this.isLoading = false;
        this.organization = res.result.data[0];
        this.inWhiteList = res.result.data[0].whiteList === 'WHITE_LISTED';
        this.managers = this.organization && this.organization.managers ? this.organization.managers.length : 0;
        this.logger.log(this.organization);
      }
    });
    this.loadOrganizationDetails(orgId);
    this.loadOrganizationSubscriptions(orgId);
    this.loadOrganizationActiveSubscriptions(orgId);
  }

  showOrganizationSuspendSubscriptions(updateStatus) {
    this.dialog.open(SubscriptionSuspendConfirmationComponent, {
      data: {
        record: this.organization,
        updateStatus,
      },
      disableClose: true,
    });
  }

  showOrganizationActivateSubscriptions(updateStatus) {
    this.dialog.open(SubscriptionActivationConfirmationComponent, {
      data: {
        record: this.organization,
        updateStatus,
      },
      disableClose: true,
    });
  }

  editSuspend() {
    const status = this.organization.status;
    const newStatus = status === 'Active' ? 'SUSPENDED' : 'ACTIVE';
    const updateStatus = (id, statusV, forceUpdate?, callback?, errorCallback?) => {
      this.organizationService.activateSuspend(id, statusV, forceUpdate).subscribe(response => {
        if (response.success) {
          this.organization.status = statusV;
          Swal.fire(
            'Organization was successfully updated',
            '',
            'success'
          );
          if (callback)  {
            callback();
          }
        }
      }, () => {
        if (errorCallback) {
          errorCallback();
        }
      });
    };
    if (status === 'Active') {
      this.showOrganizationSuspendSubscriptions((result, callback, errorcallback) => {
        updateStatus(this.organization.organizationId, newStatus, result?.forceUpdate, callback, errorcallback);
      });
    } else {
      this.showOrganizationActivateSubscriptions((result, callback, errorcallback) => {
        updateStatus(this.organization.organizationId, newStatus, result?.forceUpdate, callback, errorcallback);
      });
    }
  }

  editBillingType() {
    Swal.fire({
      title: 'Billing',
      input: 'select',
      inputValue: this.organization.billingType,
      inputOptions: this.organization.stripeOrganization ?
        {
          AUTO_STRIPE: BillingTypeEnum.AUTO_STRIPE,
          MANUAL: BillingTypeEnum.MANUAL
        } : {
          AUTO: BillingTypeEnum.AUTO,
        },
      inputPlaceholder: 'Select billing type',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            resolve();
          } else {
            resolve('You need to select Billing Type');
          }
        });
      }
    }).then((result) => {
      if (result.value) {
        const id = this.organization.organizationId;
        this.organizationService.updateBillingType(id, result.value).subscribe(
            res => {
              this.organization.billingType = result.value;
            }
          );
      }
    });
  }

  editWhiteList() {
    this.loaded.processed = true;
    this.whiteListSubscription = this.organizationService.editWhiteList(this.organization.organizationId, this.inWhiteList ? 'REGULAR' : 'WHITE_LISTED').subscribe(data => {
      this.inWhiteList = !this.inWhiteList;
      this.loaded.processed = false;
    }, () => {
      this.loaded.processed = false;
    });
  }

  private loadOrganizationDetails(orgId) {
    this.loading.organization = true;
    this.organizationService.getById(parseInt(orgId, 10)).subscribe(res => {
      if (res.result && res.result.data && res.result.data[0]) {
        this.loading.organization = false;
        this.organization = res.result.data[0];
      }
    }, () => {
      this.loading.organization = false;
    });
  }

  stopCancelSubscription(subscription) {
    Swal.fire({
      text: 'This subscription will no longer be canceled on the scheduled date. Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        subscription.loading = true;
        this.organizationService.resetCancellationSubscription(
          subscription.id).subscribe(() => {
          subscription.loading = false;
          this.loadOrganizationSubscriptions(this.organization.organizationId);
          this.loadOrganizationActiveSubscriptions(this.organization.organizationId);
        }, () => {
          subscription.loading = false;
        });
      }
    });
  }

  cancelSubscription(subscription) {
    this.dialog.open(SubscriptionCancellationConfirmationComponent, {
      data: {
        record: this.organization,
        subscription,
        subscriptions: this.activeSubscriptions,
        updateStatus: (result, callback, errorcallback) => {
          subscription.loading = true;
          this.organizationService.cancelSubscription(subscription.id, result.forceUpdate)
            .subscribe(resp => {
              subscription.loading = false;
              if (callback) {
                callback();
              }
              this.loadOrganizationSubscriptions(this.organization.organizationId);
              this.loadOrganizationActiveSubscriptions(this.organization.organizationId);
            }, () => {
              subscription.loading = false;
              if (errorcallback) {
                errorcallback();
              }
            });
        },
      },
      disableClose: true,
    });
  }

  addSubscription() {
    this.dialog.open(SubscribersTierComponent, {
      data: {
        record: this.organization,
        selectedSubscriptions: this.activeSubscriptionsTierPricesId,
        updateStatus: (result, callback, errorcallback) => {
          this.organizationService
            .startSubscription(this.organization.organizationId, result.priceId)
            .subscribe(() => {
              callback();
              this.loadOrganizationSubscriptions(this.organization.organizationId);
              this.loadOrganizationActiveSubscriptions(this.organization.organizationId);
            }, () => {
              errorcallback();
            });
        }
      },
      disableClose: true,
    });
  }

  private loadOrganizationActiveSubscriptions(orgId) {
    this.loading.subscriptionActive = true;
    this.organizationService.getSubscriptions(
      orgId,
      1,
      this.subscriptionSettings.pageNumber,
      this.subscriptionSettings.pageSize, 'paidPeriodStartDate,desc')
      .subscribe(resp => {
        this.loading.subscriptionActive = false;
        this.activeSubscriptionsTierPricesId = (resp.result.data[0].content || []).map(s => s.stripePlanId);
        this.activeSubscriptions = (resp.result.data[0].content || []);
      }, () => {
        this.loading.subscriptionActive = false;
      });
  }

  private loadOrganizationSubscriptions(orgId) {
    this.loading.subscription = true;
    this.organizationService.getSubscriptions(
      orgId,
      undefined,
      this.subscriptionSettings.pageNumber,
      this.subscriptionSettings.pageSize, 'paidPeriodStartDate,desc')
      .subscribe(resp => {
        this.loading.subscription = false;
        this.subscriptions = resp.result.data[0].content || [];
        this.subscriptionSettings.totalCount = resp.result.data[0].totalElements;
        this.subscriptionSettings.totalPages = resp.result.data[0].totalPages;
      }, () => {
        this.loading.subscription = false;
      });
  }

  onSubscriptionPageChange($event) {
    this.subscriptionSettings.pageNumber = $event - 1;
    this.loadOrganizationSubscriptions(this.organization.organizationId);
  }

  resumePaymentCollection(subscription) {
    this.handlePaymentCollection('Resume payment collection', subscription, 'This will tell Stripe to resume collecting payment starting with the next invoice at the end of the billing month. This and further payments will return to being automatic. Are you sure?');
  }

  pausePaymentCollection(subscription) {
    this.handlePaymentCollection('Pause payment collection', subscription, 'This will maintain the organization’s stripe subscription, but payment will not be collected on invoices until a WolfPack admin resumes (either here or on Stripe directly). Are you sure?');
  }


  private handlePaymentCollection(title: string, subscription, description) {
    Swal.fire({
      title,
      text: description,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        subscription.loading = true;
        this.organizationService.updatePaymentCollection(
          this.organization.organizationId,
          subscription.id)
          .subscribe((response) => {
            subscription.loading = false;
            this.loadOrganizationSubscriptions(this.organization.organizationId);
          }, () => {
            subscription.loading = false;
          });
      }
    });
  }
}
