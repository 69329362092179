import { Component, OnInit, OnDestroy } from '@angular/core';
import { IAppState } from 'src/store/states/app.state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-holdingpage',
  templateUrl: './holdingpage.component.html',
  styleUrls: ['./holdingpage.component.css']
})
export class HoldingpageComponent implements OnInit, OnDestroy {

  firstName: string;
  firstnameSubscription = new Subscription;

  constructor(private _store: Store<IAppState>) {}

  ngOnInit() {
    this.firstnameSubscription = this._store.select('user').subscribe(({ data }) => {
      if(data)
        this.firstName = data.firstName;
    });
  }

  ngOnDestroy() {
    this.firstnameSubscription.unsubscribe();
  }
}
