export enum SubscriptionCancellationReasonTypeEnum {
    CANCEL_REASON_NOT_PAID = 1,
    CANCEL_REASON_STOPPED = 2,
    CANCEL_REASON_SCHEDULED_SUBSCRIPTION_STOPPED = 3,
    CANCEL_REASON_TRIAL_END= 4,
    CANCEL_REASON_ADMIN_SUSPEND = 5,
    CANCEL_REASON_USER_REQUEST = 6,
}

export function toSubscriptionCancellationReasonTypeEnum(type) {
  switch (type) {
    case SubscriptionCancellationReasonTypeEnum.CANCEL_REASON_NOT_PAID: {
      return 'Not paid';
    }
    case SubscriptionCancellationReasonTypeEnum.CANCEL_REASON_STOPPED: {
      return 'Stopped';
    }
    case SubscriptionCancellationReasonTypeEnum.CANCEL_REASON_SCHEDULED_SUBSCRIPTION_STOPPED: {
      return 'Scheduled subscription stopped';
    }
    case SubscriptionCancellationReasonTypeEnum.CANCEL_REASON_TRIAL_END: {
      return 'Trial end';
    }
    case SubscriptionCancellationReasonTypeEnum.CANCEL_REASON_ADMIN_SUSPEND: {
      return 'Admin suspend';
    }
    case SubscriptionCancellationReasonTypeEnum.CANCEL_REASON_USER_REQUEST: {
      return 'Admin suspend';
    }
    default: {
      return '';
    }
  }
}
