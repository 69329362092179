import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { OrganizationService } from '../../../../services/organization.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-subscription-suspend-confirmation',
  templateUrl: './subscription-suspend-confirmation.component.html',
  styleUrls: ['./subscription-suspend-confirmation.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class SubscriptionSuspendConfirmationComponent implements OnInit, OnDestroy {
  loading = {
    subscription: false,
    save: false,
  };
  subscriptionPriceId;
  subscriptions = [];
  description;
  organizationSubscriptionsSubscriber: Subscription;

  constructor(private organizationService: OrganizationService,
              public dialogRef: MatDialogRef<SubscriptionSuspendConfirmationComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA)
              public data: any) {
  }

  getDescription() {
      return 'Access to Central will be suspended. Manager cannot update subscription. '+
        'He needs to contact admin. It will not cancel subscriptions. Subscriptions should be handled separately';
  }

  ngOnDestroy(): void {
    this.cancelSubscriptionRequest();
  }

  private cancelSubscriptionRequest() {
    if (this.organizationSubscriptionsSubscriber) {
      this.organizationSubscriptionsSubscriber.unsubscribe();
    }
  }

  ngOnInit() {
    this.loading.subscription = true;
    this.cancelSubscriptionRequest();
    this.organizationSubscriptionsSubscriber = this.organizationService.getSubscriptions(
      this.data.record.organizationId,
      1,
      0,
      10, 'paidPeriodStartDate,desc')
      .subscribe(resp => {
        this.loading.subscription = false;
        this.subscriptions = (resp.result.data[0].content || []);
        this.description = this.getDescription();
        this.subscriptions.sort((a, b) => a.id - b.id);
      }, () => {
        this.loading.subscription = false;
      });
  }

  public save() {
    this.loading.save = true;
    this.data.updateStatus({}, () => {
      this.loading.save = false;
      this.dialogRef.close();
    }, () => {
      this.loading.save = false;
    });
  }

  public close() {
    this.dialogRef.close();
  }

  isAnyLoading() {
    return this.loading.save || this.loading.subscription;
  }

}
