import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SharedModule } from 'src/app/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {OrganizationDetailsComponent} from './organization-details.component';
import {SubscribersTierComponent} from './components/subscription-tier/subscribers-tier.component';
import {SubscriptionTableComponent} from './components/subscription-table/subscription-table.component';
import {
  SubscriptionCancellationConfirmationComponent
} from './components/subscription-cancellation-confirmation/subscription-cancellation-confirmation.component';
import {
  SubscriptionSuspendConfirmationComponent
} from './components/subscription-suspend-confirmation/subscription-suspend-confirmation.component';
import {
  SubscriptionActivationConfirmationComponent
} from './components/subscription-activation-confirmation/subscription-activation-confirmation.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    MatDialogModule,
    MatProgressBarModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatIconModule,
  ],
  declarations: [
    OrganizationDetailsComponent,
    SubscribersTierComponent,
    SubscriptionTableComponent,
    SubscriptionCancellationConfirmationComponent,
    SubscriptionSuspendConfirmationComponent,
    SubscriptionActivationConfirmationComponent
  ],
  exports: [OrganizationDetailsComponent]
})
export class OrganizationDetailsModule {
}
