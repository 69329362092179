import { NgModule } from '@angular/core';
import { TableComponent } from 'src/app/components/table/table.component';
import { PagingComponent } from './components/paging/paging.component';
import { BrowserModule } from '@angular/platform-browser';
import { PageSizeComponent } from './components/page-size/page-size.component';
import {StatusPipe} from './shared/pipes/status.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CardContainerComponent} from './shared/components/card/card-container/card-container.component';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {LabelComponent} from './shared/components/label/label.component';

@NgModule({
  imports: [
    BrowserModule,
    MatTooltipModule,
    MatDialogModule,
    MatProgressBarModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule
  ],
  declarations: [
    TableComponent,
    PagingComponent,
    PageSizeComponent,
    StatusPipe,
    CardContainerComponent,
    LabelComponent
  ],
  exports: [
    TableComponent,
    PagingComponent,
    PageSizeComponent,
    StatusPipe,
    CardContainerComponent,
    LabelComponent,
    MatDialogModule,
    MatProgressBarModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule
  ],
})
export class SharedModule { }
