import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { HoldingpageComponent } from './components/holdingpage/holdingpage.component';
import { SystemusersdetailsComponent } from './modules/users/systemusersdetails/systemusersdetails.component';
import { UsersComponent } from './modules/users/users.component';
import { FeedbackComponent } from './modules/feedback/feedback.component';
import { OrganizationDetailsComponent } from './components/organization-details/organization-details.component';
import { WhatsnewComponent } from './modules/whatsnew/whatsnew.component';
import { RunsComponent } from './modules/runs/runs.component';
import { PacksComponent } from './modules/packs/packs.component';
import { PackdetailesComponent } from './modules/packs/packdetailes/packdetailes.component';
import { RunLibraryComponent } from './modules/runs/run-library/run-library.component';
import {FeedComponent} from './modules/feed/feed.component';

const routes: Routes = [
  { path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' },
  {
    path: 'welcome',
    component: HoldingpageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'userdetails/:id',
    component: SystemusersdetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'users/:term',
    component: UsersComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'org-details/:orgId',
    component: OrganizationDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'whatsnew',
    component: WhatsnewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'feed',
    component: FeedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'runs',
    component: RunsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'run-library',
    component: RunLibraryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'packs',
    component: PacksComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'packdetails/:id',
    component: PackdetailesComponent,
    canActivate: [AuthGuard]
  },
  /*{
    path: 'administration',
    component: AdministrationComponent,
    canActivate: [AuthGuard]
  }*//*,
  {
    path: 'faq',
    component: FAQComponent,
    canActivate: [AuthGuard]
  }*/
  // {
  // {
  //   path: '**',
  //   redirectTo: 'dashboard',
  //   // canActivate: [AuthGuard]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
