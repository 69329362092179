import { Component, OnInit, OnDestroy, ViewChild, ComponentRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { SystemusersService } from './service/systemusers.service';
import { SystemusersComponent } from './systemusers/systemusers.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

  term: string;
  isadmin: string = "admin";
  termSubscription = new Subscription;
  navigationSubscription = new Subscription;

  constructor(private service: SystemusersService, private activeroute: ActivatedRoute, private router: Router) {
    this.initialiseInvites();
  }

  ngOnInit() {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
    this.termSubscription = this.activeroute.params.subscribe(data => this.term = data.term);
    switch (this.term) {
      case 'system':
        this.isadmin = "system"; 
        break;
      case 'admin':
        this.isadmin = "admin";
        break;
        case "subscribers":
        this.isadmin = "subscribers";
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.termSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }
}