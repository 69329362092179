import { Component, OnInit, Inject } from '@angular/core';
import * as userActions from '../../../store/actions/user.actions';
import * as commonActions from '../../../store/actions/common.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/states/app.state';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  isFullScreen: boolean = false;
  menuExpanded: boolean;
  topbarExpanded: boolean;
  elem;

  constructor(private _store: Store<IAppState>, @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this._store.select('common').subscribe(commonState => {
      this.menuExpanded = commonState.menuExpanded;
      this.topbarExpanded = commonState.topbarExpanded;
    });
  }

  toggleMenu() {
    this._store.dispatch(new commonActions.SetSidebarCollapseExpand(!this.menuExpanded));
  }

  logout() {
    /**
     * @todo starting effect 'removeuserdata' to remove data from storage and logout
     */
    this._store.dispatch(new userActions.RemoveUserDataStorage());
  }


  changeSize() {
    this.isFullScreen !== true ? this.openFullscreen() : this.closeFullscreen();
  }

  openFullscreen() {
    try {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
      this.isFullScreen = true;
    } catch {
      this.isFullScreen = false;
    }
  }

  closeFullscreen() {
    try {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
      this.isFullScreen = false;
    } catch {
      this.isFullScreen = true;
    }
  }
}