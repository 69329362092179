<table class="table">
    <thead>
    <tr [style.font-size.em]="fontSize">
        <th *ngFor="let column of columns" scope="col" class="text-{{column.headerAlign || 'left'}}"
            [class.sortable]="column.sortable && !this.draggable" (click)="sortClick($event, column)">
            {{column?.title}}
            <i *ngIf="column.sortable && !this.draggable"
               class="fa fa-long-arrow-alt-up"
               [class.sorted]="column.asc"></i>
            <i *ngIf="column.sortable && !this.draggable"
               class="fa fa-long-arrow-alt-down"
               [class.sorted]="column.desc"></i>
        </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let record of data" [style.font-size.em]="fontSize">
        <td *ngFor="let column of columns" class="text-{{column.dataAlign || 'left'}}">
            <div *ngIf="column.type === 'button' && record[column.name]?.visible !== false">
                <div class="button-container">
                    <span *ngIf="record[column.name]?.tooltip?.disabled && !!record[column.name]?.disabled"  (click)="showInfo(record[column.name]?.tooltip?.disabled)">
                        <img src="assets/images/info-disabled.svg" class="disabled-icon"/>
                    </span>
                    <span *ngIf="record[column.name]?.tooltip?.info && !record[column.name]?.disabled" (click)="showInfo(record[column.name]?.tooltip?.info)">
                            <img src="assets/images/info.svg" class="disabled-icon"/>
                        </span>
                    <button (click)="buttonClick($event, column, record)"
                            [style.font-size.em]="fontSize"
                            [class]="record[column.name]?.class"
                            [disabled]="!!record[column.name]?.disabled"
                    >
                        {{record[column.name]?.value}}
                    </button>
                </div>
            </div>
            <div *ngIf="column.type === 'buttonGroup'"
                 [style.width.px]="(column.direction === 'horizontal' ? (column.buttons.length) * 100 : 100)"
                 [class.row]="column.direction === 'horizontal'"
            >
                <div *ngFor="let btn of record[column.name].buttons; index as i;"
                     [class.col]="column.direction === 'horizontal'"
                     style="padding: 3px;"
                >
                    <div class="button-container">
                         <span *ngIf="record[column.name]?.tooltip?.disabled && !!record[column.name]?.disabled"  (click)="showInfo(record[column.name]?.tooltip?.disabled)">
                            <img src="assets/images/info-disabled.svg" class="disabled-icon"/>
                        </span>
                        <span *ngIf="record[column.name]?.tooltip?.info && !record[column.name]?.disabled" (click)="showInfo(record[column.name]?.tooltip?.info)">
                            <img src="assets/images/info.svg" class="disabled-icon"/>
                        </span>
                        <button (click)="buttonClick($event, column, record, btn.name)"
                                [style.font-size.em]="fontSize"
                                [class]="record[column.name].buttons[i]?.class"
                                [disabled]="!!record[column.name].buttons[i]?.disabled"
                        >
                            {{record[column.name].buttons[i]?.value}}
                        </button>

                    </div>

                </div>
            </div>
            <div *ngIf="column.type === 'select'">
                <select (change)="selectChange($event, column, record)"
                        [class]="record[column.name]?.class"
                        [style.font-size.em]="fontSize"
                >
                    <option *ngFor="let rec of (column.data || [])"
                            [value]="rec.value"
                            [selected]="record[column.name]?.value?.toString() === rec?.value?.toString()"
                    >
                        {{rec.label}}
                    </option>
                </select>
            </div>
            <div *ngIf="column.type === 'textarea'">
                <textarea (keyup)="textareaKeyup($event, column, record)"
                          [rows]="column.rows"
                          [cols]="column.cols"
                          [class]="record[column.name]?.class"
                >{{record[column.name]}}</textarea>
            </div>
            <div *ngIf="column.type === 'a'">
                    <span *ngIf="record[column.name]?.span"
                          [style.font-size.em]="fontSize"
                          [class]="record[column.name]?.class"
                    >
                        {{record[column.name]?.value}}
                    </span>
                <a *ngIf="!record[column.name]?.span"
                   [href]="record[column.name]?.href"
                   [style.font-size.em]="fontSize"
                   [class]="record[column.name]?.class"
                   [target]="record[column.name]?.target"
                   (click)="hrefClick($event, column, record)"
                >
                    {{record[column.name]?.value}}
                </a>
            </div>
            <div *ngIf="column.type === 'span'">
                <span [class]="record[column.name]?.class">{{record[column.name]?.value}}</span>
            </div>
            <div *ngIf="!column.type" [class]="record[column.name]?.class || column?.class">
                <ng-container *ngIf="column.pipe">
                    <ng-container *ngIf="column.pipe === 'status'">
                        <ng-container *ngIf="record[column.name]?.class">{{record[column.name].value | status}}</ng-container>
                        <ng-container *ngIf="!record[column.name]?.class">{{record[column.name] | status}}</ng-container>
                    </ng-container>
                    <ng-container *ngIf="column.pipe === 'date'">
                        <ng-container *ngIf="record[column.name]?.class">{{record[column.name].value | date}}</ng-container>
                        <ng-container *ngIf="!record[column.name]?.class">{{record[column.name] | date:'yyyy-MM-dd HH:mm'}}</ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!column.pipe && !column.html">
                    {{record[column.name]?.class ? record[column.name].value : record[column.name]}}
                </ng-container>
                <ng-container *ngIf="!column.pipe && column.html">
                    <div [innerHtml]="updatedHtmlContent(record[column.name].value)"></div>
                </ng-container>
            </div>
        </td>
    </tr>
    </tbody>
</table>
