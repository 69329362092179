import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/store/states/app.state';
import { IUser } from '../models/user/user';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

    private authenticated: boolean = false;

    constructor(private router: Router, private _store: Store<IAppState>) {
        this._store.select('user').subscribe((user: IUser) => {
            this.authenticated = user.authenticated;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticated) {
            /**
             * @todo /login to be app settings constant which is easier to modify
             */
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        }
        return this.authenticated;
    }
}
