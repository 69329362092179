import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionTiersService {

  constructor(private apiService: ApiService) {
  }

  getData(): Observable<any> {
    return this.apiService.get('b2b/v1/organization/subscription/tiers');
  }

  getStripeData(): Observable<any> {
    return this.apiService.get('payment/stripe/tiers');
  }

}
