import { IUser } from 'src/app/models/user/user';
import { IRequest } from 'src/app/models/request/request';
import { RequestState } from '../states/request/request.state';
import { RequestActions, ERequestActions } from '../actions/request.actions';

export function requestReducers(state: IRequest = RequestState, action: RequestActions): IRequest {
    switch (action.type) {
        case ERequestActions.SetRequesting: {
            return {
                ...state,
                requesting: true
            }
        }
        case ERequestActions.RemoveRequesting: {
            return {
                ...state,
                requesting: false
            }
        }
        default:
            return state;
    }
}