<div class="container" style="width:390px; margin-top:90px;">
    <div class="login-card card mx-auto " stle="width:300px !important;">
        <div class="card-header">
            Please Sign In
        </div>
        <div class="card-body">
            <div class="login-logo-wrapper" style="text-align: center">
                <img src="../../../assets/images/logo.png">
            </div>
            <br>
            <form class="was-validated" [ngClass]="{'was-validated': signInForm.touched && signInForm.dirty}" [formGroup]="signInForm"
                (ngSubmit)="submit()">
                <div *ngIf="error" class="alert alert-danger ng-binding ng-hide">{{error}} </div>
                <div class="form-group">
                    <input autocomplete="on" placeholder="E-mail" name="email" type="email" [formControl]="signInForm.get('email')" required="required"
                        class="form-control" />
                    <div *ngIf="signInForm.get('email').invalid && (signInForm.get('email').dirty || signInForm.get('email').touched)" class="invalid-feedback">
                        You must enter a valid email address
                    </div>
                </div>

                <div class="form-group">
                    <input autocomplete="on" placeholder="Password" name="password" type="password" [formControl]="signInForm.get('pass')" required="required"
                        class="form-control" />
                    <div *ngIf="signInForm.get('pass').invalid && (signInForm.get('pass').dirty || signInForm.get('pass').touched)" class="invalid-feedback">
                        Password is required
                    </div>
                </div>
                <button [disabled]="!signInForm.valid" class="btn btn-block btn-success" type="submit">Login</button>
                <a ui-sref="forgot" href="#/forgot">Forgot password?</a>
            </form>
        </div>
    </div>
</div>