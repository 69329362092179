import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import Swal from "sweetalert2";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit {

  @Input() fontSize: number = 0.9;
  @Input() columns = [];
  @Input() data = [];
  @Input() sortColumn: any;
  @Input() draggable: boolean;
  @Input() dragGroupName = '';
  @Input() hrefHandler = false;
  @Output() buttonClickEvent = new EventEmitter<any>();
  @Output() selectChangeEvent = new EventEmitter<any>();
  @Output() textareaKeyupEvent = new EventEmitter<any>();
  @Output() sortClickEvent = new EventEmitter<any>();
  @Output() hrefClickEvent = new EventEmitter<any>();
  @Output() dragUpdateEvent = new EventEmitter<any>();

  sortableOptions: any = {
    group: this.dragGroupName || 'tableDragGroup_' + (new Date()).getTime(),
    onUpdate: (event: any) => {
      this.dragUpdateEvent.emit(event);
    }
  };

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.sortableOptions.disabled = !this.draggable;
    if (this.sortColumn && this.sortColumn.name && !this.draggable) {
      this.columns.forEach(col => {
        if (col.name === this.sortColumn.name) {
          if (this.sortColumn.direction === false) {
            col.asc = true;
          }
          if (this.sortColumn.direction === true) {
            col.desc = true;
          }
        }
      });
    }

  }

  buttonClick(event, column, record, btnName?) {
    this.buttonClickEvent.emit({event, column, record, btnName});
  }

  selectChange(event, column, record) {
    this.selectChangeEvent.emit({event, column, record});
  }

  textareaKeyup(event, column, record) {
    this.textareaKeyupEvent.emit({event, column, record});
  }

  sortClick(event, column) {
    if (!column.sortable || this.draggable) {
      return;
    }
    if (!column.asc && !column.desc) {
      column.asc = true;
      column.desc = false;
    } else if (column.asc && !column.desc) {
      column.asc = false;
      column.desc = true;
    } else if (!column.asc && column.desc) {
      column.asc = false;
      column.desc = false;
    }
    this.columns.forEach(col => {
      if (col.name !== column.name) {
        col.asc = false;
        col.desc = false;
      }
    });
    const sort = {
      column: column.name,
      direction: column.asc ? false : (column.desc ? true : null)
    };
    this.sortClickEvent.emit({event, column, sort});
  }

  hrefClick(event, column, record) {
    if (column.hrefHandler) {
      this.hrefClickEvent.emit({event, column, record});
      return false;
    }
  }

  public updatedHtmlContent(value: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

  public showInfo(text) {
    Swal.fire(
      text,
      '',
      'info'
    );
  }

}
