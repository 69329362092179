<div class="main-container">
    <div class="header">
        <h5 class="modal-title">Create New Subscription</h5>
        <span type="button" class="header-close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </span>
    </div>
    <div class="row actions-container subscription-container" *ngIf="!isAnyLoading()">

        <div class="description">
            Choose which Stripe Tier to apply. This subscription will be live once the current billing month ends.
        </div>

        <div class="item" *ngFor="let subscription of subscriptions"
             (click)="setSubscriptionId(subscription.default_price)"
                [ngClass]="{
                'selected': subscription.default_price === subscriptionPriceId,
                'disabled': data?.selectedSubscriptions?.indexOf(subscription.default_price) !== -1}">
            <span>
                {{ subscription.name }} subscription
            </span>
        </div>
    </div>
    <div>
        <mat-progress-bar mode="indeterminate" *ngIf="isAnyLoading()"></mat-progress-bar>
    </div>
    <div class="row actions-container">
        <div class="col-lg-12">
            <div class="col-lg-12 col-sm-12 text-center">
                <button mat-button class="btn  btn-border-radius m-l-15 submit-btn"
                        (click)="save()" [disabled]="isAnyLoading() || !isValid()">
                    Confirm
                </button>
                <button mat-button class="btn btn-border-radius m-l-15"
                        (click)="close()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
