import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RunsComponent } from './runs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared.module';
import { RunsdetailsComponent } from './runsdetails/runsdetails.component';
import { GoogleautocompleteComponent } from 'src/app/components/googleautocomplete/googleautocomplete.component';
import { GooglemapComponent } from 'src/app/components/googlemap/googlemap.component';
import { RouterModule } from '@angular/router';
import { RunLibraryComponent } from './run-library/run-library.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    RunsComponent,
    RunsdetailsComponent,
    GoogleautocompleteComponent,
    GooglemapComponent,
    RunLibraryComponent
  ]
})
export class RunsModule {
}
