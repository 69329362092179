<div class="container-fluid ">
    <h3><br>Subscribers</h3>
    <hr>
    <div class="card subscribers-card appshadow">
        <div class="card-header">Filters</div>
        <div class="card-body">
            <form>
                <div class="form-row">
                    <div class="form-group col-lg-6 col-md-12">
                        <label for="registrationDateFrom">Registration Date From</label>
                        <input type="date" class="form-control" id="registrationDateFrom" min="0001-01-01" max="9999-12-31" [formControl]="searchForm.get('registrationDateFrom')">
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                        <label for="registrationDateTo">Registration Date To</label>
                        <input type="date" class="form-control" id="registrationDateTo" min="0001-01-01" max="9999-12-31" [formControl]="searchForm.get('registrationDateTo')">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-lg-6 col-md-12">
                        <label for="tier">Tiers</label>
                        <select id="tier" class="form-control" [formControl]="searchForm.get('tierId')">
                            <option value="0">All</option>
                            <option *ngFor="let tier of this.subscriptionTiers" [value]="tier.id">
                                {{tier.name | titlecase}}</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                        <label for="orgName">Org. Name</label>
                        <input type="text" class="form-control" id="orgName" placeholder="Org. name" [formControl]="searchForm.get('orgName')">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-lg-6 col-md-12">
                        <label for="tier">Whitelisted</label>
                        <select id="whitelist" class="form-control" [formControl]="searchForm.get('whitelist')">
                            <option *ngFor="let whitelist of this.whitelist" [value]="whitelist.value">
                                {{whitelist.label | titlecase}}</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                        <label for="tier">Organization status</label>
                        <select id="status" class="form-control" [formControl]="searchForm.get('status')">
                            <option *ngFor="let status of this.statuses" [value]="status.value">
                                {{status.label | titlecase}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-lg-6 col-md-12">
                        <label for="subscriptionStatus">Subscription status</label>
                        <select id="subscriptionStatus" class="form-control" [formControl]="searchForm.get('subscriptionStatus')">
                            <option *ngFor="let subscriptionStatus of this.subscriptionStatuses" [value]="subscriptionStatus.value">
                                {{subscriptionStatus.label | titlecase}}</option>
                        </select>
                    </div>
                </div>
                <div style="text-align: right;">
                    <button type="submit" class="btn btn-primary" (click)="pageNumber = 0; onSearch()">See Results</button>
                </div>
            </form>
        </div>
    </div>

    <div class="card subscribers-card appshadow">
        <div class="card-header">
            <app-page-size *ngIf="searchWasMade" [value]="pageSize" (changeEvent)="onPageSizeChange($event)" class="float-right" style="font-size: 1.2em; margin-top: 1px; margin-left:4px;"></app-page-size>
        </div>
        <p *ngIf="!searchWasMade" class="text-center" style="margin-top: 20px;">
            Press "See Results" for search
        </p>
        <div *ngIf="searchWasMade" class="card-body">
            <div class="table-responsive">
                <app-table *ngIf="!isLoading"
                           (sortClickEvent)="onSort($event)"
                           (buttonClickEvent)="onSubscriberButtonClick($event)"
                           [sortColumn]="sortColumn" [columns]="[
                        {
                            title: 'ORGANIZATION NAME',
                            name: '_organizationName',
                            type: 'a',
                            sortable: true
                        },
                        {
                            title: 'ORGANIZATION EMAIL',
                            name: 'email',
                            sortable: true
                        },
                        {
                            title: 'S.ADMIN NAME',
                            name: '_fullName'
                        },
                        {
                            title: 'S.ADMIN EMAIL',
                            name: '_username',
                            sortable: true
                        },
                        {
                            title: 'REGISTRATION DATE',
                            name: '_creationDate',
                            sortable: true
                        },
                        {
                            title: 'TIER',
                            name: '_tier',
                            sortable: true
                        },
                        {
                            title: 'SUBSCRIPTION STATUS',
                            name: '_subscriptionStatus',
                            sortable: false
                        },
                        {
                            title: 'ACTIONS',
                            name: '_buttonSuspend',
                            type: 'button'
                        },
                   ]" [data]="resultList"></app-table>
            </div>
            <app-paging *ngIf="!isLoading" [totalCount]="totalElements" [pageLength]="resultList.length" [pageNumber]="(pageNumber + 1)" [totalPages]="totalPages" (pageChangeEvent)="onPageChange($event)">
            </app-paging>

            <div *ngIf="isLoading" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>

