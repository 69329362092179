import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Observable} from "rxjs";
import {IResult} from "../models/shared/iresult";
import {VendorTypeEnum} from "../enums/vendor-type.enum";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private apiService: ApiService) {
  }

  editWhiteList(id: string, whiteList: string): Observable<IResult> {
    return this.apiService.put('b2b/v1/organization/whitelist',
      {
        data: {
          orgId: id,
          whiteList
        }
      }
    );
  }

  getOrganizations(queryParams: object) {
    let queryParamsStr = '';
    let i = 0;
    for (const [key, value] of Object.entries(queryParams)) {
      if (value) {
        queryParamsStr += (i === 0 ? '?' : '&') + key + '=' + (value || '');
        i++;
      }
    }
    return this.apiService.get('b2b/v1/organization/all' + queryParamsStr);
  }

  getById(id: number) {
    return this.apiService.get('b2b/v1/organization/' + id);
  }

  registerOrganization(data: any) {
    return this.apiService.post('b2b/v1/organization/register/admin', data);
  }

  activateSuspend(id, status, forceUpdate, subscriptionTypeId?) {
    return this.apiService.post('b2b/v1/organization/status', {
      organizationId: id,
      status,
      forceUpdate,
      subscriptionTypeId,
    });
  }

  cancelUpgrade(id, subscriptionIds) {
    return this.apiService.post('b2b/v1/organization/updateOrgTier/cancel', {
      data: {
        id,
        subscriptionIds
      }
    });
  }

  upgrade(id, subscriptionTypeId) {
    return this.apiService.post('b2b/v1/organization/updateOrgTier', {
      data: {
        id,
        subscriptionTypeId
      }
    });
  }

  updateBillingType(orgId, billingType) {
    return this.apiService.put('b2b/v1/organization/billing', {
      data: {
        orgId,
        billingType
      }
    });
  }

  updatePaymentCollection(organizationId, subscriptionId) {
    return this.apiService.post('b2b/v1/organization/status/payment/collection', {
      data: {
        organizationId,
        subscriptionId
      }
    });
  }

  getSubscriptions(organizationId, isActive, pageNumber, pageSize, sort) {
    return this.apiService.get(
      `b2b/v1/organization/subscriptions?organizationId=${organizationId}&page=${pageNumber}&size=${pageSize}&sort=${sort}&isActive=${isActive || ''}`, {
    });
  }

  startSubscription(orgId, priceId) {
    return this.apiService.post('b2b/v1/organization/subscriptions/start', {
      data: {
        orgId,
        priceId
      }
    });
  }

  cancelSubscription(subscriptionId, forceUpdate) {
    return this.apiService.delete('b2b/v1/organization/subscriptions/' + subscriptionId + '?forceUpdate=' + forceUpdate, {});
  }

  resetCancellationSubscription(subscriptionId) {
    return this.apiService.put('b2b/v1/organization/subscriptions/' + subscriptionId, {
      reset: true,
    });
  }

}
