import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { IAppState } from '../states/app.state';
import { userReducers } from './user.reducer';
import { requestReducers } from './request.reducers.';
import { commonReducers } from './common.reducers';

export const appReducers: ActionReducerMap<IAppState, any> = {
    router: routerReducer,
    user: userReducers,
    request: requestReducers,
    common: commonReducers
};
