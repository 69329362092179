import {
    Component,
    DoCheck, Input,
    OnInit
} from '@angular/core';

@Component({
    selector: 'app-card-container',
    templateUrl: './card-container.component.html',
    styleUrls: ['./card-container.component.scss']
})
export class CardContainerComponent implements OnInit, DoCheck {

    @Input()
    loading;
    @Input()
    title;

    ngDoCheck(): void {
    }

    ngOnInit(): void {
    }

}
