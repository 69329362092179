import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/states/app.state';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private apiService: ApiService,
              private store: Store<IAppState>) {
  }

  imageUpload(blob, success, failure) {
    this.store.select('user').subscribe(({token}) => {
      const formData = new FormData();
      formData.append('file', blob);
      formData.append('token', token);
      if (blob.size > 1000000) {
        failure('Image size must be less then 1 MB');
        return;
      }
      //
      this.apiService.post('user/upload-file', formData).subscribe(response => {
        if (response.success) {
          success(response.result.data[0]);
        } else {
          failure(response.errors[0].message);
        }
      }, error => {
        failure(error.error.errors[0].message);
      });
    });
  }
}
