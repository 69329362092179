import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'src/app/models/shared/iresult';
import { ApiService } from 'src/app/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {


  constructor(private service: ApiService) {
  }

  getFeedbacks(
    from: Date,
    to: Date,
    search: string,
    status: string,
    OS: string,
    feature: string,
    pageSize: string,
    page: number,
    sortField: string,
    sortDirection: boolean
  ): Observable<IResult> {
    return this.service.post('users/feedback/get/filtered',
      {
        data: {
          filter: {
            addressed: status,
            categoryId: feature,
            devicePlatformId: OS,
            searchField: search,
            dateFrom: from,
            dateTo: to,
            status: null,
            creationCountry: null,
            creationState: null,
            whitelist: 2
          }
        },
        page,
        pageSize,
        sort: [{field: sortField, desc: sortDirection}]
      }
    );
  }


  editFeedback(
    feedbackId: number, adminAddressed: string, adminNote: string
  ): Observable<IResult> {
    return this.service.post('users/feedback/edit',
      {
        data: {
          adminNote,
          adminAddressed,
          id: feedbackId,
        }
      }
    );
  }
}
