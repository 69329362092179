export class SingleRunResponseModel {
    adminUserList: any;
    alphaUserId: number;
    avoidFerries: number;
    avoidHighways: number;
    avoidTolls: number;
    creationDate: number;
    creatorId: number;
    curated: true;
    curationRunId: string;
    distance: number;
    duration: string;
    endCountry: string;
    endDate: number;
    endState: string;
    endingPointLatitude: number;
    endingPointLongitude: number;
    endingPointName: string;
    id: number;
    isProtected: number;
    isPublic: number;
    isSolo: number;
    logbookRunId: string;
    name: string;
    note: string;
    organization: string;
    packId: number;
    packName: string;
    publicRadius: number;
    rating: string;
    routeImageUrl: string;
    runStatus: number;
    snsTopicArn: string;
    startCountry: string;
    startDate: number;
    startState: string;
    startingPointLatitude: number;
    startingPointLongitude: number;
    startingPointName: string;
    status: number;
    totalUsersGaveRating: string;
    type: string;
    waypoints: [
        {
            country: string;
            id: number;
            lat:  number;
            lon: number;
            ord:  number;
            ordLetter:  number;
            reached:  number;
            runId:  number;
            state: string;
            status:  number;
            title: string;
        }
    ];
    usersToRun: [
        {
            chatSeenDate: string;
            chatSubscriptionArn: string;
            distanceCovered: number;
            emailSent: string;
            endDate: number;
            favourite: number;
            gpxFileUrl: string;
            guestsCount: number;
            id: number;
            isAdmin: string;
            joinDate: number;
            notificationSent: string;
            rating: string;
            response: number;
            runId: number;
            scenic: string;
            startDate: string;
            status: number;
            timeOnRun: string;
            twisty: string;
            userId: number;
            userRecorded: string;
            userToRunStatus: number;
        }
    ];
}

