<app-card-container [title]="'Organization'" [loading]="isLoading">
    <form *ngIf="!isLoading">
        <div class="form-group">
            <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                    <label for="orgName" class="col-form-label org-details-label">Name:</label>
                    <input type="text" readonly class="form-control-plaintext org-details-input" id="orgName" [value]="organization?.name">
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="orgRegDate" class="col-form-label org-details-label">Registration Date:</label>
                    <input type="text" readonly class="form-control-plaintext org-details-input" id="orgRegDate" [value]="organization?.creationDate | date:'MM/dd/yyyy'">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                    <label for="orgAddress" class="col-form-label org-details-label">Address:</label>
                    <input type="text" readonly class="form-control-plaintext org-details-input" id="orgAddress" [value]="organization?.address">
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="orgStatus" class="col-form-label org-details-label">Status:</label>
                    <input type="text" readonly class="form-control-plaintext org-details-input" id="orgStatus" [value]="organization?.status">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                    <label for="orgPhoneNumber" class="col-form-label org-details-label">Phone number:</label>
                    <input type="text" readonly class="form-control-plaintext org-details-input" id="orgPhoneNumber" [value]="organization?.phoneNumber">
                    <label for="orgEmail" class="col-form-label org-details-label">Email:</label>
                    <input type="text" readonly class="form-control-plaintext org-details-input" id="orgEmail" [value]="organization?.contactEmail">
                </div>
            </div>
        </div>
    </form>
</app-card-container>
<ng-container *ngIf="!isLoading">
    <!-- Action Buttons -->
    <div class="container-fluid" *ngIf="organization">
        <div class="Actionbuttons-card card mx-auto appshadow">
            <div class="card-header">
                Action Buttons </div>
            <div class="card-body">
                <div class="col-12" class="permanently-delete">
                    <ng-container>
                        <!-- suspend btns -->
                        <button type="button" *ngIf="!inWhiteList" (click)="editWhiteList()"
                                [disabled]="loaded.processed"
                                class="form-control btn btn-primary form-control col-md-3 col-sm-12"
                                style="margin-right:10px; margin-bottom:5px;">Add to
                            Whitelist</button>
                        <button type="button" *ngIf="inWhiteList" (click)="editWhiteList()"
                                [disabled]="loaded.processed"
                                class="form-control btn btn-warning form-control col-md-3 col-sm-12"
                                style="margin-right:10px; margin-bottom:5px;">Remove from
                            Whitelist</button>
                        <!--Billing type-->
                        <button type="button" (click)="editBillingType()"
                                [disabled]="loaded.processed"
                                class="form-control btn btn-primary form-control col-md-3 col-sm-12"
                                style="margin-right:10px; margin-bottom:5px;">{{organization.billingType}}</button>
                        <!--Suspend--->
                        <button type="button" (click)="editSuspend()"
                                [disabled]="loaded.processed"
                                class="form-control btn  form-control col-md-3 col-sm-12"
                                [ngClass]="{ 'btn-danger': organization.status === 'Active', 'btn-success': organization.status !== 'Active' }"
                                style="margin-right:10px; margin-bottom:5px;">
                            {{organization.status === 'Active' ? 'Suspend' : 'Activate'}}
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<app-card-container [title]="'Super Admin'" [loading]="isLoading">
    <form *ngIf="!isLoading">
        <div class="form-group">
            <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                    <label for="orgCreatorName" class="col-form-label org-details-label">Name:</label>
                    <input type="text" readonly class="form-control-plaintext org-details-input" id="orgCreatorName" [value]="organization.creator ?
                        ((organization.creator?.firstName || '') + ' ' + (organization.creator?.lastName || '')) : ''">
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="orgCreatorEmail" class="col-form-label org-details-label">Email:</label>
                    <input type="text" readonly class="form-control-plaintext org-details-input" id="orgCreatorEmail" [value]="organization.creator ? organization.creator?.username : ''">
                </div>
            </div>
        </div>
    </form>
</app-card-container>
<app-card-container [title]="'Account Usage'" [loading]="isLoading">
    <table *ngIf="!isLoading" class="table">
        <thead>
        <tr>
            <th scope="col" class="text-center">TIER</th>
            <th scope="col" class="text-center">REGISTERED TRAVELERS</th>
            <th scope="col" class="text-center">NON-REGISTERED TRAVELERS</th>
            <th scope="col" class="text-center">MANAGERS</th>
            <th scope="col" class="text-center">GROUP LEADS</th>
            <th scope="col" class="text-center">ROAD CAPTAINS</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td class="text-center">{{organization?.subscriptionTierName | titlecase}}</td>
            <td class="text-center">{{organization?.registeredTravellers}}</td>
            <td class="text-center">{{organization?.nonRegisteredTravellers}}</td>
            <td class="text-center">{{managers}}</td>
            <td class="text-center">{{organization?.packLeaders}}</td>
            <td class="text-center">{{organization?.roadCaptains}}</td>
        </tr>
        </tbody>
    </table>
</app-card-container>
<app-card-container [title]="'Subscriptions'" [loading]="loading.subscription">
    <ng-container *ngIf="!loading.subscription">
        <div>
            <button color="primary" mat-button type="button" [disabled]="activeSubscriptionsTierPricesId.length > 1"
                    (click)="addSubscription()">
                <mat-icon>note_add</mat-icon>
                <span>Add</span>
            </button>
        </div>
        <app-paging *ngIf="!isLoading && subscriptions.length"
                    [totalCount]="subscriptionSettings.totalCount"
                    [pageLength]="subscriptions.length"
                    [pageNumber]="subscriptionSettings.pageNumber + 1"
                    [totalPages]="subscriptionSettings.totalPages"
                    (pageChangeEvent)="onSubscriptionPageChange($event)">
        </app-paging>
        <ng-container *ngIf="subscriptions.length">
            <app-subscription-table
                [loading]="loading"
                [subscriptions]="subscriptions"
                (cancelSubscription)="cancelSubscription($event)"
                (resumePaymentCollection)="resumePaymentCollection($event)"
                (pausePaymentCollection)="pausePaymentCollection($event)"
                (stopCancelSubscription)="stopCancelSubscription($event)"
            ></app-subscription-table>
        </ng-container>
    </ng-container>
</app-card-container>
