import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private service: ApiService, private http: HttpClient) { }

  login(username: string, password: string) {
    return this.service.post('user/auth',
    {
      data: {
        username,
        password
      }
    });
  }

  loginForRedirection(username: string, password: string) {
    return this.service.post('user/auth',
    {
      data: {
        username,
        password
      }
    });
  }
}
