<div class="container-fluid">
    <h3><br>Users</h3>
    <hr>
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Filters
        </div>
        <div class="card-body">
            <form [formGroup]="filterForm" (ngSubmit)="onSubmitFilter()">
                <div class="form-row" *ngIf="!requestAdmins">
                    <div class="form-group col-md-6 col-sm-12">
                        <label>Status</label>
                        <select class="form-control" [formControl]="filterForm.get('status')">
                            <option value="{{item.id}}" *ngFor="let item of statusList">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label>Whitelisted</label>
                        <select class="form-control" [formControl]="filterForm.get('whitelist')">
                            <option value="{{item.id}}" *ngFor="let item of whiteList">{{item.name}}</option>
                        </select>
                    </div>
                </div>


                <div class="form-row" *ngIf="!requestAdmins">
                    <div class="form-group col-md-6 col-sm-12">
                        <label>Country</label>
                        <select class="form-control" [formControl]="filterForm.get('country')" (change)="countriesChange()">
                            <option value="{{item.code}}" *ngFor="let item of countryList">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label>State</label>
                        <select class="form-control" [formControl]="filterForm.get('state')">
                            <option value="{{item.code}}" *ngFor="let item of stateList">{{item.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-row" *ngIf="!requestAdmins">
                    <div class="form-group col-md-6 col-sm-12">
                        <label>From</label>
                        <input type="date" class="form-control" [formControl]="filterForm.get('dateFrom')" min="0001-01-01" max="9999-12-31" />
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label>To</label>
                        <input type="date" class="form-control" [formControl]="filterForm.get('dateTo')" min="0001-01-01" max="9999-12-31" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-6 col-sm-12">
                        <label>Search</label>
                        <input type="text" class="form-control col-12" [formControl]="filterForm.get('search')" placeholder="Search (email or name)">
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label>&nbsp;</label>
                        <button type="submit " class="btn btn-primary form-control col-12" [disabled]="!countriesListLoaded">See Results
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            <span *ngIf="!requestAdmins">List of Users</span>
            <button *ngIf="requestAdmins" (click)="onAdminAddOrEdit(true)" class="btn btn-success float-left">Add New
            </button>
            <app-page-size [value]="pageSize" (changeEvent)="onPageSizeChange($event)" *ngIf="searchWasMade" class="float-right" style="font-size: 1.2em; margin-top: 1px; margin-left:4px;"></app-page-size>
        </div>
        <div class="d-flex justify-content-center" style="padding:10px;" *ngIf="!usersLoaded && searchWasMade">
            <div class="spinner-border " role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- systemusers -->
        <div class="card-body" style="margin-top:-21px;" *ngIf="!requestAdmins">
            <div class="table-responsive">
                <p *ngIf="!searchWasMade" class="text-center" style="margin-top: 20px;">
                    Press "See Results" for search
                </p>
                <app-table *ngIf="usersLoaded && searchWasMade" [sortColumn]='{name: sortColumn.table, direction: sortDirection}' (sortClickEvent)="sortClick($event)" [columns]="[
                                       {
                                           title:'ID',
                                           name:'id',
                                           sortable: true
                                        },
                                       {
                                           title:'Name',
                                           name:'_fullName',
                                           sortable: true,
                                           class: 'text-primary'
                                        },
                                        {
                                           title:'Email',
                                           name:'username',
                                           sortable: true
                                        },
                                        {
                                           title:'Creation Date',
                                           name:'_registrationDate',
                                           sortable: true
                                        },
                                        {
                                           title:'Last Session Date',
                                           name:'_lastSessionUseTime',
                                           sortable: true
                                        },
                                        {
                                            title:'Actions',
                                            name:'_buttonDetails',
                                            type: 'button'
                                         }
                                        ]" [data]="usersResponseModel" (buttonClickEvent)="detailsClicked($event)">
                </app-table>
            </div>
            <app-paging *ngIf="usersLoaded" [totalCount]="totalCount" [pageLength]="usersResponseModel.length" [pageNumber]="pageNumber" [totalPages]="totalPages" (pageChangeEvent)="onPageChange($event)">
            </app-paging>
        </div>

        <!-- administrators -->
        <div class="card-body" style="margin-top:-21px;" *ngIf="requestAdmins">
            <div class="table-responsive">
                <p *ngIf="!searchWasMade" class="text-center" style="margin-top: 20px;">
                    Press "See Results" for search
                </p>
                <app-table *ngIf="usersLoaded && searchWasMade" [sortColumn]='{name: sortColumn.table, direction: sortDirection}' (sortClickEvent)="sortClick($event)" [columns]="[
                                           {
                                               title:'ID',
                                               name:'id',
                                               sortable: true
                                            },
                                           {
                                               title:'Name',
                                               name:'_fullName',
                                               sortable: true,
                                               class: 'text-primary'
                                            },
                                            {
                                               title:'Email',
                                               name:'username',
                                               sortable: true
                                            },
                                            {
                                               title:'Creation Date',
                                               name:'_registrationDate',
                                               sortable: true
                                            },
                                            {
                                               title:'Last Session Date',
                                               name:'_lastSessionUseTime',
                                               sortable: true
                                            },
                                            {
                                                title:'',
                                                name:'_buttonEdit',
                                                type: 'button'
                                            },
                                            {
                                                title:'Actions',
                                                name:'_actions'
                                            },
                                            {
                                                title:'',
                                                name:'_buttonDelete',
                                                type: 'button'
                                            }
                                            ]" [data]="usersResponseModel" (buttonClickEvent)="editClicked($event)">
                </app-table>
            </div>
            <app-paging *ngIf="usersLoaded" [totalCount]="totalCount" [pageLength]="usersResponseModel.length" [pageNumber]="pageNumber" [totalPages]="totalPages" (pageChangeEvent)="onPageChange($event)">
            </app-paging>
        </div>
    </div>
</div>

<div *ngIf="modalConfig?.show" class="wp-modal">

    <div class="wp-modal-content">
        <div class="wp-modal-header">
            <span class="wp-modal-close" (click)="onCloseModal()">&times;</span>
            <h3>{{modalConfig?.title}}</h3>
        </div>
        <div class="wp-modal-body">
            <div *ngIf="modalConfig.isLoading" class="d-flex justify-content-center">
                <div class="spinner-border" role="status" style="margin-top: 100px; margin-bottom: 100px;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <form [hidden]="modalConfig.isLoading" (ngSubmit)="onAdminAddOrEditSubmit()" [formGroup]="userForm" autocomplete="off">
                <div *ngIf="modalConfig.addOrEdit" class="form-group">
                    <label for="formEmail">Email <b>*</b></label>
                    <input type="email" class="form-control" id="formEmail" formControlName="email"
                           [class.is-invalid]="(userForm.get('email').dirty || userForm.get('email').touched) && userForm.get('email').errors"
                    >
                    <div class="invalid-feedback">
                        <div *ngIf="userForm.get('email').errors?.required">
                            Please enter email
                        </div>
                        <div *ngIf="userForm.get('email').errors?.email">
                            Invalid email format
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="formFirstname">First name <b>*</b></label>
                    <input type="text" class="form-control" id="formFirstname" formControlName="firstName"
                        [class.is-invalid]="(userForm.get('firstName').dirty || userForm.get('firstName').touched) && userForm.get('firstName').errors"
                    >
                    <div class="invalid-feedback">Please enter first name</div>
                </div>
                <div class="form-group">
                    <label for="formLastname">Last name <b>*</b></label>
                    <input type="text" class="form-control" id="formLastname" formControlName="lastName"
                           [class.is-invalid]="(userForm.get('lastName').dirty || userForm.get('lastName').touched) && userForm.get('lastName').errors"
                    >
                    <div class="invalid-feedback">Please enter last name</div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="formPhoneCountryCode">Country Code</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">+</div>
                            </div>
                            <input type="number" class="form-control" id="formPhoneCountryCode" [min]="1" formControlName="phoneCountryCode">
                        </div>
                    </div>
                    <div class="form-group col-md-8">
                        <label for="formPhone">Phone</label>
                        <input type="number" class="form-control" id="formPhone" [min]="1" formControlName="phone"
                               [class.is-invalid]="((userForm.get('phoneCountryCode').dirty || userForm.get('phoneCountryCode').touched) || (userForm.get('phone').dirty || userForm.get('phone').touched)) && userForm.get('phone').errors"
                        >
                        <div class="invalid-feedback">
                            No phone country code is allowed without phone number
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="modalConfig.addOrEdit">
                    <label for="formPassword">Password <b>*</b></label>
                    <input type="password" class="form-control" id="formPassword" formControlName="password" autocomplete="new-password"
                           [class.is-invalid]="(userForm.get('password').dirty || userForm.get('password').touched) && userForm.get('password').errors"
                    >
                    <div class="invalid-feedback">
                        <div *ngIf="userForm.get('password').errors?.required">Please enter Password</div>
                        <div *ngIf="userForm.get('password').errors?.minlength">
                            {{'Password should be at least ' + this.userForm.get('password').errors?.minlength.requiredLength + ' characters'}}
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="modalConfig.addOrEdit">
                    <label for="formPasswordConfirm">Confirm Password <b>*</b></label>
                    <input type="password" class="form-control" id="formPasswordConfirm" formControlName="passwordConfirm"
                           [class.is-invalid]="(userForm.get('passwordConfirm').dirty || userForm.get('passwordConfirm').touched) && userForm.get('passwordConfirm').errors"
                    >
                    <div class="invalid-feedback">
                        <div *ngIf="userForm.get('passwordConfirm').errors?.required">
                            Please enter Password
                        </div>
                        <div *ngIf="userForm.get('passwordConfirm').errors?.minlength">
                            {{'Password should be at least ' + this.userForm.get('passwordConfirm').errors?.minlength.requiredLength + ' characters'}}
                        </div>
                        <div *ngIf="!userForm.get('passwordConfirm').errors?.required && !userForm.get('passwordConfirm').errors?.minlength && userForm.get('passwordConfirm').errors?.doesNotMatch">
                            Passwords don't match
                        </div>
                    </div>
                    <input type="hidden" formControlName="id">
                </div>
            </form>

        </div>
        <div class="wp-modal-footer">
            <button type="button" class="btn btn-primary" style="margin-right: 10px;" [disabled]="!userForm.valid || modalConfig.isLoading" (click)="onAdminAddOrEditSubmit()">
                Submit
            </button>
            <button (click)="onCloseModal()" class="btn btn-secondary">Cancel</button>
        </div>
    </div>
</div>