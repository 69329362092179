export class UserListRequestModel {
    data = {
        creationCountry: '',
        creationState: null,
        dateFrom: null,
        dateTo: null,
        isAdmin: false,
        isBlocked: null,
        searchField: '',
        secondarySort: '',
        status: '',
        whitelist: null,
        recipients: null,
    };
    page = 1;
    pageSize = "2";
    sort = [
        {
            field: '',
            desc: true,
        }
    ]


}