import {RegistratrionSourceEnum} from './registratrion-source.enum';

const registrationView = (item) => {
  switch (item) {
    case RegistratrionSourceEnum.UNKNOWN:
    case RegistratrionSourceEnum.INTERNAL: {
      return 'Email/Password';
    }
    case RegistratrionSourceEnum.B2B_MANAGER: {
      return 'Central manager';
    }
    case RegistratrionSourceEnum.B2B_CUSTOMER_TRAVELLER: {
      return 'Central traveller';
    }
    case RegistratrionSourceEnum.APPLE: {
      return 'Sign In with Apple';
    }
    case RegistratrionSourceEnum.FACEBOOK: {
      return 'Sign In with Facebook ';
    }
  }
};

export default registrationView;
