import {Component, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppState} from '../store/states/app.state';
import {IUser} from 'src/app/models/user/user';
import {Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SharedService} from './services/api/shared.service';


@Component({
  selector: '#app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  private env: any = environment;
  public user: IUser;
  userSubscription = new Subscription;

  constructor(private _store: Store<IAppState>, private sharedService: SharedService) {
    this.sharedService.checkSession().subscribe(x => {});
    this.userSubscription = this._store.select('user').subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
