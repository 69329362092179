<div class="card org-details-card">
    <div class="card-body">

        <div class="d-flex justify-content-center">
            <h2 style="text-align: center;">Wolfpack Management Console</h2>
        </div>
        <div class="d-flex justify-content-center" style="text-align: center;">
            Welcome, {{firstName}}. To get started, select an option from the navigation menu
        </div>
    </div>
</div>