import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CountriesRequestModel } from 'src/app/models/shared/countries/countries.requestmodel';
import { Observable } from 'rxjs';
import { IResult } from 'src/app/models/shared/iresult';

@Injectable({
  providedIn: 'root'
})
export class SharedService {


  constructor(private service: ApiService) { }

  getCountries(model: CountriesRequestModel): Observable<IResult> {
    return this.service.post('util/get/all/countries',
      {
        data: { userExists: 1 },
        pageSize: 1000,
        sort: [{ field: 'name', desc: false }],
      }
    );
  }

  getAllCountries(): Observable<IResult> {
    return this.service.post('util/get/all/countries',
      {
        data: {},
        pageSize: 1000,
        sort: [{ field: 'name', desc: false }],
      }
    );
  }


  getStates(model: CountriesRequestModel): Observable<IResult> {
    return this.service.post('util/get/all/us/states',
      {
        data: { userExists: 1 },
        pageSize: 1000,
        sort: [{ field: 'name', desc: false }],
      }
    );
  }

  getRunStates(model?: CountriesRequestModel): Observable<IResult> {
    if (model) {
      model.data.runExists = 1;
    }
    return this.service.post('util/get/all/us/states',
      {
        data: { runExists: 1 },
        pageSize: 1000,
        sort: [{ field: 'name', desc: false }],
      }
    );
  }
  getRunCountries(model?: CountriesRequestModel): Observable<IResult> {
    if (model) {
      model.data.runExists = 1;
    }
    return this.service.post('util/get/all/countries',
      {
        data: { runExists: 1 },
        pageSize: 1000,
        sort: [{ field: 'name', desc: false }],
      }
    );
  }

  getUserPreferences() {
    return this.service.post('user/preferences/get',
      null
    );
  }

  checkSession() {
    return this.service.post('user/auth/check', null);
  }
}
