import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { SubscriptionTiersService } from '../../../../services/subscription-tiers.service';
import { OrganizationService } from '../../../../services/organization.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscribers-tier',
  templateUrl: './subscribers-tier.component.html',
  styleUrls: ['./subscribers-tier.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class SubscribersTierComponent implements OnInit, OnDestroy {
  loading = {
    subscription: false,
    save: false,
  };
  subscriptionPriceId;
  subscriptions = [];

  constructor(private organizationService: OrganizationService,
              private subscriptionTiersService: SubscriptionTiersService,
              public dialogRef: MatDialogRef<SubscribersTierComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA)
              public data: any) {
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.loading.subscription = true;
    this.subscriptionTiersService.getStripeData()
      .subscribe(resp => {
        this.loading.subscription = false;
        this.subscriptions = resp.result.data || [];
      }, () => {
        this.loading.subscription = false;
      });
  }

  public save() {
    this.loading.save = true;
    this.data.updateStatus({
      priceId: this.subscriptionPriceId,
    }, () => {
      this.loading.save = false;
      this.dialogRef.close();
    }, () => {
      this.loading.save = false;
    });
  }

  public close() {
    this.dialogRef.close();
  }

  setSubscriptionId(subscriptionPriceId) {
    if (this.data?.selectedSubscriptions?.indexOf(subscriptionPriceId) !== -1) {
      return;
    }
    this.subscriptionPriceId = subscriptionPriceId;
  }

  isAnyLoading() {
    return this.loading.save || this.loading.subscription;
  }

  isValid() {
    return this.subscriptionPriceId;
  }

}
