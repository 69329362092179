<div class="container-fluid">
    <h3><br>Whats New</h3>
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Enter Message to be sent to all Wolfpack users
        </div>
        <div class="card-body" id="createNews">
<!--          <editor (onGetContent)="onMessageContentChange($event, true)"-->
<!--                  [initialValue]="message"-->
<!--                  [init]="{-->
<!--                      base_url: '/tinymce',-->
<!--                      suffix: '.min',-->
<!--                      plugins: 'lists advlist image link codesample',-->
<!--                      toolbar: 'undo redo | bold italic | bullist numlist outdent indent | link image codesample',-->
<!--                      branding: false,-->
<!--                      images_upload_handler: onMessageImageUpload,-->
<!--                      init_instance_callback: initInstanceCallback-->
<!--                  }">-->
<!--          </editor>-->
                <div *ngIf="modalConfig.isLoading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <quill-editor #editor
                  [modules]="modules"
                  [(ngModel)]="message"
                  id="editor"
                  (onEditorCreated)="getEditorInstance($event)"
                  (onContentChanged)="changedContent($event)"
                  (onSelectionChanged)="contentSelectionChange($event)"
                  [disabled]="modalConfig.isLoading">
                </quill-editor>
            <input type="file" style="display: none" id="fileInputField" accept="image/*"/>

            <div role="group" aria-label="editor buttons" style="padding-top:15px;">
                <button type="button" class="btn btn-warning col-2" (click)="preview()" [disabled]="!message">Preview</button>
                <button type="button" class="btn btn-primary col-2 offset-8" (click)="save()" [disabled]="!message">Publish</button>
            </div>
            <div role="result" aria-label="save result" class="alert alert-success alert-dismissible fade show" *ngIf="saveAnswer" style="margin-top:15px;">
                Message Saved!
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="removeSuccess()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            <button (click)="getNewsList()" class="btn btn-primary float-left">See Results
            </button>
            <app-page-size [value]="pageSize" (changeEvent)="onPageSizeChange($event)" *ngIf="searchWasMade" class="float-right" style="font-size: 1.2em;  margin-left:4px;"></app-page-size>
            <select class=" form-control col-2 float-right " [(ngModel)]="visibleOnWeb " (change)="changeVisibilityStatus() ">
                <option value="{{item.value}} " *ngFor="let item of visibleStatusList" checked>{{item.label}}</option>
            </select>
        </div>
        <div class="d-flex justify-content-center " style="padding:10px; " *ngIf="!listLoaded && searchWasMade">
            <div class="spinner-border " role="status ">
                <span class="sr-only ">Loading...</span>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive ">
                <p *ngIf="!searchWasMade" class="text-center" style="margin-top: 20px;">
                    Press "See Results" for search
                </p>
                <app-table *ngIf="listLoaded && searchWasMade" [sortColumn]='{name: sortColumn.table, direction: sortDirection}' [data]="newsList " (sortClickEvent)="sortClick($event)" (selectChangeEvent)="tableStatusChanged($event)" (buttonClickEvent)="btnClicked($event) "
                    [columns]="[ { title: 'Date Published',
                name: '_creationTime', sortable: true }, { title: 'Admin Name', name: '_adminName', sortable: true }, { title: 'Message', name: '_buttonDetails', type: 'button' }, { title: 'Status', name: '_selectStatuses', type: 'select', data: visibleStatusListForTable,
                sortable: true }, { title: 'Actions', name: '_buttonEdit', type: 'button' } ] ">
                </app-table>
            </div>
            <app-paging *ngIf="listLoaded" [totalCount]="totalCount" [pageLength]="newsList.length" [pageNumber]="pageNumber" [totalPages]="totalPages" (pageChangeEvent)="onPageChange($event)">
            </app-paging>
        </div>
    </div>
</div>

<div *ngIf="modalConfig?.show" class="wp-modal">

    <div class="wp-modal-content">
        <div class="wp-modal-header">
            <h3 class="col-12">{{modalConfig?.title}}
                <span class="wp-modal-close" (click)="onCloseModal()">&times;</span>
            </h3>
        </div>
        <div class="wp-modal-body">
<!--          <editor (onGetContent)="onMessageContentChange($event, false)"-->
<!--                  [initialValue]="editmessage"-->
<!--                  [init]="{-->
<!--                      base_url: '/tinymce',-->
<!--                      suffix: '.min',-->
<!--                      plugins: 'lists advlist image link codesample',-->
<!--                      toolbar: 'undo redo | bold italic | bullist numlist outdent indent | link image codesample',-->
<!--                      branding: false,-->
<!--                      images_upload_handler: onMessageImageUpload,-->
<!--                      init_instance_callback: initInstanceCallback-->
<!--                  }">-->
<!--          </editor>-->
            <div *ngIf="modalConfig.isEditLoading" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <quill-editor #editor [modules]="modules"
                          [(ngModel)]="editmessage"
                          (onEditorCreated)="getEditorInstanceEdit($event)"
                          (onContentChanged)="changedContentEdit($event)"
                          (onSelectionChanged)="contentSelectionChange($event)"
                          [disabled]="modalConfig.isEditLoading">
            </quill-editor>
            <input type="file" style="display: none" id="fileInputFieldEdit" accept="image/*"/>
        </div>
        <div class="wp-modal-footer">
            <button type="button" class="btn btn-primary col-5 offset-2"
                    [disabled]="modalConfig.isEditLoading"
                    (click)="editSave()">Save</button>
        </div>
    </div>
</div>
