import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PacksService } from '../service/packs.service';
import { ActivatedRoute } from '@angular/router';
import { PackResponseModel } from '../model/packdetails.responsemodel';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { _runtimeChecksFactory } from '@ngrx/store/src/runtime_checks';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { AvatarBase64 } from 'src/app/shared/data/avatar';

@Component({
  selector: 'app-packdetailes',
  templateUrl: './packdetailes.component.html',
  styleUrls: ['./packdetailes.component.css']
})
export class PackdetailesComponent implements OnInit, OnDestroy {

  packForm: UntypedFormGroup;

  packId: number = 0;
  packDetails: PackResponseModel;
  packidSubscription = new Subscription;
  packdetilsSubscription = new Subscription;
  updateSubscription = new Subscription;
  deleteSubscription = new Subscription;
  avatarImage = new AvatarBase64;

  constructor(private service: PacksService, private activeroute: ActivatedRoute, private domSanitizer: DomSanitizer) {
    this.packidSubscription = this.activeroute.params.subscribe(data => this.packId = data.id);
  }

  ngOnInit() {
    this.packForm = new UntypedFormGroup({
      name: new UntypedFormControl({ value: '', disabled: false }, [Validators.required]),
      alphaId: new UntypedFormControl({ value: '', disabled: false }, [Validators.required]),
    });

    this.getPack();
  }

  getPack() {
    this.packdetilsSubscription = this.service.details(this.packId).subscribe(data => {
      this.packDetails = data.result.data[0];

      this.packDetails.activeUsers.map(x => {
        x._name = { value: x.firstName + ' ' + x.lastName, href: `/userdetails/${x.id}`, target: '_blank' };
      })
      this.packDetails.pictureUrl = this.packDetails.pictureUrl ? this.packDetails.pictureUrl : this.avatarImage.base64;     
      this.packForm.get('name').setValue(this.packDetails.name);
      this.packForm.get('alphaId').setValue(this.packDetails.alpha.id);
    })
  }

  update() {
    this.updateSubscription = this.service.update(
      this.packId,
      this.packForm.controls.name.value,
      this.packForm.controls.alphaId.value).subscribe(data => {
        if (data.success) {
          Swal.fire({
            title: 'Success',
            text: "Pack: " + this.packForm.controls.name.value + " has been updated",
            confirmButtonColor: 'Green',
            confirmButtonText: 'OK',
          })
        }
      })
  }

  delete(){
    Swal.fire({
      title: 'Warning',
      text: "Do you really want to delete Pack: " + this.packForm.controls.name.value,
      confirmButtonColor: 'red',
      confirmButtonText: 'delete',
      showCancelButton: true,
      cancelButtonColor: 'blue'
    }).then(data => {
      if (data.value) {
        this.confirmDelete();
      }
    })
    
  }

  confirmDelete(){
    this.deleteSubscription = this.service.delete(this.packId).subscribe(data => {
      if (data.success) {
        Swal.fire({
          title: 'Deleted',
          text: "Pack: " + this.packForm.controls.name.value + " has been deleted",
          confirmButtonColor: 'Red',
          confirmButtonText: 'OK',
        }).then(data => {
          this.closeTab();
        })
      }
    })
  }

  closeTab(){
    window.close();
  }

  ngOnDestroy() {
    this.packdetilsSubscription.unsubscribe();
    this.packidSubscription.unsubscribe();
    this.updateSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
  }
}
