import { ValidatorFn, AbstractControl } from '@angular/forms';

export function MustMatchValidator(matchingControllName: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

        if (!control.parent) {
            return null;
        }

        const matchingControl: AbstractControl = control.parent.controls[matchingControllName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        return control.value !== matchingControl.value ? { 'doesNotMatch': { value: control.value } } : null;
    };
}
