import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { OrganizationService } from '../../../../services/organization.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import { SubscriptionTiersService } from 'src/app/services/subscription-tiers.service';

@Component({
  selector: 'app-subscription-activation-confirmation',
  templateUrl: './subscription-activation-confirmation.component.html',
  styleUrls: ['./subscription-activation-confirmation.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class SubscriptionActivationConfirmationComponent implements OnInit, OnDestroy {
  loading = {
    subscription: false,
    activeSubscription: false,
    save: false,
  };
  subscriptionPriceId;
  subscriptions = [];
  availableSubscriptions = [];
  description;
  organizationSubscriptionsSubscriber: Subscription;
  availableSubscriptionsSubscriber: Subscription;

  constructor(private organizationService: OrganizationService,
              private subscriptionTiersService: SubscriptionTiersService,
              public dialogRef: MatDialogRef<SubscriptionActivationConfirmationComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA)
              public data: any) {
  }

  getDescription() {
      return 'Access to Central will be activated. ' +
        'Subscriptions should be handled separately.';
  }

  ngOnDestroy(): void {
    this.cancelSubscriptionRequest();
  }

  private cancelSubscriptionRequest() {
    if (this.organizationSubscriptionsSubscriber) {
      this.organizationSubscriptionsSubscriber.unsubscribe();
    }
  }

  ngOnInit() {
    this.loading.subscription = true;
    this.cancelSubscriptionRequest();
    this.organizationSubscriptionsSubscriber = this.organizationService.getSubscriptions(
      this.data.record.organizationId,
      1,
      0,
      10, 'paidPeriodStartDate,desc')
      .subscribe(resp => {
        this.loading.subscription = false;
        this.subscriptions = (resp.result.data[0].content || []);
        this.subscriptions.sort((a, b) => a.id - b.id);
        this.description = this.getDescription();
        if (!this.subscriptions.length) {
          this.loadActiveSubscriptions();
        }
      }, () => {
        this.loading.subscription = false;
      });
  }

  setSubscriptionId(subscriptionPriceId) {
    if (this.data?.selectedSubscriptions?.indexOf(subscriptionPriceId) !== -1) {
      return;
    }
    this.subscriptionPriceId = subscriptionPriceId;
  }

  private loadActiveSubscriptions() {
    this.loading.activeSubscription = true;
    this.availableSubscriptionsSubscriber = this.subscriptionTiersService.getStripeData()
      .subscribe(resp => {
        this.loading.activeSubscription = false;
        this.availableSubscriptions = resp.result.data || [];
      }, () => {
        this.loading.activeSubscription = false;
      });
  }

  public save() {
    this.loading.save = true;
    this.data.updateStatus({
      priceId: this.subscriptionPriceId,
    }, () => {
      this.loading.save = false;
      this.dialogRef.close();
    }, () => {
      this.loading.save = false;
    });
  }

  public close() {
    this.dialogRef.close();
  }

  isAnyLoading() {
    return this.loading.save || this.loading.subscription || this.loading.activeSubscription;
  }

}
