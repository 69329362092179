export class CountriesRequestModel {

    data = {
        runExists: 0,
        userExists: 0
    };
    pageSize = 1000;
    sort = [{
        field: 'name',
        desc: false,
    }];
}
