import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {VendorTypeEnum} from '../../../../enums/vendor-type.enum';

@Component({
  selector: 'app-subscribers-confirmation',
  templateUrl: './subscription-cancellation-confirmation.component.html',
  styleUrls: ['./subscription-cancellation-confirmation.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class SubscriptionCancellationConfirmationComponent implements OnInit, OnDestroy {
  loading = {
    subscription: false,
    save: false,
  };
  subscription;
  description;
  isNoScheduledSubscription;

  constructor(public dialogRef: MatDialogRef<SubscriptionCancellationConfirmationComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA)
              public data: any) {
    this.subscription = this.data.subscription;
    this.isNoScheduledSubscription = this.data.subscriptions.every(s => !s.isScheduled);
  }

  getDescription() {
      if (this.subscription.vendorType === VendorTypeEnum.STRIPE) {
        return 'It\'s not Stripe subscription.';
      }
      return 'It\'s not Stripe subscription. It will be cancelled right now';
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {

  }

  public save(forceUpdate) {
    this.loading.save = true;
    this.data.updateStatus({forceUpdate}, () => {
      this.loading.save = false;
      this.dialogRef.close();
    }, () => {
      this.loading.save = false;
    });
  }

  public close() {
    this.dialogRef.close();
  }

  isAnyLoading() {
    return this.loading.save || this.loading.subscription;
  }

}
