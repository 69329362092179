import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SystemusersService } from '../service/systemusers.service';
import { UserDetailsResponseModel } from '../model/userdetails.responsemodel';
import { UserSubscriptionsResponseModel } from '../model/usersubscriptions.responsemodel';
import { UserRunsResponseModel } from '../model/userruns.responsemodel';
import { UserPayemntsResponseModel } from '../model/userpayments.responsemodel';
import { UserInvitesResponseModel } from '../model/userinvites.responsemodel';
import { UserPacksResponseModel } from '../model/userpacks.responsemodel';
import { IAppState } from 'src/store/states/app.state';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2';
import { AvatarBase64 } from '../../../shared/data/avatar';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/services/api/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import {RegistratrionSourceEnum} from "../model/registratrion-source.enum";
import registrationView from "../model/registartion-view";

@Component({
  selector: 'app-systemusersdetails',
  templateUrl: './systemusersdetails.component.html',
  styleUrls: ['./systemusersdetails.component.css'],
  providers: [DatePipe]
})

export class SystemusersdetailsComponent implements OnInit, OnDestroy {

  id: string = "0";
  idSubscription = new Subscription;

  userForm: UntypedFormGroup;
  RegistratrionSourceEnum = RegistratrionSourceEnum;

  details: UserDetailsResponseModel;
  avatarImage = new AvatarBase64;
  detailsSubscription = new Subscription;

  detailsPremiumStatus: number;
  detailsPremiumStatusSubscription = new Subscription;
  makeBasicSubscription = new Subscription;
  makePremiumSubscription = new Subscription;

  premiumLastPayment: Date;
  premiumNextPayment: Date;
  premiumSubscriptionType: number = 3;
  premiumForm: UntypedFormGroup;

  updateAnswer: boolean = false;
  updateSuccess: boolean = false;
  updateUserSubscription = new Subscription;

  suspendedUser: boolean = false;

  deletedUser: boolean = false;
  deletedInProgressUser: boolean = false;

  suspendSubscription = new Subscription;
  activateSubscription = new Subscription;

  inWhiteList: boolean = false;
  whiteListSubscription = new Subscription;

  userBlocked: boolean = false;

  userSubscriptions: Array<UserSubscriptionsResponseModel>;
  userOrganizations: Array<any>;
  userSubscriptionsSubscription = new Subscription;
  rolledBackSubscriptionsSubscription = new Subscription;
  resetSubscription = new Subscription;
  processSubscriptionsSubscription = new Subscription;
  userOrganizationsSubscription = new Subscription;

  runsSize: string = "10";
  searchRun: string;
  userRuns: Array<UserRunsResponseModel>;
  userRunsSubscription = new Subscription;

  paymentsSize: string = "10";
  searchPayments: string;
  payments: Array<UserPayemntsResponseModel>;
  paymentsSubscription = new Subscription;

  invitesSize: string = "10";
  searchInvites: string;
  invites: Array<UserInvitesResponseModel>;
  invitesSubscription = new Subscription;

  packsSize: string = "10";
  searchPacks: string
  packs: Array<UserPacksResponseModel>;
  packsSubscription = new Subscription;

  preferencesSubscription = new Subscription;
  distanceFormat = 'km';

  token: string;
  tokenSubscription = new Subscription;

  //paging and sorting
  pageNumber = { subscriptionDates: 1, run: 1, payment: 1, invite: 1, pack: 1, userOrganizations: 1 };
  pageSize = { subscriptionDates: 10, run: 10, payment: 10, invite: 10, pack: 10, userOrganizations: 10 };
  totalCount = { subscriptionDates: 0, run: 0, payment: 0, invite: 0, pack: 0, userOrganizations: 0 };
  totalPages = { subscriptionDates: 0, run: 0, payment: 0, invite: 0, pack: 0 };
  loaded = { subscriptionDates: false, run: false, payment: false, invite: false, pack: false, processed: false, organizationDetails: false };
  sortDirection = { subscriptionDates: false, run: false, payment: false, invite: false, pack: false };
  sortColumn = {
    subscriptionDates: { post: "username", table: "username" },
    run: { post: "creationDate", table: "creationDate" },
    payment: { post: "date", table: "date" },
    invite: { post: "invitationType", table: "invitationType" },
    pack: { post: "packName", table: "packName" }
  };

  modalConfig = {
    show: false,
    addOrEdit: true,
    wasValidated: false,
    title: 'Make Premium',
    isLoading: false
  };


  dateRange = {
    payment: {
      from: new UntypedFormControl(),
      to: new UntypedFormControl()
    }
  };

  selectors = {
    payment: {
      paymentType: new UntypedFormControl(null),
      actions: new UntypedFormControl(null)
    }
  };

  loading = {
    info: false
  };

  action;

  constructor(
    private activeroute: ActivatedRoute,
    private service: SystemusersService,
    private sharedService: SharedService,
    private _store: Store<IAppState>,
    private datePipe: DatePipe,
    private domSanitizer: DomSanitizer
  ) {
    this.idSubscription = this.activeroute.params.subscribe(data => this.id = data.id);
    this._store.select('user').subscribe(({ token }) => {
      this.token = token;
      this.getPreferences();
    });

  }

  ngOnInit() {
    this.detailsSubscription = this.service.getUserDetails(this.id).subscribe(data => {
      if (data && data.result && data.result.data) {
        this.details = data.result.data[0];
         this.details.pictureUrl = this.details.pictureUrl ? this.details.pictureUrl : this.avatarImage.base64;
        this.userForm = new UntypedFormGroup({
          email: new UntypedFormControl({ value: this.details.username, disabled: true }, [Validators.required]),
          firstName: new UntypedFormControl({value: this.details.firstName, disabled: true }, [Validators.required]),
          lastName: new UntypedFormControl({value: this.details.lastName, disabled: true }, [Validators.required]),
          phone: new UntypedFormControl({value: this.details.phone, disabled: true }, [Validators.required]),
        });

        this.createPremiumForm();
        this.checkSuspended();
        this.checkDeleted();
        this.checkWhiteList();
        this.checkBlocked();
        this.getUserSubscriptions();
        this.getUserOrganizations();
        this.getUserRuns();
        this.getUserPayments();
        this.getUserInvites();
        this.getUserPacks();
      }
    });
    this.checkuserInfo();
  }

  getPreferences() {
    this.preferencesSubscription = this.sharedService.getUserPreferences().subscribe(data => {
      this.distanceFormat = data.result.data.find(option => option.keyword === 'DISTANCE_FORMAT').value === '3' ? 'km' : 'mi';
    });
  }

  createPremiumForm() {
    this.premiumForm = new UntypedFormGroup({
      premiumLastPayment: new UntypedFormControl('', [Validators.required]),
      premiumNextPayment: new UntypedFormControl('', [Validators.required]),
      premiumSubscriptionType: new UntypedFormControl(3, [Validators.required]),
    });
  }

  checkuserInfo() {
    this.loading.info = true;
    this.detailsPremiumStatusSubscription = this.service.getUserPremiumStatus(this.id).subscribe(data => {
      this.detailsPremiumStatus = data.result.data[0] ? data.result.data[0].vendorType : null;
      this.loading.info = false;
    }, () => {
      this.loading.info = false;
    });
  }

  checkBlocked() {
    this.userBlocked = this.details.unsuccessfulLogins >= 3;
  }

  checkSuspended() {
    this.suspendedUser = this.details.status == 1 && this.details.verified ? false : true;
  }

  checkDeleted() {

    this.deletedUser = this.details.status === 2;

    if (this.deletedUser) {
      this.processStatus();
    }

  }

  checkWhiteList() {
    this.inWhiteList = this.details.whitelist == 1;
  }

  permanentlyDelete() {
    Swal.fire({
      title: 'It will immediately delete user. Are you sure?',
      icon: 'question',
      animation: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
    }).then((result) => {
      if (result.value) {
        this.loaded.processed = true;
        this.rolledBackSubscriptionsSubscription =
          this.service.permanentlyDelete(this.id).subscribe(data => {
            this.deletedInProgressUser = false;
            this.deletedUser = true;
            this.loaded.processed = false;
          });
      }
    });
  }

  rolledBackDelete() {
    this.loaded.processed = true;
    this.rolledBackSubscriptionsSubscription =
      this.service.rolledBackProcessStatus(this.id).subscribe(data => {
        this.deletedInProgressUser = false;
        this.deletedUser = false;
        this.loaded.processed = false;
      });
  }

  processStatus() {
    this.loaded.processed = true;
    this.processSubscriptionsSubscription = this.service.processStatus(this.id).subscribe(data => {
      this.action = data.result.data[0];
      this.deletedInProgressUser = !!this.action;
      this.loaded.processed = false;
    });
  }

  getUserOrganizations() {
    this.loaded.organizationDetails = false;
    this.userOrganizationsSubscription = this.service.getUserOrganizations(this.id).subscribe(data => {
      this.userOrganizations = data.result.data[0];
      this.loaded.organizationDetails = true;
    });
  }

  getUserSubscriptions() {
    this.loaded.subscriptionDates = false;
    this.userSubscriptionsSubscription = this.service.getUserSubscriptions(this.id).subscribe(data => {
      this.userSubscriptions = data.result.data;
      this.totalCount.subscriptionDates = data.result.totalCount;
      this.loaded.subscriptionDates = true;
      this.userSubscriptions.map(x => {
        x.subscriptionDate = this.datePipe.transform(new Date(x.subscriptionDate), 'MM.dd.yyyy');
      });
    });
  }

  getUserRuns() {
    this.loaded.run = false;
    this.userRunsSubscription = this.service.getUserRuns(
      this.id,
      this.searchRun,
      this.runsSize,
      this.sortColumn.run.post,
      this.sortDirection.run
    ).subscribe(data => {
      this.userRuns = data.result.data;
      this.totalCount.run = data.result.totalCount;
      this.loaded.run = true;


      this.userRuns.map(x => {
        x._attended = x.userStartDate ? 'yes' : 'no';
        x._isPublic = x.isPublic == 1 ? 'public' : 'private';
        x.distance = this.distanceFormat === 'km' ?  parseFloat(x.distance).toFixed(2) + ' km' : (parseFloat(x.distance) * 0.62137).toFixed(2) + ' mi';
        x.userDistanceCovered = Math.round(parseFloat(x.userDistanceCovered)).toFixed(2);
        x.creationDate = this.datePipe.transform(new Date(x.creationDate), 'MM.dd.yyyy');
        x.startDate = this.datePipe.transform(new Date(x.startDate), 'MM.dd.yyyy');
        x.endDate = this.datePipe.transform(new Date(x.endDate), 'MM.dd.yyyy');
        x._buttonDetails = { value: 'Download Simulation', class: 'btn btn-primary' };
      });
      this.totalPages.run = Math.ceil(this.totalCount.run / this.pageSize.run);
    });
  }

  runsPageSizeChange(event) {
    this.runsSize = event.value;
    this.getUserRuns();
  }

  runsSortClick(event) {
    this.sortDirection.run = event.sort.direction === true;
    switch (event.sort.column) {
      case '_attended':
        this.sortColumn.run.post = 'userStartDate';
        this.sortColumn.run.table = '_attended';
        break;
      case '_isPublic':
        this.sortColumn.run.post = 'isPublic';
        this.sortColumn.run.table = '_isPublic';
        break;
      default:
        this.sortColumn.run.post = event.sort.column;
        this.sortColumn.run.table = event.sort.column;
        break;
    }
    this.getUserRuns();
  }

  onRunPageChange(pageNumber: number) {
    if (this.pageNumber.run === pageNumber) {
      return false;
    }
    this.pageNumber.run = pageNumber || 1;
    this.getUserRuns();
    return false;
  }

  checkDateForm(formValue, formValueItem) {
    // if (formValue && !formValue.value) {
    //   formValueItem.value = '';
    // }
  }

  getUserPayments() {
    this.loaded.payment = false;
    this.userRunsSubscription = this.service.getUserPayments(this.id,
      this.pageNumber.payment,
      this.paymentsSize,
      this.sortColumn.payment.post,
      this.searchPayments,
      this.sortDirection.payment, {
        dateFrom: this.dateRange.payment.from.value,
        dateTo: this.dateRange.payment.to.value,
        paymentPeriod: this.selectors.payment.paymentType.value === 'null' ? null : this.selectors.payment.paymentType.value,
        paymentAction: this.selectors.payment.actions.value === 'null' ? null : this.selectors.payment.actions.value
      }).subscribe(data => {
      this.payments = data.result.data;
      this.totalCount.payment = data.result.totalCount;
      this.payments.map(x => {
        x.date = this.datePipe.transform(new Date(x.date), 'MM.dd.yyyy');
        x._amount = x.amount ? (x.amount + ' ' + x.currencyCode) : 'N/A';
        x._paymentMethod = this.getPaymentMethod(x.vendor);
      });
      this.loaded.payment = true;
      this.totalPages.payment = Math.ceil(this.totalCount.payment / this.pageSize.payment);
    });
  }

   getPaymentMethod (val): string {
    if (val == 1) return 'Paypal';
    if (val == 2) return 'Google';
    if (val == 3) return 'Apple';
    if (val == 4) return 'Manual';
    return 'Tester';
  }

  paymentsPageSizeChange(event) {
    this.paymentsSize = event.value;
    this.pageSize.payment = +event.value;
    this.getUserPayments();
  }

  paymentsSortClick(event) {
    this.sortDirection.payment = event.sort.direction === true;
    switch (event.sort.column) {
      case '_paymentMethod':
          this.sortColumn.payment.post = 'vendor';
          this.sortColumn.payment.table = '_paymentMethod';
          break;
      case '_amount':
        this.sortColumn.payment.post = 'amount';
        this.sortColumn.payment.table = '_amount';
        break;
      default:
        this.sortColumn.payment.post = event.sort.column;
        this.sortColumn.payment.table = event.sort.column;
        break;
    }
    this.getUserPayments();
  }

  onPaymentPageChange(pageNumber: number) {
    if (this.pageNumber.payment === pageNumber) {
      return false;
    }
    this.pageNumber.payment = pageNumber || 1;
    this.getUserPayments();
    return false;
  }

  getUserInvites() {
    this.loaded.invite = false;
    this.invitesSubscription = this.service.getUserInvites(
      this.id,
      1,
      this.invitesSize,
      this.sortColumn.invite.post,
      this.searchInvites,
      this.sortDirection.invite).subscribe(data => {
        this.invites = data.result.data;
        this.totalCount.invite = data.result.totalCount;
        this.invites.map(x => {
          x.invitationTime = x.invitationTime ? this.datePipe.transform(new Date(x.invitationTime), 'MM.dd.yyyy') : '';
          x.acceptTime = x.acceptTime ? this.datePipe.transform(new Date(x.acceptTime), 'MM.dd.yyyy') : '';
        });
        this.loaded.invite = true;
        this.totalPages.invite = Math.ceil(this.totalCount.invite / this.pageSize.invite);
    });
  }

  invitesPageSizeChange(event) {
    this.invitesSize = event.value;
    this.getUserInvites();
  }

  invitesSortClick(event) {
    this.sortDirection.invite = event.sort.direction === true;
    switch (event.sort.column) {
      default:
        this.sortColumn.invite.post = event.sort.column;
        this.sortColumn.invite.table = event.sort.column;
        break;
    }
    this.getUserInvites();
  }

  onInvitesPageChange(pageNumber: number) {
    if (this.pageNumber.invite === pageNumber) {
      return false;
    }
    this.pageNumber.invite = pageNumber || 1;
    this.getUserInvites();
    return false;
  }

  getUserPacks() {
    this.loaded.pack = false;
    this.packsSubscription = this.service.getUserPacks(
      this.id,
      1,
      this.packsSize,
      this.sortColumn.pack.post,
      this.searchPacks).subscribe(data => {
      this.packs = data.result.data;
      this.totalCount.pack = data.result.totalCount;
      this.packs.map(x => {
        x.roleInPack = x.packCreatorId === parseInt(this.id, 10) ? 'Creator' : 'Member';
        switch (x.status) {
          case 1:
            x.statusName = 'Accepted';
            break;
          case 2:
            x.statusName = 'Pending';
            break;
          case 3:
            x.statusName = 'Rejected';
            break;
          case 4:
            x.statusName = 'Removed';
            break;
          case 5:
            x.statusName = 'Deleted';
            break;
        }
        x.packCreationTime = this.datePipe.transform(new Date(x.packCreationTime), 'MM.dd.yyyy');
        x.createDate = this.datePipe.transform(new Date(x.createDate), 'MM.dd.yyyy');
      });
      this.totalPages.pack = Math.ceil(this.totalCount.pack / this.pageSize.pack);

      this.loaded.pack = true;
    });
  }

  packsSortClick(event) {
    this.sortDirection.pack = event.sort.direction === true;
    switch (event.sort.column) {
      case 'roleInPack':
        this.sortColumn.pack.post = 'packCreatorId';
        this.sortColumn.pack.table = 'roleInPack';
        break;
      default:
        this.sortColumn.pack.post = event.sort.column;
        this.sortColumn.pack.table = event.sort.column;
        break;
    }
    this.getUserPacks();
  }

  packsPageSizeChange(event) {
    this.packsSize = event.value;
    this.getUserPacks();
  }

  onPacksPageChange(pageNumber: number) {
    if (this.pageNumber.pack === pageNumber) {
      return false;
    }
    this.pageNumber.pack = pageNumber || 1;
    this.getUserPacks();
    return false;
  }

  runDetailsClicked(event) {
    let id = event.record.userToRunId;
    window.open(this.service.getRunFileUrl(id, this.token));
  }

  updateUser() {
    this.updateUserSubscription = this.service.updateUserDetails(
      this.id,
      this.userForm.controls.firstName.value,
      this.userForm.controls.lastName.value,
      this.userForm.controls.phone.value
    ).subscribe(data => {
      this.updateSuccess = data.result.data[0];
      this.updateAnswer = true;
    });
  }

  deleteReasons() {
      Swal.fire({
        title: 'Delete the user',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          this.service.deletePermanentUser(this.id).subscribe(response => {
            if (response && !response.errors) {
              Swal.fire(
                'Process of user delete started successfully',
                '',
                'success'
              );
              this.deletedUser = true;
              this.processStatus();
            }
          }, error => {
            Swal.fire(
              'Something went wrong to start delete process',
              '',
              'error'
            );
          });
        }
      });
  }


  suspendReasons() {
    Swal.fire({
      title: 'Want to suspend? Please type note',
      input: 'text',
      icon: 'question',
      animation: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Suspend!'
    }).then((result) => {
      if (result.value) {
        this.suspendUser(result.value);
      }
    });
  }

  suspendUser(suspendreasons: string) {
    this.suspendSubscription = this.service.suspendUser(
      this.id,
      suspendreasons
    ).subscribe(data => {
      this.suspendedUser = data.result.data[0];
    });
  }

  makeBasicQuestion() {
    Swal.fire({
      title: 'Want to Make Basic?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Make Basic!'
    }).then((result) => {
      if (result.value) {
        this.makeBasic();
      }
    });
  }

  makeBasic() {
    this.makeBasicSubscription = this.service.makeBasic(
      this.id,
    ).subscribe(data => {
      if (data.result.data[0]) {
        this.checkuserInfo();
      }
    });
  }

  onCloseModal() {
    this.modalConfig.show = false;
  }

  makePremiumQuestion() {
    this.modalConfig.show = true;
  }

  makePremium() {
    this.makePremiumSubscription = this.service.makepremium(
      this.id,
      this.premiumForm.controls.premiumLastPayment.value,
      this.premiumForm.controls.premiumNextPayment.value
    ).subscribe(data => {
      if (data.result.data[0]) {
        this.createPremiumForm();
        this.onCloseModal();
        this.checkuserInfo();
      }
    });
  }

  activateUser() {
    Swal.fire({
      title: 'Warning',
      text: 'Do you want to activate this user?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Activate!'
    }).then((result) => {
      if (result.value) {
        this.activateSubscription = this.service.activateUser(
          this.id
        ).subscribe(data => {
          this.suspendedUser = !data.result.data[0];
        });
      }
    })
  }

  editWhiteList() {
    this.whiteListSubscription = this.service.editWhiteList(this.details.username, this.inWhiteList ? 2 : 1).subscribe(data => {
      this.inWhiteList = data.result.data[0].whitelist === 1;
    });
  }

  unblockUser() {
    Swal.fire({
      title: 'Warning',
      text: 'Unblock this User?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Unblock!'
    }).then((result) => {
      if (result.value) {
        this.activateSubscription = this.service.unblockUser(
          this.id
        ).subscribe(data => {
          this.userBlocked = !data.result.data[0];
        });
      }
    });
  }

  ngOnDestroy() {
    this.idSubscription.unsubscribe();
    this.detailsSubscription.unsubscribe();
    this.detailsPremiumStatusSubscription.unsubscribe();
    this.makeBasicSubscription.unsubscribe();
    this.makePremiumSubscription.unsubscribe();
    this.updateUserSubscription.unsubscribe();
    this.suspendSubscription.unsubscribe();
    this.activateSubscription.unsubscribe();
    this.paymentsSubscription.unsubscribe();
    this.invitesSubscription.unsubscribe();
    this.packsSubscription.unsubscribe();
    this.whiteListSubscription.unsubscribe();
    this.userSubscriptionsSubscription.unsubscribe();
    this.userRunsSubscription.unsubscribe();
    this.tokenSubscription.unsubscribe();
    this.preferencesSubscription.unsubscribe();
    if (this.processSubscriptionsSubscription) {
      this.processSubscriptionsSubscription.unsubscribe();
    }
    if (this.rolledBackSubscriptionsSubscription) {
      this.rolledBackSubscriptionsSubscription.unsubscribe();
    }
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
  }

  public resetPassword() {
    Swal.fire({
      title: 'This will require the user to set a new password. Are you sure?',
      icon: 'question',
      animation: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.loaded.processed = true;
        this.resetSubscription =
          this.service.resetPassword(this.id).subscribe(data => {
            this.loaded.processed = false;
          });
      }
    });
  }

  registrationViewType(item) {
    return registrationView(item);
  }

}
