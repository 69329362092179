export enum StatusEnum {
  ACTIVE = 1,
  DELETED = 2,
  SUSPENDED = 3,
  NOT_YET_STARTED = 4,
  DONE = 5,
  NOT_YET_CONFIRMED = 6,
  PENDING = 7,
  DRAFT = 8
}
