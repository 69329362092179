import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogService } from './services/log.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { appReducers } from '../store/reducers/app.reducers';
import { environment } from '../environments/environment';
import { UserEffects } from '../store/effects/user.effects';
import { AuthenticationModule } from './authentication/authentication.module';
import { HomeComponent } from './components/home/home.component';
import { TokenInterceptor } from './services/api/interceptors/token.interceptor';
import { ErrorInterceptor } from './services/api/interceptors/error.interceptor';
import { AppInitService } from './app-init.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersModule } from './modules/users/users.module';
import { HoldingpageComponent } from './components/holdingpage/holdingpage.component';
import { FeedbackModule } from './modules/feedback/feedback.module';
import { WhatsnewModule } from './modules/whatsnew/whatsnew.module';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { RunsModule } from './modules/runs/runs.module';
import { PacksModule } from './modules/packs/packs.module';
import { AdministrationModule } from './modules/administration/administration.module';
import { FAQComponent } from './components/faq/faq.component';
import { SharedModule } from './shared.module';
import { LoadInterceptor } from './services/api/interceptors/load.interceptop';
import { QuillModule } from 'ngx-quill';
import {NgxImageCompressService} from 'ngx-image-compress';
import {FeedModule} from './modules/feed/feed.module';
import {TranslationService} from './services/translation.service';
import {TranslationPipe} from './pipes/translation.pipe';
import {OrganizationDetailsModule} from './components/organization-details/organization-details.module';

export function initializeApp(appInitService: AppInitService) {
   return (): Promise<any> => {
      return appInitService.Init();
   };
}
export function setupTranslateServiceFactory(
  service: TranslationService) {
  return () => service.use('en');
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HoldingpageComponent,
    SideBarComponent,
    TopBarComponent,
    FAQComponent,
    TranslationPipe,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
    AppRoutingModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AuthenticationModule,
    UsersModule,
    FeedbackModule,
    WhatsnewModule,
    FeedModule,
    RunsModule,
    PacksModule,
    OrganizationDetailsModule,
    AdministrationModule,
    SharedModule,
    QuillModule.forRoot()
  ],
  providers: [
    /**
     * initializeapp before application loads
     */
    AppInitService,
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true},
    TranslationService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateServiceFactory,
      deps: [
        TranslationService
      ],
      multi: true
    },
    LogService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadInterceptor, multi: true},
    NgxImageCompressService
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
