<div class="sidebar-header" [ngClass]="menuExpanded ? 'sidebar-header-expanded' : 'sidebar-header-collapsed'">
    <i (click)="toggleMenu()" class="sidebar-header-menu-collapse-icon fa"
       [ngClass]="menuExpanded ? 'fa-arrow-left' : 'fa-bars'"></i>
    <img [class.img-collapsed]="!menuExpanded" src="../../../assets/images/app-logo-header.png" alt="logo">
    <i (click)="toggleTopBar()" class="sidebar-header-topbar-collapse-icon fa"
       [ngClass]="topbarExpanded ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'"></i>
</div>

<nav class="sidebar" [ngClass]="menuExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'">
    <ul class="sidebar-list" [class.sidebar-list-collapsed]="!menuExpanded">
        <div *ngFor="let item of menuItems; index as i">
            <!--parent-->
            <li class="panel panel-default" routerLinkActive="active-link" #parentEl>
                <a *ngIf="!hasChildren(i)" [routerLink]="[item.route]">
                    <i class="sidebar-item-icon fa fa-{{item.icon}}"></i>
                    <span *ngIf="menuExpanded">{{item?.title}}</span>
                </a>
                <a *ngIf="hasChildren(i)" (click)="parentEl.isExpanded = !parentEl.isExpanded"
                   style="display: flex; flex-direction: row; justify-content: space-between;">
                    <div>
                        <i class="sidebar-item-icon fa fa-{{item.icon}}"></i>
                        <span *ngIf="menuExpanded">{{item?.title}}</span>
                    </div>
                    <i [ngClass]="parentEl.isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'"
                       class="fa" style="margin-right: 10px; margin-top: 4px;"></i>
                </a>
            </li>
            <!--children-->
            <li *ngFor="let itemChild of (item.children || []);" [hidden]="!parentEl.isExpanded"
                routerLinkActive="active-link" class="panel panel-default submenu-list">
                <a [routerLink]="[itemChild.route]">
                    <i class="sidebar-item-icon fa fa-{{itemChild.icon}}"></i>
                    <span *ngIf="menuExpanded">{{itemChild?.title}}</span>
                </a>
            </li>
        </div>
    </ul>
</nav>