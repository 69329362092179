import { Action } from '@ngrx/store';
import { IUser } from '../../app/models/user/user';

export enum EUserActions {
    LoadUserToken = '[User] Load Token',
    LoadUserTokenError = '[User] Load Token Error',
    SetUserData = '[User] Set User Data',
    RemoveUserDataState = '[User] Remove User Data State',
    RemoveUserDataStorage = '[User] Remove User Data Storage'
}

export class RemoveUserDataStorage implements Action {
    public readonly type = EUserActions.RemoveUserDataStorage;
}

export class RemoveUserDataState implements Action {
    public readonly type = EUserActions.RemoveUserDataState;
    constructor(public payload: IUser) { }
}

export class SetUserData implements Action {
    public readonly type = EUserActions.SetUserData;
    constructor(public payload: IUser) { }
}

export class LoadUserToken implements Action {
    public readonly type = EUserActions.LoadUserToken;
    constructor(public payload: { username: string, password: string }) { }
}

export class LoadUserTokenError implements Action {
    public readonly type = EUserActions.LoadUserTokenError;
    constructor(public payload: { error: string }) { }
}

export type UserActions = 
 LoadUserToken | 
 LoadUserTokenError | 
 SetUserData |
 RemoveUserDataState | 
 RemoveUserDataStorage;