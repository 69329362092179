import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { IResult } from 'src/app/models/shared/iresult';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WhatsnewService {

  constructor(private service: ApiService) { }

  getNews(
    searchField: string,
    pageSize: number,
    page: number,
    sortField: string,
    sortDirection: boolean,
    visibleOnWeb: string
  ): Observable<IResult> {
    return this.service.post('news/get/filtered',
      {
        data: {
          filter: {
            status: 1,
            searchField: searchField,
            visibleOnWeb: visibleOnWeb
          }
        },
        page: page,
        pageSize: pageSize,
        sort: [{ field: sortField, desc: sortDirection }]
      }
    );
  }

  public uploadImage(file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.service.post('news/item/upload-file', formData);
  }

  savetNews(
    message: string,
    title: string,
    visibleOnWeb: boolean
  ): Observable<IResult> {
    return this.service.post('news/add',
      {
        data: {
          message: message,
          title: title,
          visibleOnWeb: visibleOnWeb
        }
      }
    );
  }

  removeUnused(urls) {
    return this.service.post('news/item/removeunused', {
      data: urls
    });
  }

  editNews(
    id:number,
    message: string,
    title: string,
    visibleOnWeb: boolean
  ): Observable<IResult> {
    return this.service.post('news/edit',
      {
        data: {
          id:id,
          message: message,
          title: title,
          visibleOnWeb: visibleOnWeb,
          status: 1,
        }
      }
    );
  }
}
