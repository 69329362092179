import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginModel } from './model/loginModel';
import { LoginService } from './service/login.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/store/states/app.state';
import * as actions from 'src/store/actions/user.actions';
import { Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

	model = new LoginModel;
	error: string;
	authenticated: boolean = false;
	errorSubscription: Subscription;
	signInForm: UntypedFormGroup;

	constructor(private _store: Store<IAppState>, private service: LoginService, private router: Router) {
		this.signInForm = new UntypedFormGroup({
			email: new UntypedFormControl('', [Validators.required, Validators.email]),
			pass: new UntypedFormControl('', [Validators.required]),
			remember: new UntypedFormControl(false, [Validators.required]),
		});
	}

	/**
	 * 
	 */
	ngOnInit() {
		this.errorSubscription = this._store.select("user").subscribe(({ authenticated, error }) => {
			this.error = error;
			this.authenticated = authenticated;
		});
	}

	/**
	 * 
	 */
	submit() {
		this._store.dispatch(new actions.LoadUserToken(
			{
				username: this.signInForm.controls.email.value,
				password: this.signInForm.controls.pass.value,
			}
		));
	}

	ngOnDestroy() {
		this.errorSubscription.unsubscribe();
	}
}
