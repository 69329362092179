import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DOCUMENT } from '@angular/common';
import { IAppState } from 'src/store/states/app.state';
import { Store } from '@ngrx/store';
import * as userActions from '../../../../store/actions/user.actions';
import {TranslationService} from '../../translation.service';

declare const swal: any;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private _store: Store<IAppState>,
                @Inject(DOCUMENT) private document: Document,
                private translationService: TranslationService) { }

    intercept(request: HttpRequest<any>,
              next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (!this.document.location.href.includes('/login')) { // not to appear on login page
                if (event instanceof HttpResponse) {
                    if (event.body.errors && event.body.errors.length) {
                        // what to do
                        if (event.body.errors[0].code === 10003 || event.body.errors[0].code === 10035) {
                            this._store.dispatch(new userActions.RemoveUserDataStorage());
                            this.throwCustomError(event);
                        }
                        else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: this.getMessage(event),
                                showConfirmButton: false,
                                timer: 3000
                            });
                            this.throwCustomError(event);
                        }
                    }
                }
            }
            return event;
        }));
    }

    throwCustomError(event) {
        throw new HttpErrorResponse({
            error: event.body.errors,
            headers: event.headers,
            status: 500,
            statusText: event.body.errors[0].message,
            url: event.url
        });
    }

  private getMessage(event) {
    const code = event.body.errors[0].code;
    const translate = this.translationService.translate(`server_error_${code}`, false, 'server');
    return translate ||
      (event.body.errors[0].message || event.body.errors[0].explanation || 'Something went wrong');
  }
}
