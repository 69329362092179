import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnInit {

  @Input() totalCount = 1;
  @Input() pageLength = 1;
  @Input() pageNumber = 1;
  @Input() totalPages = 0;
  @Output() pageChangeEvent = new EventEmitter<number>();
  totalPagesArray = [];

  constructor() {
  }

  ngOnInit() {
    for (let i = 0; i < this.totalPages; i++) {
      this.totalPagesArray.push(i + 1);
    }
  }

  isValidPage(distance: number, page: number) {
    return (this.pageNumber - distance > page || this.pageNumber + distance < page) && (page !== 1 && page !== this.totalPages);
  }

  onPageChange(pageNumber: number) {
    this.pageChangeEvent.emit(pageNumber);
  }

}
