import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import {Observable} from "rxjs";
import {IResult} from "../../../models/shared/iresult";

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  constructor(private service: ApiService) {
  }

  public uploadImage(image, id) {
    const formData: FormData = new FormData();
    formData.append('file', image);
    return this.service.post('user/edit/' + id + '/profile-image', formData);
  }

  public updateUser(user, id) {
    return this.service.post('user/edit/' + id, {
      data: user
    });
  }

  public findUser(id) {
    return this.service.post('user/get/by/id', {
      data: {
        id
      }
    });
  }

  public listPosts(searchField: string,
                   pageSize: number,
                   page: number,
                   sortField: string,
                   sortDirection: boolean) {
    return this.service.getFromService(`system/posts/search?` +
      `page=${page}&size=${pageSize}&paged=true&sort=${sortField}${sortField ?
        (sortDirection ? ',asc' : ',desc') : ''}` +
      `&search=${searchField}`,
      'feed');
  }

  public savePost(body) {
    return this.service.postFromService('system/posts', 'feed', body);
  }

  public updatePost(id, body) {
    return this.service.postFromService(`system/posts/${id}`, 'feed', body);
  }

  public savePostFile(id, image) {
    const formData: FormData = new FormData();
    formData.append('file', image);
    return this.service.postFromService(`files/post/${id}`, 'feed', formData);
  }

  public updatePostFile(id, image) {
    const formData: FormData = new FormData();
    formData.append('file', image);
    return this.service.putFromService(`files/${id}`, 'feed', formData);
  }

  deletePost(systemPostId: number): Observable<IResult> {
    return this.service.postFromService('system/posts/' + systemPostId + '/delete', 'feed', {});
  }

}
