<div class="container-fluid" *ngIf="details">
    <h3><br>User Details</h3>
    <hr>
    <div class="systemuserdetails-card card mx-auto appshadow">
        <div class="card-header">
            User Details
        </div>
        <div class="card-body">
            <form [formGroup]="userForm" (ngSubmit)="updateUser()">
                <div class="form-row col-md-3 col-sm-12 float-left">
                    <label>&nbsp;</label>
                    <img [src]="domSanitizer.bypassSecurityTrustResourceUrl(details.pictureUrl)" class="col-sm-12" />
                </div>
                <div class="col-md-9 col-sm-12 float-left">
                    <div class="form-group col-12" *ngIf="updateAnswer">
                        <div class="alert alert-success" role="alert" *ngIf="updateSuccess">
                            User Updated!
                        </div>
                        <div class="alert alert-danger" role="alert" *ngIf="!updateSuccess">
                            Operation was Unsuccessful!
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-12 ">
                            <label>Email</label>
                            <input type="text " class="form-control" [formControl]="userForm.get('email')">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 ">
                            <label>First Name</label>
                            <input type="text " class="form-control" [formControl]="userForm.get('firstName')" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Last Name</label>
                            <input type="text " class="form-control" [formControl]="userForm.get('lastName')" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 " [class.was-validated]="userForm.get('phone').dirty">
                            <label>Phone</label>
                            <input type="number" class="form-control" [formControl]="userForm.get('phone')" />
                            <div class="invalid-feedback">
                                invalid format!
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 " [class.was-validated]="userForm.get('phone').dirty">
                            <label>Sign in type</label>
                            <input type="text" readonly class="form-control" value="{{registrationViewType(details.registrationSourceType)}}" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 ">
                            <label>Posthog ID</label>
                            <input type="text" readonly class="form-control" [value]="details.internalId" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12" style="padding-top:10px;">
                            <button type="submit" [disabled]="!userForm.valid && !userForm.get('phone').valid"
                                class="form-control btn btn-primary form-control col-md-3 col-xs-12"
                                style="margin-right:10px;" *ngIf="false">Update</button>
                            <!-- <button type="button " class="form-control btn btn-warning form-control col-3">Cancel</button> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Action Buttons -->
<div class="container-fluid" *ngIf="details">
    <div class="Actionbuttons-card card mx-auto appshadow">
        <div class="card-header">
            Action Buttons </div>
        <div class="card-body">
            <div class="col-12" class="permanently-delete">

                <ng-container *ngIf="deletedUser">
                    <button type="button" *ngIf="deletedInProgressUser" (click)="rolledBackDelete()"
                            class="form-control btn btn-danger form-control col-md-3 col-sm-12"
                            [disabled]="loaded.processed"
                            style="margin-right:10px; margin-bottom:5px;">Rolled Back Delete</button>
                    <div *ngIf="deletedInProgressUser" class="notification-title">
                        User will be deleted {{action.stateDate | date}}
                    </div>
                    <div *ngIf="!deletedInProgressUser" class="notification-title">
                        User was deleted
                    </div>
                    <button type="button" *ngIf="deletedInProgressUser" (click)="permanentlyDelete()"
                            class="form-control btn btn-danger form-control col-md-3 col-sm-12"
                            [disabled]="loaded.processed"
                            style="margin-right:10px; margin-bottom:5px;">Permanently delete</button>
                </ng-container>

                <ng-container *ngIf="!deletedUser">
                    <!-- suspend btns -->
                    <button type="button" *ngIf="!suspendedUser" (click)="suspendReasons()"
                            class="form-control btn btn-danger form-control col-md-3 col-sm-12"
                            style="margin-right:10px; margin-bottom:5px;">Suspend</button>
                    <button type="button" *ngIf="!deletedUser" (click)="deleteReasons()"
                            [disabled]="loaded.processed || !loaded.organizationDetails ||
                            (loaded.organizationDetails && userOrganizations && !!userOrganizations.creator &&
                             userOrganizations.creator.id === details.id)"
                            class="form-control btn btn-danger form-control col-md-3 col-sm-12"
                            style="margin-right:10px; margin-bottom:5px;">Delete</button>

                    <button type="button" *ngIf="suspendedUser" (click)="activateUser()"
                            [disabled]="loaded.processed"
                            class="form-control btn btn-success form-control col-md-3 col-sm-12"
                            style="margin-right:10px; margin-bottom:5px;">Activate</button>
                    <!-- whitelist btns -->
                    <button type="button" *ngIf="!inWhiteList" (click)="editWhiteList()"
                            [disabled]="loaded.processed"
                            class="form-control btn btn-primary form-control col-md-3 col-sm-12"
                            style="margin-right:10px; margin-bottom:5px;">Add to
                        Whitelist</button>
                    <button type="button" *ngIf="inWhiteList" (click)="editWhiteList()"
                            [disabled]="loaded.processed"
                            class="form-control btn btn-warning form-control col-md-3 col-sm-12"
                            style="margin-right:10px; margin-bottom:5px;">Remove from
                        Whitelist</button>
                    <!-- basic btn -->
                    <button type="button" (click)="makeBasicQuestion()" *ngIf="detailsPremiumStatus"
                            [disabled]="detailsPremiumStatus !== 4"
                            class="form-control btn btn-warning form-control col-md-3 col-sm-12"
                            style="margin-right:10px; margin-bottom:5px;">Make Basic</button>
                    <!-- Premium btn -->
                    <button type="button" (click)="makePremiumQuestion()" *ngIf="!detailsPremiumStatus"
                            [disabled]="loaded.processed"
                            class="form-control btn btn-primary form-control col-md-3 col-sm-12"
                            style="margin-right:10px; margin-bottom:5px;" [disabled]="loading.info">Make Premium</button>
                    <!-- unblock btn -->
                    <button type="button" *ngIf="userBlocked" (click)="unblockUser()"
                            [disabled]="loaded.processed"
                            class="form-control btn btn-warning form-control col-md-3 col-sm-12"
                            style="margin-right:10px; margin-bottom:5px;">Unblock</button>
                </ng-container>

                <ng-container *ngIf="details.status !== 2 && (details.registrationSourceType === RegistratrionSourceEnum.INTERNAL ||
                        details.registrationSourceType === RegistratrionSourceEnum.B2B_CUSTOMER_TRAVELLER ||
                        details.registrationSourceType === RegistratrionSourceEnum.B2B_MANAGER ||
                        details.registrationSourceType === RegistratrionSourceEnum.UNKNOWN)">
                    <button type="button" (click)="resetPassword()"
                            class="form-control btn btn-danger form-control col-md-3 col-sm-12"
                            [disabled]="loaded.processed"
                            style="margin-right:10px; margin-bottom:5px;">Reset password</button>
                </ng-container>

            </div>
        </div>
    </div>
</div>

<!-- Organization Details -->
<div class="container-fluid" *ngIf="details">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header ">
            Organization
        </div>
        <div class="d-flex justify-content-center" style="padding:10px;" *ngIf="!loaded.organizationDetails">
            <div class="spinner-border " role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="card-body" style="margin-top:-21px;">
            <div class="table-responsive">
                <br/>
                <div *ngIf="loaded.organizationDetails && userOrganizations && userOrganizations.creator &&
                             userOrganizations.creator?.id === details.id">
                    Owner of organization: <b>{{userOrganizations?.name}}</b>
                </div>
                <div *ngIf="!loaded.organizationDetails && !(userOrganizations && userOrganizations.creator &&
                             userOrganizations.creator?.id === details.id)">
                    Not owner of organization
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>

<!-- Subscription Dates -->
<div class="container-fluid" *ngIf="details">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header ">
            List of Subscription Dates
        </div>
        <div class="d-flex justify-content-center" style="padding:10px;" *ngIf="!loaded.subscriptionDates">
            <div class="spinner-border " role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="card-body" style="margin-top:-21px;">
            <div class="table-responsive">
                <app-table *ngIf="loaded.subscriptionDates" [columns]="[
                                           {
                                               title:'subscription Date',
                                               name:'subscriptionDate'
                                            }
                                            ]" [data]="userSubscriptions">
                </app-table>
            </div>
            <hr>
            <div *ngIf="loaded.subscriptionDates" class="row">
                <div class="col-6">
                    Showing <b>{{userSubscriptions.length}}</b> of <b>{{totalCount.subscriptionDates}}</b> entries
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Runs -->
<div class="container-fluid" *ngIf="details">
    <div class="SubscriptionDates-card card mx-auto appshadow">
        <div class="card-header">List of Runs
            <app-page-size [value]="runsSize" (changeEvent)="runsPageSizeChange($event)" class="float-right"
                style="font-size: 1.2em;  margin-left:4px;"></app-page-size>
            <input type="text" class="form-control col-2 float-right" placeholder="search" [(ngModel)]="searchRun"
                (keyup)="getUserRuns()" />
        </div>
        <div class="d-flex justify-content-center">
            <div class="spinner-border " role="status" *ngIf="!loaded.run">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <app-table *ngIf="loaded.run" [sortColumn]='{name: sortColumn.run.table, direction: sortDirection.run}'
                    (buttonClickEvent)="runDetailsClicked($event)" (sortClickEvent)="runsSortClick($event)" [columns]="[
                {
                    title:'ID',
                    name:'id',
                    sortable: true
                 },
                 {
                    title:'Run Name',
                    name:'name',
                    sortable: true
                 },
                 {
                    title:'Pack',
                    name:'packName',
                    sortable: false
                 },
                 {
                    title:'Planned Distance',
                    name:'distance',
                    sortable: false
                 },
                 {
                    title:'Distance Covered',
                    name:'userDistanceCovered',
                    sortable: false
                 },
                 {
                    title:'Creation Date',
                    name:'creationDate',
                    sortable: true
                 },
                 {
                    title:'Start Point',
                    name:'startingPointName',
                    sortable: false
                 },
                 {
                    title:'End Point',
                    name:'endingPointName',
                    sortable: false
                 },
                 {
                    title:'Start Date',
                    name:'startDate',
                    sortable: true
                 },
                 {
                    title:'End Date',
                    name:'endDate',
                    sortable: true
                 },
                 {
                    title:'Status',
                    name:'runStatus',
                    sortable: false
                 },
                 {
                    title:'Type of Run',
                    name:'_isPublic',
                    sortable: false
                 },
                 {
                    title:'Is Creator',
                    name:'packCreatorId',
                    sortable: false
                 },
                 {
                    title:'Attended',
                    name:'_attended',
                    sortable: false
                 },
                 {
                    title:'Is Protected',
                    name:'isProtected',
                    sortable: false
                 },
                 {
                     title:'Actions',
                     name:'_buttonDetails',
                     type: 'button'
                  }
                 ]" [data]="userRuns">
                </app-table>
            </div>
            <app-paging *ngIf="loaded.run" [totalCount]="totalCount.run" [pageLength]="userRuns.length"
                [pageNumber]="pageNumber.run" [totalPages]="totalPages.run" (pageChangeEvent)="onRunPageChange($event)">
            </app-paging>
        </div>
    </div>
</div>

<!-- Payments -->
<div class="container-fluid" *ngIf="details">
    <div class="SubscriptionDates-card card mx-auto appshadow">
        <div class="card-header">

            <span>Payment Activities</span>

            <div class="activity-data form-row">
                <div class="form-group col-md-3 col-sm-8">
                    <label>From</label>
                    <input type="date" class="form-control"
                           #fromActivity
                           [formControl]="dateRange.payment.from"
                           (change)="pageNumber.payment = 1; checkDateForm(dateRange.payment.from, fromActivity); getUserPayments()"
                           min="0001-01-01" max="9999-12-31" />
                </div>
                <div class="form-group col-md-3 col-sm-8">
                    <label>To</label>
                    <input type="date" class="form-control" [formControl]="dateRange.payment.to"
                           #toActivity
                           (change)="pageNumber.payment = 1; checkDateForm(dateRange.payment.to, toActivity); getUserPayments()" min="0001-01-01" max="9999-12-31" />
                </div>
                <div class="form-group col-md-3 col-sm-8">
                    <label>Payment Type</label>
                    <select class="form-control" [formControl]="selectors.payment.paymentType"
                        (ngModelChange)="pageNumber.payment = 1; getUserPayments()">
                        <option value="null">All</option>
                        <option value="Year">Year</option>
                        <option value="Month">Month</option>
                    </select>
                </div>
                <div class="form-group col-md-3 col-sm-3">
                    <label>Actions</label>
                    <select class="form-control" [formControl]="selectors.payment.actions"
                            (ngModelChange)="pageNumber.payment = 1; getUserPayments()">
                        <option value="null">All</option>
                        <option value="payment">Payment</option>
                        <option value="cancellation">Cancellation</option>
                    </select>
                </div>
<!--                <button type="button" (click)="pageNumber.payment = 1; getUserPayments()"-->
<!--                        class="form-control btn btn-primary form-control col-md-3 col-sm-12"-->
<!--                        style="margin-right:10px; margin-bottom:5px; margin-top: 0.95rem;">Search</button>-->
            </div>

            <div>
                <app-page-size [value]="runsSize" (changeEvent)="pageNumber.payment = 1; paymentsPageSizeChange($event)" class="float-right"
                               style="font-size: 1.2em;  margin-left:4px;"></app-page-size>
<!--                <input type="text" class="form-control col-2 float-right" placeholder="search" [(ngModel)]="searchPayments"-->
<!--                       (keyup)="getUserPayments()" />-->
            </div>

        </div>
        <div class="card-body">
            <div class="d-flex justify-content-center">
                <div class="spinner-border " role="status" *ngIf="!loaded.payment">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="table-responsive">
                <app-table *ngIf="loaded.payment"
                    [sortColumn]='{name: sortColumn.payment.table, direction: sortDirection.payment}'
                    (sortClickEvent)="paymentsSortClick($event)" [columns]="[
                    {
                        title:'Date',
                        name:'date',
                        sortable: true
                     },
                     {
                        title:'Action',
                        name:'action',
                        sortable: true
                     },
                     {
                        title:'Amount',
                        name:'_amount',
                        sortable: true
                     },
                     {
                        title:'Payment Type',
                        name:'period',
                        sortable: true
                     },
                     {
                        title:'Payment Method',
                        name:'_paymentMethod',
                        sortable: true
                     }
                     ]" [data]="payments">
                </app-table>
            </div>
            <app-paging *ngIf="loaded.payment" [totalCount]="totalCount.payment" [pageLength]="payments?.length"
                [pageNumber]="pageNumber.payment" [totalPages]="totalPages.payment"
                (pageChangeEvent)="onPaymentPageChange($event)"></app-paging>
        </div>
    </div>
</div>

<!-- Invites -->
<div class="container-fluid" *ngIf="details">
    <div class="SubscriptionDates-card card mx-auto appshadow">
        <div class="card-header">Invites
            <app-page-size [value]="invitesSize" (changeEvent)="invitesPageSizeChange($event)" class="float-right"
                style="font-size: 1.2em;  margin-left:4px;"></app-page-size>
            <input type="text" class="form-control col-2 float-right" placeholder="search" [(ngModel)]="searchInvites"
                (keyup)="getUserInvites()" />
        </div>
        <div class="card-body">
            <div class="d-flex justify-content-center">
                <div class="spinner-border " role="status" *ngIf="!loaded.invite">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="table-responsive">
                <app-table *ngIf="loaded.invite"
                    [sortColumn]='{name: sortColumn.invite.table, direction: sortDirection.invite}'
                    (sortClickEvent)="invitesSortClick($event)" [columns]="[
                    {
                        title:'Pack Name',
                        name:'packName',
                        sortable: true
                     },
                     {
                        title:'Invited Email/Phone/Id',
                        name:'invitedIdentity',
                        sortable: true
                     },
                     {
                        title:'Invitation Date',
                        name:'invitationTime',
                        sortable: true
                     },
                     {
                        title:'Time of status',
                        name:'invitationLUT',
                        sortable: true
                     },
                     {
                        title:'Invited Status',
                        name:'invitationStatus',
                        sortable: true
                     },
                     {
                        title:'Delivery Status',
                        name:'notificationStatus',
                        sortable: true
                     },
                     {
                        title:'Type',
                        name:'invitationType',
                        sortable: true
                     }
                     ]" [data]="invites">
                </app-table>
            </div>
            <app-paging *ngIf="loaded.invite" [totalCount]="totalCount.invite" [pageLength]="invites?.length"
                [pageNumber]="pageNumber.invite" [totalPages]="totalPages.invite"
                (pageChangeEvent)="onInvitesPageChange($event)"></app-paging>
        </div>
    </div>
</div>

<!-- Packs -->
<div class="container-fluid" *ngIf="details">
    <div class="SubscriptionDates-card card mx-auto appshadow">
        <div class="card-header">Packs
            <app-page-size [value]="packsSize" (changeEvent)="packsPageSizeChange($event)" class="float-right"
                style="font-size: 1.2em;  margin-left:4px;"></app-page-size>
            <input type="text" class="form-control col-2 float-right" placeholder="search" [(ngModel)]="searchPacks"
                (keyup)="getUserPacks()" />
        </div>
        <div class="card-body">
            <div class="d-flex justify-content-center">
                <div class="spinner-border " role="status" *ngIf="!loaded.pack">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="table-responsive">
                <app-table *ngIf="loaded.pack"
                    [sortColumn]='{name: sortColumn.pack.table, direction: sortDirection.pack}'
                    (sortClickEvent)="packsSortClick($event)" [columns]="[
                    {
                        title:'Pack Name',
                        name:'packName',
                        sortable: true
                     },
                     {
                        title:'Pack Status',
                        name:'packStatus',
                        sortable: true
                     },
                     {
                        title:'Users Role',
                        name:'roleInPack',
                        sortable: true
                     },
                     {
                        title:'Active Users',
                        name:'packActiveUsersCount',
                        sortable: true
                     },
                     {
                        title:'Creation Date',
                        name:'packCreationTime',
                        sortable: true
                     },
                     {
                        title:'Join Date',
                        name:'createDate',
                        sortable: true
                     },
                     {
                        title:'User Status',
                        name:'statusName',
                        sortable: true
                     }
                     ]" [data]="packs">
                </app-table>
            </div>
            <app-paging *ngIf="loaded.pack" [totalCount]="totalCount.pack" [pageLength]="packsSize"
                [pageNumber]="pageNumber.pack" [totalPages]="totalPages.pack"
                (pageChangeEvent)="onPacksPageChange($event)"></app-paging>

        </div>
    </div>
</div>

<div *ngIf="modalConfig?.show" class="wp-modal">

    <div class="wp-modal-content">
        <div class="wp-modal-header">
            <span class="wp-modal-close" (click)="onCloseModal()">&times;</span>
            <h3>{{modalConfig?.title}}</h3>
        </div>
        <div class="wp-modal-body">
            <div class="container-fluid">

                <form [formGroup]="premiumForm" autocomplete="off">

                    <div class="form-group">
                        <label for="formFirstname">Last payment date</label>
                        <input type="date" min="0001-01-01" max="9999-12-31" class="form-control"
                            formControlName="premiumLastPayment" required="required">
                    </div>

                    <div class="form-group">
                        <label for="formFirstname">Next payment date</label>
                        <input type="date" min="0001-01-01" max="9999-12-31" data-date-format="DD MMMM YYYY"
                            class="form-control" formControlName="premiumNextPayment" required="required">
                    </div>

                    <div class="form-group">
                        <label for="formFirstname">Next payment date</label>
                        <select class="form-control" formControlName="premiumSubscriptionType">
                            <option value="3">Monthly (1 months free)</option>
                            <option value="4">Yearly (1 years free)</option>
                        </select>
                    </div>
                </form>
            </div>
        </div>
        <div class="wp-modal-footer">
            <button type="button" class="btn btn-primary" style="margin-right: 10px;" [disabled]="!premiumForm.valid"
                (click)="makePremium()">
                Submit
            </button>
            <button (click)="onCloseModal()" class="btn btn-secondary">Cancel</button>
        </div>
    </div>

</div>
