<div class="container-fluid ">
    <h3><br>Feedback</h3>
    <hr>
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Filters
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Start Date</label>
                    <input type="date" class="form-control" [formControl]="feedbackForm.get('datefrom')" min="0001-01-01" max="9999-12-31" />
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>End Date</label>
                    <input type="date" class="form-control" [formControl]="feedbackForm.get('dateto')" min="0001-01-01" max="9999-12-31" />
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Search</label>
                    <input type="text" class="form-control" [formControl]="feedbackForm.get('search')" />
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Status</label>
                    <select class="form-control" [formControl]="feedbackForm.get('status')">
                        <option value="{{item.id}}" *ngFor="let item of statusList">{{item.name}}</option>
                    </select>
                </div>

                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>OS</label>
                    <select class="form-control" [formControl]="feedbackForm.get('os')">
                        <option value="{{item.id}}" *ngFor="let item of osList">{{item.name}}</option>
                    </select>
                </div>

                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Feature</label>
                    <select class="form-control" [formControl]="feedbackForm.get('feature')">
                        <option value="{{item.id}}" *ngFor="let item of featureList">{{item.name}}</option>
                    </select>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12 offset-md-8" style="float:left;">
                    <label>&nbsp;</label>
                    <button type="submit " class="btn btn-primary form-control col-12" (click)="onSearch()">See
                        Results</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Feedbacks
            <app-page-size [value]="pageSize" (changeEvent)="onPageSizeChange($event)" class="float-right" *ngIf="searchWasMade" style="font-size: 1.2em; margin-top: 1px; margin-left:4px;"></app-page-size>
        </div>
        <div class="d-flex justify-content-center" style="padding:10px;" *ngIf="!listLoaded && searchWasMade">
            <div class="spinner-border " role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <p *ngIf="!searchWasMade" class="text-center" style="margin-top: 20px;">
                    Press "See Results" for search
                </p>
                <app-table *ngIf="listLoaded && searchWasMade" [sortColumn]='{name: sortColumn.table, direction: sortDirection}' [data]="feedbackList" (sortClickEvent)="sortClick($event)" (buttonClickEvent)="detailsClicked($event)" (selectChangeEvent)="editFeedbackSelect($event)"
                    (textareaKeyupEvent)="editFeedback($event)" [columns]="[
                {  
                    title:'feedbackID',
                    name:'feedbackId',
                    sortable: true
                 },
                {  
                    title:'Comment Date',
                    name:'_feedbackTime',
                    sortable: true
                 },
                 {  
                    title:'Name',
                    name:'_fullName',
                    sortable: true
                 },
                 {  
                    title:'Email',
                    name:'username',
                    sortable: true
                 },
                 {  
                    title:'Number of Current Packs',
                    name:'userActivePacksCount',
                    sortable: true
                 },
                 {  
                    title:'Number of Runs',
                    name:'userTotalRunsCount',
                    sortable: true
                 },
                 {  
                    title:'Country',
                    name:'userRegistrationCountryName',
                    sortable: true
                 },
                 {  
                    title:'State',
                    name:'userRegistrationStateName',
                    sortable: true
                 },
                 {  
                    title:'OS',
                    name:'feedbackDevicePlatformName',
                    sortable: true
                 },
                 {  
                    title:'App Version',
                    name:'feedbackAppVersion',
                    sortable: true
                 },
                 {  
                    title:'Feature',
                    name:'feedbackCategoryName',
                    sortable: true
                 },
                 {  
                    title:'Message',
                    name:'feedbackMessage',
                    sortable: true
                 },
                 {  
                    title:'Status',
                    name:'_selectStatuses',
                    type: 'select',
                    data: statusListForTableSelect,
                    sortable: true
                 },
                 {  
                    title:'Note',
                    name:'adminNote',
                    type: 'textarea',
                    sortable: true
                 },
                 {  
                    title:'Action',
                    name:'_buttonDetails',
                    type: 'button'
                 }           
                 ]">
                </app-table>
            </div>
            <app-paging *ngIf="listLoaded" [totalCount]="totalCount" [pageLength]="feedbackList.length" [pageNumber]="pageNumber" [totalPages]="totalPages" (pageChangeEvent)="onPageChange($event)">
            </app-paging>
        </div>
    </div>
</div>