export class RunsRequestModel {
  model = {
    page: 1,
    pageSize: 10,
    data: {
      filter: {
        status: null,
        dateFrom: null,
        dateTo: null,
        startCountry: null,
        startState: null,
        endCountry: null,
        endState: null,
        whitelist: 2,
        secondarySort: null,
        searchField: null,
        isPublic: null,
        isProtected: null
      },
      recipients: null,
    },
    sort: [{
      field: 'creationDate',
      desc: true
    }],
  };
}
