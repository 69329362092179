<hr>
<nav aria-label="Paging" class="row">
    <div class="col-sm-12 col-md-6">
        Showing <b>{{pageLength}}</b> of <b>{{totalCount}}</b> entries
    </div>
    <div class="col-sm-12 col-md-6" *ngIf="totalPages > 1">
        <ul class="pagination" style="float:right">
            <li class="page-item" [class.disabled]="pageNumber === 1">
                <a class="page-link" aria-label="Previous" href="javascript:void(0)" (click)="onPageChange(pageNumber - 1)">
                    <span aria-hidden="true">Previous</span>
                </a>
            </li>
            <li *ngFor="let page of totalPagesArray; index as i" [class.active]="page === pageNumber" [class.disabled]="isValidPage(1, page)" [hidden]="isValidPage(2, page)" class="page-item">
                <a class="page-link" href="javascript:void(0)" (click)="onPageChange(page)">
                    {{isValidPage(1, page) ? '...' : page}}
                </a>
            </li>
            <li class="page-item" [class.disabled]="pageNumber === totalPages">
                <a class="page-link" aria-label="Next" href="javascript:void(0)" (click)="onPageChange(pageNumber + 1)">
                    <span aria-hidden="true">Next</span>
                </a>
            </li>
        </ul>
    </div>
</nav>