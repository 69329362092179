<div class="main-container">
    <div class="header">
        <h5 class="modal-title">Activate</h5>
        <span type="button" class="header-close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </span>
    </div>
    <div class="row actions-container subscription-container" *ngIf="!isAnyLoading()">
        <ng-container *ngIf="subscriptions.length > 0" >
            <div class="actions-container-header">
                List of subscriptions
            </div>
            <app-subscription-table [subscriptions]="subscriptions"
                                    [showMenu]="false"
                                    [description]="description"></app-subscription-table>
        </ng-container>
        <ng-container *ngIf="subscriptions.length === 0" >
            <div class="actions-container-header">
                Select subscription. It will be used after activation
            </div>
            <div class="item" *ngFor="let subscription of availableSubscriptions"
                 (click)="setSubscriptionId(subscription.subscriptionTypeId)"
                 [ngClass]="{
                'selected': subscription.subscriptionTypeId === subscriptionPriceId}">
            <span>
                {{ subscription.name }} subscription
            </span>
            </div>
        </ng-container>

    </div>
    <div>
        <mat-progress-bar mode="indeterminate" *ngIf="isAnyLoading()"></mat-progress-bar>
    </div>
    <div class="row actions-container">
        <div class="col-lg-12">
            <div class="col-lg-12 col-sm-12 text-center">
                <button mat-button class="btn  btn-border-radius m-l-15 submit-btn"
                        (click)="save()" [disabled]="isAnyLoading()">
                    Confirm
                </button>
                <button mat-button class="btn btn-border-radius m-l-15"
                        (click)="close()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
