import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { Observable } from 'rxjs';
import { IResult } from 'src/app/models/shared/iresult';
import { RunsRequestModel } from '../model/runs.requestmodel';
import { SingleRunUpdateModel } from '../model/singlerun.update.model';

@Injectable({
  providedIn: 'root'
})
export class RunsService {

  constructor(private service: ApiService) {
  }

  getCurationRuns(payload: any): Observable<IResult> {
    return this.service.post('curation/run/get/filtered', payload);
  }

  updateCurationRun(data): Observable<IResult> {
    return this.service.post('curation/run/edit', {data});
  }

  deleteCurationRun(id: number): Observable<IResult> {
    return this.service.post('curation/run/edit', {
      data: {
        id,
        status: 2
      }
    });
  }

  getRuns(model: RunsRequestModel): Observable<IResult> {
    return this.service.post('run/get/filtered',
      model.model
    );
  }

  curate(id: number): Observable<IResult> {
    return this.service.post('curation/run/add',
      {
        data: {
          id
        }
      }
    );
  }

  delete(id: number): Observable<IResult> {
    return this.service.post('run/remove',
      {
        data: {
          id
        }
      }
    );
  }

  getRunUsersList(runId: number): Observable<IResult> {
    return this.service.post('run/search/run/users',
      {
        data: {runId}
      }
    );
  }

  getRun(runId: number): Observable<IResult> {
    return this.service.post('run/get',
      {
        data: {id: runId}
      }
    );
  }

  update(model: SingleRunUpdateModel): Observable<IResult> {
    return this.service.post('run/edit',
      {data: model}
    );
  }

}
