import {Action} from '@ngrx/store';


export enum ECommonActions {
  SetSidebarCollapseExpand = '[Common] Set Sidebar Collapse Expand',
  SetTopbarCollapseExpand = '[Common] Set Topbar Collapse Expand',
  SetLoading = '[Common] Set Loading'
}

export class SetSidebarCollapseExpand implements Action {
  public readonly type = ECommonActions.SetSidebarCollapseExpand;

  constructor(public payload: boolean) {
  }
}

export class SetTopbarCollapseExpand implements Action {
  public readonly type = ECommonActions.SetTopbarCollapseExpand;

  constructor(public payload: boolean) {
  }
}

export class SetLoading implements Action {
  public readonly type = ECommonActions.SetLoading;

  constructor(public payload: boolean) {
  }
}

export type CommonActions =
  SetSidebarCollapseExpand |
  SetTopbarCollapseExpand |
  SetLoading;
