<div class="appshadow">
    <div *ngIf="!mapLoaded" class="d-flex justify-content-center "
         style="padding:10px;  height:300px; max-height: 300px;">
        <div class="spinner-border " role="status">
            <span class="sr-only">...</span>
        </div>
    </div>
    <app-googlemap #mapcomponent *ngIf="runDetails && mapLoaded"
                   [avoidFerries]="runDetails?.avoidFerries"
                   [avoidTolls]="runDetails?.avoidTolls"
                   [avoidHighways]="runDetails?.avoidHighways"
                   [start]="start"
                   [end]="end"
                   [waypoints]="waypoints"
                   (onMapResponse)="onReceiveMapResponse($event)">
    </app-googlemap>

</div>

<div *ngIf="showForm" class="table-responsive appshadow" style="margin-top:20px; padding-bottom:20px;">
    <div *ngIf="!mapLoaded" class="d-flex justify-content-center " style="padding:10px;">
        <div class="spinner-border " role="status">
            <span class="sr-only">...</span>
        </div>
    </div>

    <div *ngIf="mapLoaded" class="container-fluid" style="padding-top:10px; ">

        <div class="col-8 float-left" area="controls">
            <div class="form-row">
                <div class="form-group col-md-6 col-sm-12" style="float:left;">
                    <label>Name</label>
                    <input type="text" class="form-control" [formControl]="runForm.get('name')"/>
                </div>
                <div class="form-group col-md-6 col-sm-12" style="float:left;">
                    <label>Start</label>
                    <input type="text" class="form-control"
                           [hidden]="importExportOnly"
                           [formControl]="runForm.get('start')"/>
                    <app-googleautocomplete *ngIf="importExportOnly"
                                            class="col-sm-12 form-control"
                                            [autoCompleteInput]="runDetails.startingPointName | titlecase"
                                            (setAddress)="onStartPointChange($event)"
                                            addressType="geocode"
                                            style="width:100%;">
                    </app-googleautocomplete>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6 col-sm-12" style="float:left;">
                    <textarea class="form-control"
                              [hidden]="hideNote"
                              [formControl]="runForm.get('note')"
                              placeholder="Note"
                              style="margin-top:8px;"></textarea>
                </div>
                <div class="form-group col-md-6 col-sm-12 " style="float:left; ">
                    <label>Destination</label>
                    <input type="text" class="form-control"
                           [hidden]="importExportOnly"
                           [formControl]="runForm.get('end')"/>
                    <app-googleautocomplete *ngIf="importExportOnly"
                                            class="col-sm-12 form-control"
                                            [autoCompleteInput]="runDetails.endingPointName | titlecase"
                                            (setAddress)="onEndPointChange($event)"
                                            addressType="geocode"
                                            style="width:100%;">
                    </app-googleautocomplete>
                </div>
            </div>

            <div class="form-row" style="padding-left:13px;">
                <div class="form-group  col-md-4 col-sm-12" style="float:left;">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="highways"
                               [formControl]="runForm.get('avoidhighways') " (change)="changeHighways()">
                        <label class="form-check-label" for="highways">
                            Avoid Highways
                        </label>
                    </div>
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="tolls"
                               [formControl]="runForm.get('avoidtolls') " (change)="changeTolls()">
                        <label class="form-check-label" for="tolls">
                            Avoid Tolls
                        </label>
                    </div>
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <div class="input-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="ferries"
                                   [formControl]="runForm.get('avoidferries')" (change)="changeFerries()">
                            <label class="form-check-label" for="ferries">
                                Avoid Ferries
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="input-group col-lg-6  float-left" style="padding: 0px;">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <input type="checkbox" [(ngModel)]="allowUpdate">
                    </div>
                </div>
                <button class="btn btn-success form-control" (click)="confirmUpdate()" [disabled]="!allowUpdate">
                    Update
                </button>
            </div>


            <div class="input-group col-lg-6  float-left">
                <div class="alert alert-success form-control" *ngIf="updateSuccess === 1" role="alert"
                     style="max-height: 38px; padding-top:5px !important; width:100%;">
                    The Run Has Been Updated
                </div>
            </div>


        </div>

        <div class="col-4 float-left" area="waypoints">
            <div class="form-row">
                <label>&nbsp;</label>
                <button (click)="addwaypointVisible = !addwaypointVisible"
                        class="form-control btn btn-success col-sm-12">
                    Add Waypoints
                </button>

                <app-googleautocomplete *ngIf="addwaypointVisible"
                                        class="col-sm-12 form-control"
                                        (setAddress)="setAddress($event)"
                                        addressType="geocode"
                                        style="width:100%; margin-top:10px;">
                </app-googleautocomplete>

                <div class="form-group col-sm-12" style="float:left; margin-bottom:10px;">
                    <label></label>
                    <div>
                        <div *ngFor="let item of waypoints;" class="col-xs-12" style="cursor: pointer;">
                            <div class="input-group mb-3 col-xs-12" style="min-width:100% !important">
                                <div class="input-group-prepend col-xs-12">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-window-restore text-primary" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <input type="text" disabled class="form-control" value="{{item.location.title}}"
                                       aria-label="Username" aria-describedby="basic-addon1">
                                <div class="input-group-append">
                                    <span (click)="removeWaypoint(item.location.lat, item.location.lng)"
                                          class="input-group-text"
                                          id="basic-addon2">
                                        <i class="fa fa-window-close text-primary" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showUsersTable" class="table-responsive appshadow " style="margin-top:20px; ">
    <div class="d-flex justify-content-center " style="padding:10px; " *ngIf="!usersList || usersList.length < 1">
        <div class="spinner-border " role="status">
            <span class="sr-only ">...</span>
        </div>
    </div>

    <app-table [data]="usersList" *ngIf="usersList && usersList.length > 0"
               [columns]="[ { title:'Email', name:'username', sortable: false }, { title:'Name', name:'_fullName', sortable: false }, { title:'Participated', name:'_participated', sortable: false } ]">
    </app-table>
</div>
