import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  EUserActions,
  LoadUserToken,
  SetUserData,
  RemoveUserDataStorage,
  RemoveUserDataState,
  LoadUserTokenError
} from '../actions/user.actions';
import { LoginService } from 'src/app/authentication/login/service/login.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IResult } from 'src/app/models/shared/iresult';
import { environment } from '../../environments/environment';

@Injectable()
export class UserEffects {

  domains: any;

  constructor(private _actions$: Actions, private service: LoginService, private router: Router) {
    this.domains = environment.domains;
  }

  setuserdata$ = createEffect(() =>
    this._actions$.pipe(
    ofType<LoadUserToken>(EUserActions.LoadUserToken),
    switchMap(action => this.service.login(action.payload.username, action.payload.password)),
    switchMap((data: IResult) => {
      if (data && data.result && data.result.data[0] && data.result.data[0].token) {
        if (data.result.data[0].pscUser.isAdmin) {
          return of(new SetUserData({
            token: data.result.data[0].token,
            authenticated: true,
            authenticating: false,
            data: data.result.data[0].pscUser,
            error: null
          }));
        }

        /**
         * @todo redirect to redirectUrl from env
         */

        const encoded = btoa(unescape(encodeURIComponent(JSON.stringify(data.result.data[0]))));
        const isAdmin = !!data.result.data[0].isAdmin;

        const urls = this.domains[location.host];
        const redirectUrl = isAdmin ? urls.adminRedirectUrl : urls.userRedirectUrl;

        window.location.href = redirectUrl + '/#/userredirect/' + encoded;
      } else {
        /**
         * @todo returns autherror message
         */
        return of(new LoadUserTokenError({error: data.errors[0].explanation}));
      }
    }), tap(
      data => {
        /**
         * @todo /login to be app settings constant which is easier to modify
         */
        localStorage.setItem('user', JSON.stringify(data.payload));
        this.router.navigate(['/welcome']);
      }
    )
  ));

  removeuserdata$ = createEffect(() =>this._actions$.pipe(
    ofType<RemoveUserDataStorage>(EUserActions.RemoveUserDataStorage),
    switchMap(data => {

      /**
       * @todo /logout from state
       */
      return of(new RemoveUserDataState({
        token: null,
        authenticated: false,
        authenticating: false,
        data: null,
        error: null
      }));
    }), tap(
      data => {
        /**
         * @todo /logout from storage
         */
        localStorage.removeItem('user');
        this.router.navigate(['/login']);
      }
    ),
  )
  );
}
