import {Component, OnInit, OnDestroy, LOCALE_ID, Inject, ViewChild} from '@angular/core';
import { FeedService } from './service/feed.service';
import { DatePipe } from '@angular/common';
import { FileUploadService } from '../../services/file-upload.service';
import Swal from 'sweetalert2';
import {DatePickerComponent} from 'ng2-date-picker';
import {StatusEnum} from '../../enums/status.enum';
const systemId = -1;

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.css'],
  providers: [DatePipe]
})
export class FeedComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @ViewChild('dayPicker') datePicker;

  now = new Date();

  datePickerConfig = {
    firstDayOfWeek: 'su',
    monthFormat: 'MMM, YYYY',
    disableKeypress: false,
    allowMultiSelect: false,
    closeOnSelect: undefined,
    closeOnSelectDelay: 100,
    onOpenDelay: 0,
    weekDayFormat: 'ddd',
    appendTo: document.body,
    drops: 'down',
    opens: 'right',
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    format: "YYYY-MM-DD HH:mm",
    yearFormat: 'YYYY',
    showGoToCurrent: true,
    dayBtnFormat: 'DD',
    monthBtnFormat: 'MMM',
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 1,
    secondsFormat: 'ss',
    secondsInterval: 1,
    showSeconds: false,
    showTwentyFourHours: true,
    timeSeparator: ':',
    multipleYearsNavigateBy: 10,
    showMultipleYearsNavigation: false,
    minTime: this.now.toString(),
  };

  loading = {
    details: false,
    post: false,
    list: false
  };

  authorDetails;

  feedForm = {
    name: '',
    pictureUrl: ''
  };

  feedPostForm = {
    id: '',
    title: '',
    text: '',
    image: undefined,
    imageUrl: undefined,
    isPushed: false,
    scheduledTime: undefined,
    userId: undefined
  };

  pageSize = 10;
  pageNumber = 1;
  systemPosts = [];
  totalCount = 0;
  totalPages = 0;
  searchField = '';
  sortColumn: any = { column: 'scheduledTime', direction: undefined };
  currentSystemPost;

  constructor(private service: FeedService,
              private datePipe: DatePipe,
              private fileUploadService: FileUploadService,
              @Inject( LOCALE_ID ) private locale: string) { }

  ngOnInit() {
    this.loadDetails();
  }

  isValidDate() {
    return +new Date(this.feedPostForm.scheduledTime) >= +this.now;
  }

  modelChanges($event) {

  }

  private loadDetails(success?, error?) {
    this.loading.details = true;
    this.service.findUser(systemId)
      .subscribe((t) => {
        this.loading.details = false;
        this.authorDetails = t.result.data[0];
        this.updateDetails();
        if (success) {
          success();
        }
      }, () => {
        this.loading.details = false;
        if (error) {
          error();
        }
      });
  }

  onPageSizeChange(event) {
    this.pageSize = event.value;
    this.seeResults();
  }

  private updateDetails() {
    this.feedForm.name = this.authorDetails.firstName;
    this.feedForm.pictureUrl = this.authorDetails.pictureUrl;
  }

  ngOnDestroy() {
  }

  public fileChangeEvent(event: any): void {
    if (event.target.files[0]) {
      this.loading.details = true;
      this.service.uploadImage(event.target.files[0], systemId).subscribe((response) => {
        this.loading.details = false;
        if (response.result && response.result.data) {
          this.feedForm.pictureUrl = response.result.data[0].pictureUrl;
        }
      }, () => {
        this.loading.details = false;
      });
    }
  }

  public cancelPostAuthorDetails() {
    this.updateDetails();
  }

  public savePostAuthorDetails() {
    this.loading.details = true;
    this.service.updateUser({
      firstName: this.feedForm.name,
      lastName: ''
    },  systemId)
      .subscribe((t) => {
        this.loadDetails(() => {
          this.loading.details = false;
          Swal.fire({
            title: 'Success',
            text: 'Saved',
          });
        }, () => {
          this.loading.details = false;
        });
      }, () => {
        this.loading.details = false;
      });
  }

  public filePostImageChangeEvent(event: any): void {
    if (event.target.files[0]) {
      this.feedPostForm.image = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.feedPostForm.image);
      reader.onload = (evnt) => {
        this.feedPostForm.imageUrl = reader.result;
      };
    }
  }

  public savePostDetails() {
    const response = () => {
      this.loading.post = false;
      Swal.fire({
        title: 'Success',
        text: 'Saved',
      });
      this.cancelPostDetails();
      this.seeResults();
    };
    this.loading.post = true;
    if (this.feedPostForm.id) {
      const object = Object.assign({}, this.feedPostForm);

      object['scheduledTime'] =
        new Date(this.feedPostForm.scheduledTime).toISOString();

      this.service.updatePost(this.feedPostForm.id, object)
        .subscribe((t) => {
          this.loading.post = false;
          if (this.feedPostForm.image) {
            if (this.currentSystemPost &&
              this.currentSystemPost.files && this.currentSystemPost.files[0] &&
              this.currentSystemPost.files[0].path) {
              this.loading.post = true;
              this.service.updatePostFile(this.currentSystemPost.files[0].id,
                this.feedPostForm.image)
                .subscribe((rslt) => {
                  this.loading.post = false;
                  response();
                }, () => {
                  this.loading.post = false;
                });
            } else {
              this.loading.post = true;
              this.service.savePostFile(this.feedPostForm.id,
                this.feedPostForm.image)
                .subscribe((rslt) => {
                  this.loading.post = false;
                  response();
                }, () => {
                  this.loading.post = false;
                });
            }
          } else {
            response();
          }
        }, () => {
          this.loading.post = false;
        });
    } else {
      const object = Object.assign({}, this.feedPostForm);

      object['scheduledTime'] =
        new Date(this.feedPostForm.scheduledTime).toISOString();

      this.service.savePost(object)
        .subscribe((t) => {
          this.loading.post = false;
          this.feedPostForm.id = t.result.data[0].id;
          if (this.feedPostForm.image) {
            if (this.currentSystemPost && this.currentSystemPost.files &&
              this.currentSystemPost.files[0] && this.currentSystemPost.files[0].path) {
              this.loading.post = true;
              this.service.updatePostFile(this.currentSystemPost.files[0].id,
                this.feedPostForm.image)
                .subscribe((rslt) => {
                  this.loading.post = false;
                  response();
                }, () => {
                  this.loading.post = false;
                });
            } else {
              this.loading.post = true;
              this.service.savePostFile(this.feedPostForm.id,
                this.feedPostForm.image)
                .subscribe((rslt) => {
                  this.loading.post = false;
                  response();
                }, () => {
                  this.loading.post = false;
                });
            }
          } else {
            response();
          }
        }, () => {
          this.loading.post = false;
        });
    }

  }

  public cancelPostDetails() {
    this.currentSystemPost = undefined;
    Object.keys(this.feedPostForm).forEach(key => {
      this.feedPostForm[key] = '';
    });
  }

  public sortPosts(event) {
    switch (event.sort.column) {
      case 'scheduledTime':
        this.sortColumn.column = 'scheduledTime';
        this.sortColumn.direction = event.sort.direction;
        break;
    }
    this.seeResults();
  }

  public btnClicked(event) {
    console.log(event)
    switch (event.column.name) {
      case '_buttonDetails':
        this.details(event.record);
        break;
      case '_buttonDelete':
        this.delete(event.record);
        break;
      default:
        break;
    }
  }

  private delete(record) {
    this.loading.details = true;
    Swal.fire({
      title: 'Warning',
      text: 'This post will be removed from the schedule and not sent to users. Are you sure?',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'green'
    }).then(data => {
      if (data.value) {
        this.service.deletePost(record.id)
          .subscribe(post => {
            this.loading.details = false;
            Swal.fire({
              title: 'Success',
              text: 'Saved',
            });
            this.seeResults();
          }, () => {

          });
      }
    });
  }

  private details(record) {
    this.currentSystemPost = record;
    this.feedPostForm.id = this.currentSystemPost.id;
    this.feedPostForm.title = this.currentSystemPost.title;
    this.feedPostForm.text = this.currentSystemPost.text;
    this.feedPostForm.imageUrl = this.currentSystemPost.files[0] ? this.currentSystemPost.files[0].path : '';
    this.feedPostForm.userId = this.currentSystemPost.userId;
    this.feedPostForm.scheduledTime = new Date(this.currentSystemPost.scheduledTime);
  }

  public seeResults() {
    this.loading.list = true;
    this.service.listPosts(this.searchField,
      this.pageSize,
      this.pageNumber - 1,
      this.sortColumn.column,
      this.sortColumn.direction)
      .subscribe((response) => {
        this.loading.list = false;
        if (response && response.result && response.result.data) {
          this.systemPosts = response.result.data;
          this.totalCount = response.result.totalCount;
          this.totalPages = Math.ceil(this.totalCount / this.pageSize);
          this.updateSystemPosts();
        }
      }, () => {
        this.loading.list = false;
      });
  }

  public onPageChange(pageNumber) {
    if (this.pageNumber === pageNumber) {
      return false;
    }
    this.pageNumber = pageNumber || 1;
    this.seeResults();
    return false;
  }

  private updateSystemPosts() {
    this.systemPosts.forEach(x => {
      x._buttonDetails = { value: 'Edit', visible: x.statusId === StatusEnum.NOT_YET_STARTED,
        class: 'btn btn-primary btn-lg btn-block' };
      x._buttonDelete = { value: 'Delete', visible: x.statusId === StatusEnum.NOT_YET_STARTED,
        class: 'btn btn-warning btn-lg btn-block' };
    });
  }

}
