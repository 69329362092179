import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IAppState} from 'src/store/states/app.state';
import {Store} from '@ngrx/store';
import * as commonActions from '../../../../store/actions/common.actions';
import {finalize} from 'rxjs/operators';


@Injectable()
export class LoadInterceptor implements HttpInterceptor {

  constructor(private store: Store<IAppState>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(new commonActions.SetLoading(true));
    return next.handle(request.clone()).pipe(
      finalize(() => {
        this.store.dispatch(new commonActions.SetLoading(false));
      })
    );
  }
}
