<div class="container-fluid ">
    <br>
    <h3>
        <span>Runs /</span>
        <a [routerLink]="['/run-library']" style="text-decoration: none; color: #212529; opacity: 0.5;">
            Run Library
        </a>
    </h3>
    <hr>
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Filters
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Start Country</label>
                    <select class="form-control" [formControl]="runsForm.get('startcountry')"
                            (change)="startCountriesChange()">
                        <option value="{{item.code}}" *ngFor="let item of countryList">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>End Country</label>
                    <select class="form-control" [formControl]="runsForm.get('endcountry')"
                            (change)="endCountriesChange()">
                        <option value="{{item.code}}" *ngFor="let item of endcountryList">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Status</label>
                    <select class="form-control" [formControl]="runsForm.get('status')">
                        <option value="{{item.id}}" *ngFor="let item of statusList">{{item.name}}</option>
                    </select>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Start State</label>
                    <select class="form-control" [formControl]="runsForm.get('startstate')">
                        <option value="{{item.code}}" *ngFor="let item of stateList">{{item.name}}</option>
                    </select>
                </div>

                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>End State</label>
                    <select class="form-control" [formControl]="runsForm.get('endstate')">
                        <option value="{{item.code}}" *ngFor="let item of endstateList">{{item.name}}</option>
                    </select>
                </div>

                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Search</label>
                    <input type="text" class="form-control" [formControl]="runsForm.get('search')">
                </div>
            </div>

            <div class="form-row">

                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Type of Run</label>
                    <select class="form-control" [formControl]="runsForm.get('publicRuns')"
                            (change)="publicRunsChanges()">
                        <option value="{{item.id}}" *ngFor="let item of publicRuns">{{item.name}}</option>
                    </select>
                </div>

                <div class="form-group col-md-4 col-sm-12" style="float:left;">
                    <label>Protected Run</label>
                    <select class="form-control" [formControl]="runsForm.get('protectedRuns')"
                            (change)="protectedRunsChanges()">
                        <option value="{{item.id}}" *ngFor="let item of protectedRuns">{{item.name}}</option>
                    </select>
                </div>

            </div>

            <div class="form-row">
                <div class="form-group col-md-4 col-sm-12 offset-md-8" style="float:left;">
                    <label>&nbsp;</label>
                    <button type="submit " class="btn btn-primary form-control col-12" [disabled]="!countriesListLoaded"
                            (click)="onSearch()">See
                        Results
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            Runs
            <app-page-size [value]="pageSize" (changeEvent)="onPageSizeChange($event)" class="float-right"
                           *ngIf="searchWasMade"
                           style="font-size: 1.2em; margin-top: 1px; margin-left:4px;"></app-page-size>
        </div>
        <div class="d-flex justify-content-center" style="padding:10px;" *ngIf="!listLoaded && searchWasMade">
            <div class="spinner-border " role="status">
                <span class="sr-only">...</span>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <p *ngIf="!searchWasMade" class="text-center" style="margin-top: 20px;">
                    Press "See Results" for search
                </p>
                <app-table *ngIf="listLoaded && searchWasMade"
                           [sortColumn]='{name: sortColumn.table, direction: sortDirection}' [data]="runsList"
                           (sortClickEvent)="sortClick($event)" (buttonClickEvent)="btnTableClicked($event)"
                           (selectChangeEvent)="editFeedbackSelect($event)"
                           (textareaKeyupEvent)="editFeedback($event)" [columns]="[
                {
                    title:'Run Name',
                    name:'name',
                    sortable: true
                 },
                 {
                  title:'id',
                  name:'id',
                  sortable: true
                 },
                 {
                  title:'Pack Name',
                  name:'packName',
                  sortable: true
                 },
                 {
                  title:'Creator',
                  name:'_creator',
                  type: 'a',
                  sortable: true
                 },
                 {
                  title:'Creation Date',
                  name:'_creationDate',
                  sortable: true
                 },
                 {
                  title:'Start Point',
                  name:'startingPointName',
                  sortable: true
                 },
                 {
                  title:'Destination',
                  name:'endingPointName',
                  sortable: true
                 },
                 {
                  title:'Run Start Date',
                  name:'_startDate',
                  sortable: true
                 },
                 {
                  title:'Run End Date',
                  name:'_endDate',
                  sortable: true
                 },
                 {
                  title:'Users Currently on the Run',
                  name:'usersOnRun',
                  sortable: true
                 },
                 {
                  title:'Status',
                  name:'_runStatus',
                  sortable: true
                 },
                 {
                  title:'Type of Run',
                  name:'_isPublic',
                  sortable: true
                 },
                 {
                  title:'Protected',
                  name:'_isProtected',
                  sortable: true
                 },
                 {
                  title:'Waypoints',
                  name:'waypointsCount',
                  sortable: true
                 },
                  {
                      title:'',
                      name:'_buttonDelete',
                      type: 'button'
                   },
                   {
                       title: 'Actions',
                       name:'_buttonDetails',
                       type: 'button',
                       headerAlign: 'center'
                    },
                    {
                        title:'',
                        name:'_buttonCuration',
                        type: 'button'
                     }
                 ]">
                </app-table>
            </div>
            <app-paging *ngIf="listLoaded" [totalCount]="totalCount" [pageLength]="runsList.length"
                        [pageNumber]="pageNumber" [totalPages]="totalPages" (pageChangeEvent)="onPageChange($event)">
            </app-paging>
        </div>
    </div>
</div>

<div *ngIf="modalConfig?.show" class="wp-modal">
    <div class="wp-modal-content justify-content-center col-10" style="margin-top: -80px; padding-bottom: 15px;">
        <div class="wp-modal-header">
            <h3 class="col-12">{{modalConfig?.title}}
                <span class="wp-modal-close" (click)="onCloseModal()">&times;</span>
            </h3>
        </div>
        <div class="wp-modal-body" style="max-height :600px; overflow: auto; overflow-x: scroll;">
            <app-runsdetails [runId]="modalConfig.runId" *ngIf="modalConfig?.show"></app-runsdetails>
        </div>
    </div>
</div>
