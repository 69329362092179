<div class="container-fluid">
    <div class="header-container">
        <div>
            <h3><br>Feed Management</h3>
        </div>
        <div *ngIf="loading.details || loading.post" class="d-flex justify-content-center loader-content">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <br/>
    <div class="card mx-auto appshadow">
        <div class="card-header">
            System Posts
        </div>
        <div class="card-body" id="createNews">
                <div class="form-group post-details-container">
                    <div class="container-post-part container-post-part-container-input">
                        <label class="input-label">Post author name</label>
                        <input type="text" [(ngModel)]="feedForm.name"
                               [disabled]="loading.details"
                               class="form-control" required="required">
                    </div>
                    <div class="container-post-part">
                        <button type="button" class="btn btn-warning option-btn" (click)="savePostAuthorDetails()"
                                [disabled]="!feedForm.name || loading.details">Save</button>
                        <button type="button" class="btn btn-primary option-btn" (click)="cancelPostAuthorDetails()"
                                [disabled]="loading.details">Cancel</button>
                    </div>
                </div>
                <div class="form-group post-details-container">
                    <div class="container-post-part container-post-part-container-input">
                        <label class="input-label">Post author logo</label>
                        <input type="file"
                               (change)="fileChangeEvent($event)"
                               #imageSelection
                               accept="image/x-png,image/jpeg" class="image-upload"/>
                        <div class="image-upload-container">
                        <img [src]="feedForm?.pictureUrl"
                             onerror="this.src='assets/images/placeholder.png';"
                             class="profile-img"/>
                        </div>
                    </div>
                    <div class="container-post-part">
                        <button type="button" class="image-upload-box btn btn-warning  option-btn"
                                [disabled]="loading.details"
                                (click)="imageSelection.click()" >
                            Upload and Save
                        </button>
                    </div>
                </div>

                <div class="form-group">
                    <b>{{!feedPostForm.id ? 'Create' : 'Update'}} System Feed Post</b>
                    <br/>
                    <br/>
                    <div>
                        <div class="form-group post-details-container">
                            <div class="container-post-part container-post-part-container-input">
                                <label class="input-label">Headline</label>
                                <input type="text" [(ngModel)]="feedPostForm.title"
                                       [disabled]="loading.post"
                                       class="form-control" required="required">
                            </div>
                        </div>
                        <div class="form-group post-details-container">
                            <div class="container-post-part container-post-part-container-input">
                                <label class="input-label">Description</label>
                                <textarea [(ngModel)]="feedPostForm.text"
                                       [disabled]="loading.post"
                                       class="form-control" required="required">
                                </textarea>
                            </div>
                        </div>
                        <div class="form-group post-details-container">
                            <div class="container-post-part container-post-part-container-input">
                                <label class="input-label">Image</label>
                                <input type="file"
                                       (change)="filePostImageChangeEvent($event)"
                                       #imageSelectionForPost
                                       [disabled]="loading.post"
                                       accept="image/x-png,image/jpeg" class="image-upload"/>
                                <div class="image-upload-container" (click)="imageSelectionForPost.click()" >
                                    <img [src]="feedPostForm?.imageUrl"
                                         onerror="this.src='assets/images/placeholder.png';"
                                         class="post-image"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group post-details-container" *ngIf="!feedPostForm.isPushed">
                                <div class="container-post-part container-post-part-container-input">
                                        <label class="input-label">Scheduled</label>
                                    <div class="input-container">
                                        <input [readonly]="true" mode="daytime" [(ngModel)]="feedPostForm.scheduledTime"
                                               (ngModelChange)="modelChanges($event)"
                                               class="form-control datetime-picker-for-post"
                                               #dayPicker
                                               [disabled]="loading.post"
                                               [dpDayPicker]="datePickerConfig"
                                               placeholder="YYYY-DD-MM HH:MM" theme="dp-material"/>
                                        <div class="error-text" *ngIf="feedPostForm.scheduledTime &&
                                        !isValidDate() ">Date should be more than now</div>
                                    </div>

                                </div>
                        </div>

                        <div>
                            <button type="button" class="btn btn-warning option-btn" (click)="savePostDetails()"
                                    [disabled]="loading.post || !feedPostForm.text || !feedPostForm.title ||
                                    (!feedPostForm.isPushed && !feedPostForm.scheduledTime) || !isValidDate()">Scheduled</button>
                            <button type="button" class="btn btn-primary option-btn" (click)="cancelPostDetails()"
                                    [disabled]="loading.post">Cancel</button>
                        </div>


                    </div>
                </div>


        </div>
    </div>
    <div class="systemusers-card card mx-auto appshadow">
        <div class="card-header">
            <button type="button" class="btn btn-primary option-btn" (click)="seeResults()"
                    [disabled]="loading.list">See results</button>
            <app-page-size [value]="pageSize" (changeEvent)="onPageSizeChange($event)" class="float-right"
                           style="font-size: 1.2em; margin-top: 1px; margin-left:4px;"></app-page-size>
        </div>
        <div>
            <div class="card-body" style="margin-top:-21px;">
                <div class="table-responsive" >
                    <app-table  *ngIf="!loading.list"
                                [sortColumn]='{name: sortColumn.column, direction: sortColumn.direction}'
                                [data]="systemPosts"
                                    [columns]="[
                                         {
                                             title:'Title',
                                             name:'title',
                                             sortable: false
                                          },
                                          {
                                            title:'Text',
                                            name:'text',
                                            sortable: false
                                         },
                                         {
                                            title:'Status',
                                            name:'statusId',
                                            pipe: 'status',
                                            sortable: false
                                         },
                                         {
                                            title:'Scheduled time',
                                            name:'scheduledTime',
                                            pipe: 'date',
                                            sortable: true
                                         },
                                         {
                                             title: 'Edit',
                                             name:'_buttonDetails',
                                             type: 'button',
                                             headerAlign: 'center'
                                          },
                                          {
                                             title: 'Uncheduled',
                                             name:'_buttonDelete',
                                             type: 'button',
                                             headerAlign: 'center'
                                          }
                                        ]"
                               (buttonClickEvent)="btnClicked($event) "
                                (sortClickEvent)="sortPosts($event)">
                    </app-table>
                    <app-paging *ngIf="!loading.list" [totalCount]="totalCount" [pageLength]="systemPosts.length"
                                [pageNumber]="pageNumber" [totalPages]="totalPages"
                                (pageChangeEvent)="onPageChange($event)">
                    </app-paging>
                    <div *ngIf="loading.list" class="d-flex justify-content-center loader-content">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
