<nav class="topbar navbar navbar-default" [class.topbar-expanded]="topbarExpanded">
    <div style="display: inline-flex; justify-content: space-between; width: 100%;">
        <div [ngClass]="menuExpanded ? 'topbar-left' : 'topbar-left-collapsed'">
            <i class="topbar-bars-icon fa fa-bars" (click)="toggleMenu()"></i>
        </div>
        <div class="topbar-right">
            <i class="topbar-expand-icon fa fa-expand" (click)="changeSize()"></i>
            <i class="topbar-sign-out-icon fa fa-sign-out-alt " (click)="logout()"></i>
        </div>
    </div>
</nav>