import { Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { RunUsersResponseModel } from '../model/runusers.responsemodel';
import { SingleRunResponseModel } from '../model/singlerun.responsemodel';
import { Directions } from '../model/googledirectionsmodel';
import { Subscription } from 'rxjs';
import { RunsService } from '../service/runs.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Guid } from '../../../shared/classes/guid';
import { SingleRunUpdateModel } from '../model/singlerun.update.model';
import Swal from 'sweetalert2';
import { GooglemapComponent } from 'src/app/components/googlemap/googlemap.component';

@Component({
  selector: 'app-runsdetails',
  templateUrl: './runsdetails.component.html',
  styleUrls: ['./runsdetails.component.css']
})
export class RunsdetailsComponent implements OnInit, OnDestroy {
  @ViewChild('mapcomponent') child: GooglemapComponent;

  @Input() runId: number;
  @Input() showUsersTable = false;
  @Input() importExportOnly = false;
  @Input() importedRunData: any;
  @Input() hideNote = false;
  @Output() exportData = new EventEmitter<any>();

  usersList: RunUsersResponseModel[];
  runDetails = new SingleRunResponseModel();
  showForm: boolean;
  getRunSubscription = new Subscription();
  getRunUsersSubscription = new Subscription();
  updateRunUsersSubscription = new Subscription();
  allowUpdate = false;
  updateSuccess?: number = null;

  directions: Directions[] = [];
  mapLoaded: boolean = false;
  start: any;
  end: any;
  waypoints: any[] = [];
  addwaypointVisible: boolean = false;
  runForm: UntypedFormGroup;

  constructor(private service: RunsService) {
    this.directions = [];
    this.directions.push({
      origin: {lat: 0, lng: 0},
      destination: {lat: 0, lng: 0},
      title: '',
    });
  }

  ngOnInit() {
    this.runForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      note: new UntypedFormControl('', [Validators.required]),
      waypoints: new UntypedFormControl('', [Validators.required]),
      start: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
      startPoint: new UntypedFormControl(''),
      end: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
      endPoint: new UntypedFormControl(''),
      avoidhighways: new UntypedFormControl('', [Validators.required]),
      avoidtolls: new UntypedFormControl('', [Validators.required]),
      avoidferries: new UntypedFormControl('', [Validators.required])
    });

    this.showForm = true;
    this.usersList = [];
    this.runDetails = null;
    if (this.importExportOnly && this.importedRunData) {
      this.showUsersTable = false;
      //
      this.runDetails = this.importedRunData;
      this.fillForm();
      this.getDirection();
    } else {
      this.getRunUsersSubscription = this.service.getRunUsersList(this.runId).subscribe(data => {
        this.usersList = data.result.data;
        this.usersList.forEach(x => {
          x._fullName = x.firstName + ' ' + x.lastName;
          x._participated = x.userStartDate ? 'Participated' : 'Has not participated';
        });
        this.showUsersTable = this.usersList.length > 0;
        //
        this.getRunSubscription = this.service.getRun(this.runId).subscribe(response => {
          this.runDetails = response.result.data[0];
          this.fillForm();
          this.getDirection();
        });
      });
    }
  }

  fillForm() {
    this.runForm.controls.name.setValue(this.runDetails.name);
    this.runForm.controls.note.setValue(this.runDetails.note);
    this.runForm.controls.start.setValue(this.runDetails.startingPointName);
    this.runForm.controls.end.setValue(this.runDetails.endingPointName);
    this.runForm.controls.avoidhighways.setValue(this.runDetails.avoidHighways === 1);
    this.runForm.controls.avoidtolls.setValue(this.runDetails.avoidTolls === 1);
    this.runForm.controls.avoidferries.setValue(this.runDetails.avoidFerries === 1);
  }

  getDirection() {
    this.start = {lat: this.runDetails.startingPointLatitude, lng: this.runDetails.startingPointLongitude};
    this.end = {lat: this.runDetails.endingPointLatitude, lng: this.runDetails.endingPointLongitude};

    this.runDetails.waypoints.forEach(data => {
      this.waypoints.push({
        location: {
          lat: data.lat,
          lng: data.lon,
          title: data.title,
          id: Guid.newGuid(),
          country: data.country,
          state: data.state
        }
      });
    });
    this.mapLoaded = true;
  }

  setAddress(event) {
    const lat = event.geometry.location.lat();
    const lng = event.geometry.location.lng();
    const title = event.formatted_address;

    event.address_components.reverse();
    const countryCmp = event.address_components.find(ac => ac.types[0] === 'country');
    const country = countryCmp ? countryCmp.short_name : null;
    let state = null;
    if (country === 'US') {
      const stateCmp = event.address_components.find(ac => ac.types[0] === 'administrative_area_level_1');
      state = stateCmp ? stateCmp.short_name : null;
    }

    this.waypoints.push({
      location: {lat, lng, title, country, id: Guid.newGuid(), state}
    });
    this.addwaypointVisible = false;
    this.refreshRoutes();
  }

  removeWaypoint(lat, lng) {
    this.waypoints = this.waypoints.filter(w => {
      return !(w.location.lat === lat && w.location.lng === lng);
    });
    this.child.updateWaypoints(this.waypoints);
    this.refreshRoutes();
  }

  eventOptions: any = {
    onUpdate: () => {
      this.child.updateWaypoints(this.waypoints);
      this.refreshRoutes();
    }
  };

  refreshRoutes() {
    this.child.refreshMap();
  }

  confirmUpdate() {
    Swal.fire({
      title: 'Do you really want to Update Run?',
      text: '',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.update();
      }
    });
  }

  update() {
    const updateModel = new SingleRunUpdateModel();
    updateModel.alphaUserId = this.runDetails.alphaUserId ? this.runDetails.alphaUserId.toString() : null;
    updateModel.id = this.runDetails.id.toString();
    updateModel.startDate = this.runDetails.startDate;
    updateModel.endDate = this.runDetails.endDate;

    updateModel.endingPointLatitude = this.runDetails.endingPointLatitude;
    updateModel.endingPointLongitude = this.runDetails.endingPointLongitude;
    updateModel.startingPointLatitude = this.runDetails.startingPointLatitude;
    updateModel.startingPointLongitude = this.runDetails.startingPointLongitude;
    updateModel.isSolo = this.runDetails.isSolo;

    updateModel.name = this.runForm.controls.name.value;
    updateModel.note = this.runForm.controls.note.value;
    updateModel.avoidFerries = this.runForm.controls.avoidferries.value ? 1 : 0;
    updateModel.avoidHighways = this.runForm.controls.avoidhighways.value ? 1 : 0;
    updateModel.avoidTolls = this.runForm.controls.avoidtolls.value ? 1 : 0;

    let counter = 0;
    updateModel.waypoints = [];
    this.waypoints.forEach(element => {
      counter++;
      element.ord = counter;
      element.ordLetter = counter;
      element.org = this.runDetails.organization;
      element.runId = this.runDetails.id;
      element.title = element.location.title;
      element.lat = element.location.lat;
      element.lon = element.location.lng;
      element.country = element.location.country;
      element.reached = 0;
      element.state = element.location.state;
      updateModel.waypoints.push(element);
    });

    if (this.importExportOnly) {
      this.exportData.emit({
        ...updateModel,
        distance: this.runDetails.distance,
        startingPointName: this.runDetails.startingPointName,
        endingPointName: this.runDetails.endingPointName,
        startCountry: this.runDetails.startCountry,
        startState: this.runDetails.startState,
        endCountry: this.runDetails.endCountry,
        endState: this.runDetails.endState
      });
    } else {
      this.updateRunUsersSubscription = this.service.update(updateModel).subscribe(data => {
        if (data.success) {
          this.updateSuccess = 1;
        }
      });
    }
  }

  changeHighways() {
    this.runDetails.avoidHighways = this.runForm.controls.avoidhighways.value;
    this.refreshRoutes();
  }

  changeTolls() {
    this.runDetails.avoidTolls = this.runForm.controls.avoidtolls.value;
    this.refreshRoutes();
  }

  changeFerries() {
    this.runDetails.avoidFerries = this.runForm.controls.avoidferries.value;
    this.refreshRoutes();
  }

  onReceiveMapResponse(event) {
    const route = event.routes[0];
    const legs = route.legs;
    //
    const start = {
      lat: legs[0].start_location.lat(),
      lon: legs[0].start_location.lng()
    };
    const end = {
      lat: legs[legs.length - 1].end_location.lat(),
      lon: legs[legs.length - 1].end_location.lng()
    };
    //
    const newWaypoints = [];
    legs.forEach((leg, i) => {
      if (i > 0 && i < legs.length) {
        newWaypoints.push({
          location: {
            lat: leg.start_location.lat(),
            lng: leg.start_location.lng(),
            title: leg.start_address,
            id: Guid.newGuid()
          }
        });
      }
    });
    this.waypoints = newWaypoints;
    //
    this.runDetails.startingPointLatitude = start.lat;
    this.runDetails.startingPointLongitude = start.lon;
    this.runDetails.endingPointLatitude = end.lat;
    this.runDetails.endingPointLongitude = end.lon;
    this.start.lat = start.lat;
    this.start.lng = start.lon;
    this.end.lat = end.lat;
    this.end.lng = end.lon;
    this.runDetails.startingPointName = legs[0].start_address;
    this.runDetails.endingPointName = legs[legs.length - 1].end_address;
    let totalDistance = 0;
    legs.forEach(leg => {
      totalDistance += leg.distance.value;
    });
    this.runDetails.distance = totalDistance * 0.000621371192; // miles
  }

  onStartPointChange(event) {
    const lat = event.geometry.location.lat();
    const lng = event.geometry.location.lng();

    const countryCmp = event.address_components.find(ac => ac.types[0] === 'country');
    const country = countryCmp ? countryCmp.short_name : null;
    let state = null;
    if (country === 'US') {
      const stateCmp = event.address_components.find(ac => ac.types[0] === 'administrative_area_level_1');
      state = stateCmp ? stateCmp.short_name : null;
    }

    this.runDetails.startCountry = country;
    this.runDetails.startState = state;
    this.runDetails.startingPointLatitude = lat;
    this.runDetails.startingPointLongitude = lng;
    this.start.lat = lat;
    this.start.lng = lng;
    this.refreshRoutes();
  }

  onEndPointChange(event) {
    const lat = event.geometry.location.lat();
    const lng = event.geometry.location.lng();

    const countryCmp = event.address_components.find(ac => ac.types[0] === 'country');
    const country = countryCmp ? countryCmp.short_name : null;
    let state = null;
    if (country === 'US') {
      const stateCmp = event.address_components.find(ac => ac.types[0] === 'administrative_area_level_1');
      state = stateCmp ? stateCmp.short_name : null;
    }

    this.runDetails.endCountry = country;
    this.runDetails.endState = state;
    this.runDetails.endingPointLatitude = lat;
    this.runDetails.endingPointLongitude = lng;
    this.end.lat = lat;
    this.end.lng = lng;
    this.refreshRoutes();
  }

  ngOnDestroy() {
    if (this.getRunSubscription) {
      this.getRunSubscription.unsubscribe();
    }
    if (this.getRunUsersSubscription) {
      this.getRunUsersSubscription.unsubscribe();
    }
    if (this.updateRunUsersSubscription) {
      this.updateRunUsersSubscription.unsubscribe();
    }
  }
}
