import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input, NgZone } from '@angular/core';

declare let google: any;

@Component({
  selector: 'app-googleautocomplete',
  templateUrl: './googleautocomplete.component.html',
  styleUrls: ['./googleautocomplete.component.css']
})
export class GoogleautocompleteComponent implements OnInit, AfterViewInit {

  @Input() addressType: string;
  @Input() autoCompleteInput: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addressText') addressText: any;

  constructor(private ngZone: NgZone) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autoComplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        types: [this.addressType]
      });
    google.maps.event.addListener(autoComplete, 'place_changed', () => {
      this.ngZone.run(() => {
        const place = autoComplete.getPlace();
        this.invokeEvent(place);
      });
    });
  }

  invokeEvent(place) {
    this.setAddress.emit(place);
  }
}
