import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FeedbackService } from './service/feedback.service';
import { FeedbackResponseModel } from './model/feedback.responsemodel';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  providers: [DatePipe]
})
export class FeedbackComponent implements OnInit, OnDestroy {

  feedbackForm: UntypedFormGroup;
  statusList: { id: number, name: string, checked: boolean }[];
  statusListForTableSelect: { value: number, label: string }[];
  osList: { id: number, name: string }[];
  featureList: { id: number, name: string }[];

  listLoaded: boolean = false;
  searchWasMade = false;
  feedbackList = new Array<FeedbackResponseModel>();

  feedbackListSubscription = new Subscription;
  noteSubscription = new Subscription;
  timeout: any;

  totalCount = 0;
  pageNumber = 1;
  pageSize = 10;
  totalPages = 0;
  sortColumn =  {post: "feedbackTime", table: "_feedbackTime"};
  sortDirection: boolean = true;

  constructor(private service: FeedbackService, private router: Router, private datePipe: DatePipe) { }

  ngOnInit() {
    this.feedbackForm = new UntypedFormGroup({
      datefrom: new UntypedFormControl('', [Validators.required]),
      dateto: new UntypedFormControl('', [Validators.required]),
      status: new UntypedFormControl('', [Validators.required]),
      search: new UntypedFormControl('', [Validators.required]),
      os: new UntypedFormControl('', [Validators.required]),
      feature: new UntypedFormControl('', [Validators.required]),
    });

    this.statusList = [
      { id: null, name: 'All', checked: false },
      { id: 1, name: 'Addressed', checked: false },
      { id: 2, name: 'Not Addressed', checked: false },
      { id: 3, name: 'N/A', checked: false },
      { id: 4, name: 'In Progress', checked: false },
    ];

    this.statusListForTableSelect = [
      { value: null, label: 'All' },
      { value: 1, label: 'Addressed' },
      { value: 2, label: 'Not Addressed' },
      { value: 3, label: 'N/A' },
      { value: 4, label: 'In Progress' },
    ];

    this.osList = [
      { id: null, name: 'All' },
      { id: 1, name: 'Android' },
      { id: 2, name: 'IOS' },
      { id: 3, name: 'Web' },
    ];

    this.featureList = [
      { id: null, name: 'All' },
      { id: -1, name: 'None' },//this id is hardcoded on the server-side
      { id: 1, name: 'Login' },
      { id: 2, name: 'Home' },
      { id: 3, name: 'Profile/Settings' },
      { id: 4, name: 'Wolfpacks' },
      { id: 5, name: 'Runs' },
      { id: 6, name: 'On the Run experience' },
      { id: 7, name: 'Other' },
      { id: 11, name: 'Close my account' },
    ];
    this.setForm();
  }

  setForm() {
    this.feedbackForm.get('status').setValue(2);
    this.feedbackForm.get('os').setValue(null);
    this.feedbackForm.get('feature').setValue(null);
  }

  onSearch() {
    this.pageNumber = 1;
    this.getFeedbackList();
  }

  getFeedbackList() {
    this.searchWasMade = true;
    this.listLoaded = false;
    // this.sortTable();
    this.feedbackListSubscription = this.service.getFeedbacks(
      this.feedbackForm.controls.datefrom.value,
      this.feedbackForm.controls.dateto.value,
      this.feedbackForm.controls.search.value,
      this.feedbackForm.controls.status.value,
      this.feedbackForm.controls.os.value,
      this.feedbackForm.controls.feature.value,
      this.pageSize.toString(),
      this.pageNumber,
      this.sortColumn.post,
      this.sortDirection
    ).subscribe(data => {
      this.feedbackList = data.result.data;
      this.totalCount = data.result.totalCount;
      this.totalPages = Math.ceil(this.totalCount / this.pageSize);

      this.feedbackList.map(x => {
        x._fullName = x.userFirstName + ' ' + x.userLastName,
          x._feedbackTime = this.datePipe.transform(x.feedbackTime, 'MM.dd.yyyy'),
          x._buttonDetails = { value: 'User Details', class: 'btn btn-success' },
          x._selectStatuses = { value: x.adminAddressed ? x.adminAddressed .toString() : '', class: '' }
      })
      this.feedbackList.forEach(element => {
        element.feedbackDevicePlatformName = this.osList.filter(x => x.id == element.feedbackDevicePlatformId)[0].name;

      });
      this.listLoaded = true;
    })
  }

  editFeedback(event) {
    if (this.timeout) { clearTimeout(this.timeout); }
    this.timeout = setTimeout(() => {
      let feedbackId = event.record.feedbackId;
      let noteText = event.event.target.value;
      let selectValue = event.record.adminAddressed;
      this.noteSubscription = this.service.editFeedback(feedbackId, selectValue, noteText).subscribe(data => {
        if (data.success) {
          let tempRow: FeedbackResponseModel = this.feedbackList.find(x => x.feedbackId == feedbackId);
          tempRow.adminNote = noteText;
        }
      });
    }, 500);
  }

  editFeedbackSelect(event) {
    if (this.timeout) { clearTimeout(this.timeout); }
    this.timeout = setTimeout(() => {
      let feedbackId = event.record.feedbackId;
      let selectValue = event.event.target.value;
      let noteText = event.record.adminNote;
      this.noteSubscription = this.service.editFeedback(feedbackId, selectValue, noteText).subscribe(data => {
        if (data.success) {
          let tempRow: FeedbackResponseModel = this.feedbackList.find(x => x.feedbackId == feedbackId);
          tempRow.adminAddressed = selectValue;
        }
      });
    }, 500);
  }

  detailsClicked(event) {
    let userId = event.record.userId;
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    window.open(baseUrl + "/userdetails/" + userId, "_blank");
  }

  onPageChange(pageNumber: number) {
    if (this.pageNumber === pageNumber) {
      return false;
    }
    this.pageNumber = pageNumber || 1;
    this.getFeedbackList();
    return false;
  }

  onPageSizeChange(event) {
    this.pageSize = event.value
    if (this.listLoaded) {
      this.pageNumber = 1;
      this.getFeedbackList();
    }
  }

  sortClick(event){
    this.sortDirection = event.sort.direction === true;
    switch (event.sort.column) {
      case '_fullName':
        this.sortColumn.post = 'userFirstName';
        this.sortColumn.table = '_fullName';
        break;
      case '_feedbackTime':
        this.sortColumn.post = 'feedbackTime';
        this.sortColumn.table = '_feedbackTime';
        break;
      case 'feedbackDevicePlatformName':
        this.sortColumn.post = 'feedbackDevicePlatformId';
        this.sortColumn.table = 'feedbackDevicePlatformName';
        break;
      case '_selectStatuses':
        this.sortColumn.post = 'adminAddressed';
        this.sortColumn.table = '_selectStatuses';
        break;
      default:
        this.sortColumn.post = event.sort.column;
        this.sortColumn.table = event.sort.column;
        break;
    }
    this.getFeedbackList();
  }

  ngOnDestroy() {
    this.feedbackListSubscription.unsubscribe();
    this.noteSubscription.unsubscribe();
  }
}
