import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-page-size',
  templateUrl: './page-size.component.html',
  styleUrls: ['./page-size.component.scss']
})
export class PageSizeComponent implements OnInit {

  @Input() value = 30;
  @Input() disabled = false;
  @Output() changeEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onChange(event) {
    this.changeEvent.emit({event, value: event.target.value});
  }

}
