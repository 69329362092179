import { Component, OnInit, OnDestroy } from '@angular/core';
import { WhatsnewService } from './service/whatsnew.service';
import { Subscription } from 'rxjs';
import { WhatsNewResponseModel } from './model/whatsnew.responsemodel';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { FileUploadService } from '../../services/file-upload.service';

let thisComponent: any;

import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
import resizeImage from '../../shared/functions/resize-image';
import {environment} from '../../../environments/environment';
import {NgxImageCompressService} from 'ngx-image-compress';
Quill.register('modules/imageResize', ImageResize);


@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.component.html',
  styleUrls: ['./whatsnew.component.css'],
  providers: [DatePipe]
})
export class WhatsnewComponent implements OnInit, OnDestroy {

  message: any;
  saveAnswer: boolean;
  saveError: string;
  messageSubscription = new Subscription;

  visibleOnWeb: string = ' ';
  visibleStatusList: { value: string, label: string, chekced: boolean }[];
  visibleStatusListForTable: { value: string, label: string, chekced: boolean }[];

  listLoaded: boolean = false;
  searchWasMade : boolean = false;
  newsList = new Array<WhatsNewResponseModel>();
  newsListSubscription = new Subscription;

  searchfield = "";
  totalCount = 0;
  pageNumber = 1;
  pageSize = 10;
  totalPages = 0;
  sortColumn = { post: "creationTime", table: "creationTime" };
  sortDirection: boolean = true;
  timeout: any;

  editmessageId?: number;
  editmessage: any;
  editmessageVisibleOnWeb: boolean;
  editmessageSubscription = new Subscription;

  modalConfig = {
    show: false,
    addOrEdit: true,
    wasValidated: false,
    title: 'Edit Form',
    isLoading: false,
    isEditLoading: false,
  };

  textChangeInterval: any;
  loadingData = {
    selectedChange: false
  };

  modules = {
    formula: true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
      ['link', 'image'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean']
    ],
    imageResize: true
  };

  quillEditorRef;
  quillEditorRefEdit;
  private removedImages = [];
  private editRemovedImages = [];

  constructor(private service: WhatsnewService,
              private datePipe: DatePipe,
              private fileUploadService: FileUploadService,
              private imageCompress: NgxImageCompressService) { }

  ngOnInit() {
    thisComponent = this;
    this.visibleStatusList = [
      { value: '', label: 'All', chekced: true },
      { value: 'true', label: 'Visible On Web', chekced: false },
      { value: 'false', label: 'Not Visible On Web', chekced: false },
    ];
    this.visibleStatusListForTable = [
      { value: 'true', label: 'Visible On Web', chekced: false },
      { value: 'false', label: 'Not Visible On Web', chekced: false },
    ];
  }

  changedContentEdit($event) {
    this.editmessage = $event.html;
  }

  changedContent($event) {
    this.message = $event.html;
  }

  public base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  public contentSelectionChange($event) {

  }

  imageHandler = (image, callback) => {
    const input = <HTMLInputElement> document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      if (/^image\//.test(file.type)) {
        // if (file.size <= this.maxUploadFileSize) {
        this.modalConfig.isLoading = true;
        this.modalConfig.isEditLoading = true;
        const reader  = new FileReader();
        reader.onload = () => {
          this.service.uploadImage(file)
                  .subscribe(t => {
                    this.modalConfig.isLoading = false;
                    this.modalConfig.isEditLoading = false;
                    if (t && t.result && t.result.data &&
                      t.result.data[0]) {
                      const range = this.quillEditorRef.getSelection();
                      const img = '<div><img src="' + t.result.data[0] + '" /></div>';
                      this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
                      const item: any = document.getElementById('fileInputField');
                      if (item) {
                        item.value = null;
                      }
                    }
                  }, () => {
                    this.modalConfig.isLoading = false;
                    this.modalConfig.isEditLoading = false;
                  });
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  imageHandlerEdit = (image, callback) => {
    const input = <HTMLInputElement> document.getElementById('fileInputFieldEdit');
    document.getElementById('fileInputFieldEdit').onchange = () => {
      let file: File;
      file = input.files[0];
      if (/^image\//.test(file.type)) {
        // if (file.size <= this.maxUploadFileSize) {
        this.modalConfig.isEditLoading = true;
        const reader  = new FileReader();
        reader.onload = () => {
          this.service.uploadImage(file)
            .subscribe(t => {
              this.modalConfig.isEditLoading = false;
              if (t && t.result && t.result.data &&
                t.result.data[0]) {
                const range = this.quillEditorRefEdit.getSelection();
                const img = '<div><img src="' + t.result.data[0] + '" /></div>';
                this.quillEditorRefEdit.clipboard.dangerouslyPasteHTML(range.index, img);
                const item: any = document.getElementById('fileInputFieldEdit');
                if (item) {
                  item.value = null;
                }
              }
            }, () => {
              this.modalConfig.isEditLoading = false;
            });
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
    this.quillEditorRef.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user') {
        const currrentContents = this.quillEditorRef.getContents();
        const diff = currrentContents.diff(oldDelta);
        try {
          this.removedImages = this.removedImages.concat(diff.ops.filter(u => u.insert && u.insert.image &&
            this.message.indexOf(u.insert.image) === -1).map(t => t.insert.image));
          this.removedImages = this.removedImages.filter(t => this.message.indexOf(t) === -1);
        } catch (error) {}
      }
    });
  }

  getEditorInstanceEdit(editorInstance: any) {
    this.quillEditorRefEdit = editorInstance;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandlerEdit);
    this.quillEditorRefEdit.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user') {
        const currrentContents = this.quillEditorRefEdit.getContents();
        const diff = currrentContents.diff(oldDelta);
        try {
          this.editRemovedImages = this.editRemovedImages.concat(diff.ops.filter(u => u.insert && u.insert.image &&
            this.editmessage.indexOf(u.insert.image) === -1).map(t => t.insert.image));
          this.editRemovedImages = this.editRemovedImages.filter(t => this.editmessage.indexOf(t) === -1);
        } catch (error) {}
      }
    });
  }


  save() {
    if (this.message) {
      Swal.fire({
        title: 'Are you sure?',
        html: this.message,
        width: 'auto',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Publish!'
      }).then((result) => {
        if (result.value) {
          this.messageSubscription = this.service.savetNews(
            this.message,
            "",
            false
          ).subscribe(data => {
            this.saveAnswer = data.success;
            if (this.saveAnswer)
              this.message = null;
              this.getNewsList();

            if (this.removedImages && this.removedImages.length > 0) {
              this.modalConfig.isLoading = true;
              this.service.removeUnused(this.removedImages).subscribe(t => {
                this.modalConfig.isLoading = false;
                this.removedImages = [];
              }, () => {
                this.modalConfig.isLoading = false;
              });
            }

          });
        }
      });
    }
  }

  preview() {
    if (this.message) {
      Swal.fire({
        title: 'Preview',
        html: this.message,
        width: 'auto'
      })
    }
  }

  previewForTabel(html) {
    if (html) {
      Swal.fire({
        title: 'Preview',
        html: html,
        width: 'auto'
      })
    }
  }

  removeSuccess() {
    this.saveAnswer = false;
  }

  getNewsList() {
    this.searchWasMade = true;
    this.listLoaded = false;
    this.newsListSubscription = this.service.getNews(
      this.searchfield,
      this.pageSize,
      this.pageNumber,
      this.sortColumn.post,
      this.sortDirection,
      this.visibleOnWeb
    )
      .subscribe(data => {
        this.newsList = data.result.data;
        this.totalCount = data.result.totalCount;
        this.totalPages = Math.ceil(this.totalCount / this.pageSize);
        this.newsList.map(x => {
          x._creationTime = this.datePipe.transform(x.creationTime, 'MM.dd.yyyy hh:MM:ss'),
            x._adminName = x.creatorFirstName + ' ' + x.creatorLastName,
            x._buttonDetails = { value: 'View', class: 'btn btn-success btn-lg btn-block' },
            x._buttonEdit = { value: 'Edit', class: 'btn btn-primary btn-lg btn-block' },
            x._selectStatuses = { value: x.visibleOnWeb ? 'true' : 'false', class: '' }
        })
        this.listLoaded = true;
      })
  }

  changeVisibilityStatus() {
    this.getNewsList();
  }

  onPageSizeChange(event) {
    this.pageSize = event.value
    if (this.listLoaded) {
      this.pageNumber = 1;
      this.getNewsList();
    }
  }

  onPageChange(pageNumber: number) {
    if (this.pageNumber === pageNumber) {
      return false;
    }
    this.pageNumber = pageNumber || 1;
    this.getNewsList();
    return false;
  }

  sortClick(event) {
    this.sortDirection = event.sort.direction === true;
    switch (event.sort.column) {
      case '_adminName':
        this.sortColumn.post = 'creatorFirstName';
        this.sortColumn.table = '_adminName';
        break;
      case '_creationTime':
        this.sortColumn.post = 'creationTime';
        this.sortColumn.table = '_creationTime';
        break;
      case '_selectStatuses':
        this.sortColumn.post = 'visibleOnWeb';
        this.sortColumn.table = '_selectStatuses';
        break;
      default:
        this.sortColumn.post = event.sort.column;
        this.sortColumn.table = event.sort.column;
        break;
    }
    this.getNewsList();
  }

  btnClicked(event) {
    let html = event.record.message;
    switch (event.column.name) {
      case "_buttonDetails":
        this.details(html);
        break;
      case "_buttonEdit":
        this.editmessageId = event.record.id;
        this.editmessage = event.record.message;
        this.editmessageVisibleOnWeb = event.record.visibleOnWeb;
        this.edit();
        break;
      default:
        break;
    }
  }

  details(html) {
    this.previewForTabel(html);
  }

  edit() {
    this.modalConfig.show = true;
    this.editRemovedImages = [];
  }

  editSave() {
    if (!this.loadingData.selectedChange) {
      this.modalConfig.isEditLoading = true;
      this.editmessageSubscription = this.service.editNews(this.editmessageId, this.editmessage, "", this.editmessageVisibleOnWeb)
        .subscribe(data => {
        if (data.success === true) {
          this.modalConfig.isEditLoading = false;
          this.onCloseModal();
          this.getNewsList();
          this.editmessageId = null;
          this.editmessage = '';

          if (this.editRemovedImages && this.editRemovedImages.length > 0) {
            this.modalConfig.isEditLoading = true;
            this.service.removeUnused(this.editRemovedImages).subscribe(t => {
              this.modalConfig.isEditLoading = false;
              this.editRemovedImages = [];
            }, () => {
              this.modalConfig.isEditLoading = false;
            });
          }

          Swal.fire({
            title: 'Preview',
            text: "Message Edited Successfully",
            confirmButtonColor: 'green',
          });
        }
      });
    }
  }

  onCloseModal() {
    this.modalConfig.show = false;
    clearInterval(this.textChangeInterval);
  }

  tableStatusChanged(event){
    this.editmessageId = event.record.id;
    this.editmessage = event.record.message;
    this.editmessageVisibleOnWeb = !event.record.visibleOnWeb;
    // this.editSave();
    this.loadingData.selectedChange = true;
    this.editmessageSubscription = this.service.editNews(this.editmessageId, this.editmessage, "", this.editmessageVisibleOnWeb).subscribe(data => {
      this.editmessageId = null;
      this.editmessage = '';
      event.record.visibleOnWeb = !event.record.visibleOnWeb;
      this.editmessageVisibleOnWeb = null;
      if (data.success === false) {
        Swal.fire({
          title: 'Error',
          text: "Message Edit was Unsuccessfully",
          confirmButtonColor: 'red',
        });
      }
      this.loadingData.selectedChange = false;
    }, () => {this.loadingData.selectedChange = false;});
  }

  onMessageImageUpload(blobInfo, success, failure) {
    thisComponent.fileUploadService.imageUpload(blobInfo.blob(), success, failure);
  }

  initInstanceCallback() {
    clearInterval(this.textChangeInterval);
    this.textChangeInterval = setInterval(() => {
      const toxDropzone = document.getElementsByClassName('tox-dropzone');
      if (toxDropzone && toxDropzone[0] && toxDropzone[0].firstElementChild && toxDropzone[0].firstElementChild.textContent) {
        toxDropzone[0].firstElementChild.textContent = 'Image size must be less than 1 MB';
      }
    }, 10);
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.newsListSubscription.unsubscribe();
    this.editmessageSubscription.unsubscribe();
    clearInterval(this.textChangeInterval);
  }

}
