import { EUserActions } from '../actions/user.actions';
import { UserActions } from '../actions/user.actions';
import { UserState } from '../states/user/user.state';
import { IUser } from 'src/app/models/user/user';

export function userReducers(state: IUser = UserState, action: UserActions): IUser {
    switch (action.type) {
        case EUserActions.LoadUserToken: {
            return {
                ...state,
                authenticated: false,
                authenticating: true,
                error: null,
            };
        }
        case EUserActions.SetUserData: {
            return {
                ...state,
                data: action.payload.data,
                token: action.payload.token,
                authenticated: true,
                authenticating: false,
                error: action.payload.error
            };
        }
        case EUserActions.RemoveUserDataState: {
            return {
                ...state,
                data: action.payload.data,
                token: action.payload.token,
                authenticated: action.payload.authenticated,
                authenticating: action.payload.authenticating,
                error: action.payload.error
            };
        }
        case EUserActions.LoadUserTokenError: {
            return {
                ...state,
                authenticated: false,
                authenticating: false,
                error: action.payload.error
            };
        }
        default:
            return state;
    }
}
