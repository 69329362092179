import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemUserlistComponent } from './systemuserlist/userlist.component';
import { SystemusersComponent } from './systemusers/systemusers.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SystemusersdetailsComponent } from './systemusersdetails/systemusersdetails.component';
import { UsersComponent } from './users.component';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { SharedModule } from 'src/app/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    MatDialogModule,
    MatProgressBarModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatIconModule
  ],
  declarations: [
    UsersComponent,
    SystemusersComponent,
    SystemUserlistComponent,
    SystemusersComponent,
    SystemusersdetailsComponent,
    SubscribersComponent,
  ],
  exports: [SystemUserlistComponent]
})
export class UsersModule {
}
