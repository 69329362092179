import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResult } from '../../models/shared/iresult';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private env: any = environment;
  private _url: string;

  protected defaultOptions: any = {
    headers: null,
    status: -1,
    statusText: null,
    type: null,
    url: null,
  };

  constructor(private http: HttpClient) {
    this._url = this.env.apiUrl;
  }

  errorHandler(error: HttpErrorResponse): Observable<IResult> {
    if (error.status == 401) {
      window.location.href = './#/unauthorized';
    }
    return throwError(() => error.message || 'Server error');
  }

  /**
    * Make request with http GET method.
    *
    * @param url      An url for http request.
    * @param options  A request options.
    * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
    */
  public get(url: string, options?: any): Observable<any> {
    return this.http.get(`${this._url}${url}`, options);
  }

  /**
   * Make request with http GET method. Support root service
   *
   * @param url      An url for http request.
   * @param options  A request options.
   * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
   */
  public getFromService(url: string, service: string, options?: any): Observable<any> {
    return this.http.get(`${this.env.url}${service}/${url}`, options);
  }

  /**
       * Performs a request with `post` http method.
       * @param url      An url which is used in a http request.
       * @param data     Data (in JavaScript format) which is used in a http request.
       * @param options  A request options arguments.
       * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
       */
  public post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    return this.http.post(`${this._url}${url}`, body, options);
  }

  /**
   * Performs a request with `post` http method.
   * @param url      An url which is used in a http request.
   * @param data     Data (in JavaScript format) which is used in a http request.
   * @param options  A request options arguments.
   * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
   */
  public postFromService(url: string, service: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    return this.http.post(`${this.env.url}${service}/${url}`, body, options);
  }

  /**
     * Performs a request with `put` http method.
     *
     * @param url      An url which is used in a http request.
     * @param data     Data (in JavaScript format) which is used in a http request.
     * @param options  A request options arguments.
     * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
     */
  public put(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    return this.http.put(`${this._url}${url}`, body, options);
  }

  /**
   * Performs a request with `put` http method.
   *
   * @param url      An url which is used in a http request.
   * @param data     Data (in JavaScript format) which is used in a http request.
   * @param options  A request options arguments.
   * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
   */
  public putFromService(url: string, service: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    return this.http.put(`${this.env.url}${service}/${url}`, body, options);
  }

  /**
     * Performs a request with `post` http method.
     *
     * @param url      An url which is used in a http request.
     * @param data     Data (in JavaScript format) which is used in a http request.
     * @param options  A request options arguments.
     * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
     */
  public delete(url: string, data: Object): Observable<any> {
    return this.http.delete(`${this._url}${url}`, data);
  }

  /**
   * Performs a request with `post` http method.
   *
   * @param url      An url which is used in a http request.
   * @param data     Data (in JavaScript format) which is used in a http request.
   * @param options  A request options arguments.
   * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
   */
  public deleteFromService(url: string, service: string, data?): Observable<any> {
    return this.http.delete(`${this.env.url}${service}/${url}`);
  }

}
