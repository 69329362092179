/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-googlemap',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.css']
})
export class GooglemapComponent implements OnInit {
  @Input() avoidFerries: number = 0;
  @Input() avoidTolls: number = 0;
  @Input() avoidHighways: number = 0;
  @Input() start: any;
  @Input() end: any;
  @Input() startPointName: string;
  @Input() endPointName: string;
  @Input() waypoints: any[] = [];
  @Output() onMapResponse = new EventEmitter<any>();

  map: google.maps.Map;
  directionsService: any;
  directionsDisplay: any;


  constructor(@Inject(DOCUMENT) document) {
  }

  ngOnInit() {
    this.initMap();
  }

  initMap() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: 0, lng: 0},
      //  zoom: 33.5
    });

    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      preserveViewport: true,
      draggable: true,
      map: this.map
    });
    this.directionsDisplay.addListener('directions_changed', () => {
      let changeDetected = false;
      const newWaypoints = [];
      const response = this.directionsDisplay.getDirections();
      //
      if (response) {
        const route = response.routes[0];
        const legs = route.legs;
        legs.forEach((leg, i) => {
          if (i > 0 && i < legs.length) {
            newWaypoints.push({
              location: {
                lat: leg.start_location.lat(),
                lng: leg.start_location.lng()
              }
            });
          }
        });
        if (newWaypoints.length !== this.waypoints.length) {
          changeDetected = true;
        } else {
          for (let i = 0; i < newWaypoints.length; i++) {
            if (this.waypoints[i].location.lat !== newWaypoints[i].location.lat ||
              this.waypoints[i].location.lng !== newWaypoints[i].location.lng) {
              changeDetected = true;
              break;
            }
          }
        }
        //
        const newStartCoords = {
          lat: legs[0].start_location.lat(),
          lon: legs[0].start_location.lng()
        };
        const newEndCoords = {
          lat: legs[legs.length - 1].end_location.lat(),
          lon: legs[legs.length - 1].end_location.lng()
        };
        //
        if (newStartCoords.lat - this.start.lat !== 0 || newStartCoords.lon - this.start.lng !== 0 ||
          newEndCoords.lat - this.end.lat !== 0 || newEndCoords.lon - this.end.lng !== 0) {
          changeDetected = true;
          this.start.lat = newStartCoords.lat;
          this.start.lng = newStartCoords.lon;
          this.end.lat = newEndCoords.lat;
          this.end.lng = newEndCoords.lon;
        }
        //
        if (changeDetected) {
          this.waypoints = newWaypoints;
          this.refreshMap();
        }
      }
    });
    //
    this.refreshMap();
  }

  refreshMap() {
    this.displayRoute(this.start, this.end, this.directionsService, this.directionsDisplay);
  }

  displayRoute(origin, destination, service, display) {
    service.route({
      origin,
      destination,
      waypoints: this.waypoints,
      travelMode: 'DRIVING',
      optimizeWaypoints: false,
      avoidFerries: this.avoidFerries === 1,
      avoidHighways: this.avoidHighways === 1,
      avoidTolls: this.avoidTolls === 1
    }, (response, status) => {
      if (status === 'OK') {
        this.onMapResponse.emit(response);
        display.setDirections(response);
      }
    });
    this.centerMap();
  }

  centerMap() {
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(this.start);
    bounds.extend(this.end);
    this.waypoints.forEach(element => {
      bounds.extend(element.location);
    });
    this.map.fitBounds(bounds, 100);
  }

  updateWaypoints(waypoints) {
    this.waypoints = waypoints;
  }
}
