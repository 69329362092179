<div class="card org-details-card">
    <div class="card-header">{{title}}</div>
    <div class="card-body">
        <form *ngIf="!loading">
            <div class="form-group">
                <ng-content></ng-content>
            </div>
        </form>
        <div *ngIf="loading" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
