import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RunsService } from './service/runs.service';
import { CountriesResponseModel } from 'src/app/models/shared/countries/countries.responsemodel';
import { Subscription } from 'rxjs';
import { CountriesRequestModel } from 'src/app/models/shared/countries/countries.requestmodel';
import { RunsRequestModel } from './model/runs.requestmodel';
import { DatePipe } from '@angular/common';
import { RunsResponseModel } from './model/runs.responsemodel';
import { SingleRunResponseModel } from './model/singlerun.responsemodel';
import { RunUsersResponseModel } from './model/runusers.responsemodel';
import { SharedService } from 'src/app/services/api/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-runs',
  templateUrl: './runs.component.html',
  styleUrls: ['./runs.component.css'],
  providers: [DatePipe]
})
export class RunsComponent implements OnInit, OnDestroy {

  runsForm: UntypedFormGroup;
  countriesListLoaded: boolean = true;

  // startCountry
  countryList: CountriesResponseModel[];
  countryrequest = new CountriesRequestModel;
  countryListSubscription = new Subscription;
  stateList: CountriesResponseModel[];
  statesActive: boolean = false;
  stateListSubscription = new Subscription;

  // EndCountry
  endcountryList: CountriesResponseModel[];
  endcountryrequest = new CountriesRequestModel;
  endcountryListSubscription = new Subscription;
  endstateList: CountriesResponseModel[];
  endstatesActive: boolean = false;
  endstateListSubscription = new Subscription;

  statusList: { id: number, name: string }[];

  publicRuns: { id: number, name: string }[] = [
    {
      id: null,
      name: 'All'
    },
    {
      id: 0,
      name: 'Private'
    },
    {
      id: 1,
      name: 'Public'
    }
  ];
  protectedRuns: { id: number, name: string }[] = [
    {
      id: null,
      name: 'All'
    },
    {
      id: 0,
      name: 'No'
    },
    {
      id: 1,
      name: 'Yes'
    }
  ];

  runsListRequestModel = new RunsRequestModel;
  runsListSubscription = new Subscription();
  searchWasMade = false;
  listLoaded = false;
  runsList: RunsResponseModel[] = [];
  sortColumn = { post: 'creationDate', table: '_creationDate' };
  sortDirection = true;
  // pagination
  pageNumber = 1;
  pageSize = 10;
  totalCount = 0;
  totalPages = 0;

  // table buttons subscriptions
  curationSubscription = new Subscription;
  deleteSubscription = new Subscription;


  usersList: RunUsersResponseModel[] = [];
  runDetailes: SingleRunResponseModel;
  modalConfig = {
    show: false,
    addOrEdit: true,
    wasValidated: false,
    title: 'Details',
    runId: 0,
  };

  constructor(private service: RunsService, private sharedService: SharedService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.runsForm = new UntypedFormGroup({
      startcountry: new UntypedFormControl('', [Validators.required]),
      endcountry: new UntypedFormControl('', [Validators.required]),
      startstate: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
      endstate: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
      status: new UntypedFormControl('', [Validators.required]),
      search: new UntypedFormControl('', [Validators.required]),

      publicRuns: new UntypedFormControl('', [Validators.required]),
      protectedRuns: new UntypedFormControl('', [Validators.required]),

    });

    this.statusList = [
      { id: null, name: 'All' },
      { id: 1, name: 'Run started (Active)' },
      { id: 2, name: 'Deleted' },
      { id: 3, name: 'Suspended' },
      { id: 4, name: 'Not yet started' },
      { id: 5, name: 'Finished' },
    ];

    this.countryListSubscription = this.sharedService.getRunCountries(this.countryrequest).subscribe(data => {
      this.countryList = data.result.data;
      this.countryList.unshift(
        {
          code: null,
          name: 'All'
        }
      );
    });

    this.endcountryListSubscription = this.sharedService.getRunCountries(this.endcountryrequest).subscribe(data => {
      this.endcountryList = data.result.data;
      this.endcountryList.unshift(
        {
          code: null,
          name: 'All'
        }
      );
    });
  }

  publicRunsChanges() {
    if (+this.runsForm.controls.publicRuns.value === 1 && +this.runsForm.controls.protectedRuns.value === 1) {
      this.runsForm.controls.protectedRuns.setValue('');
    }
  }

  protectedRunsChanges() {
    if (+this.runsForm.controls.protectedRuns.value !== 0 &&
      this.runsForm.controls.protectedRuns.value !== ''
      && +this.runsForm.controls.publicRuns.value === 1) {
      this.runsForm.controls.publicRuns.setValue('');
    }
  }

  startCountriesChange() {
    if (this.runsForm.controls.startcountry.value === 'US') {
      this.countriesListLoaded = false;

      this.stateListSubscription = this.sharedService.getRunStates(this.countryrequest).subscribe(data => {
        this.stateList = data.result.data;
        this.stateList.unshift(
          {
            code: '',
            name: 'All',

          }
        );
        this.runsForm.get('startstate').enable();
        this.countriesListLoaded = true;
      });
    } else {
      this.runsForm.get('startstate').setValue('');
      this.runsForm.get('startstate').disable();
    }
  }

  endCountriesChange() {
    if (this.runsForm.controls.endcountry.value === 'US') {
      this.countriesListLoaded = false;

      this.endstateListSubscription = this.sharedService.getRunStates(this.countryrequest).subscribe(data => {
        this.endstateList = data.result.data;
        this.endstateList.unshift(
          {
            code: '',
            name: 'All',

          }
        );
        this.runsForm.get('endstate').enable();
        this.countriesListLoaded = true;
      });
    } else {
      this.runsForm.get('endstate').setValue('');
      this.runsForm.get('endstate').disable();
    }
  }

  onSearch() {
    this.pageNumber = 1;
    this.getRunsList();
  }

  getRunsList() {
    this.searchWasMade = true;
    this.listLoaded = false;
    this.runsListRequestModel.model.sort[0].field = this.sortColumn.post;
    this.runsListRequestModel.model.pageSize = this.pageSize;
    this.runsListRequestModel.model.page = this.pageNumber;
    this.runsListRequestModel.model.sort[0].desc = this.sortDirection;
    this.runsListRequestModel.model.data.filter.status = this.runsForm.controls.status.value;
    this.runsListRequestModel.model.data.filter.searchField = this.runsForm.controls.search.value;
    this.runsListRequestModel.model.data.filter.startCountry = this.runsForm.controls.startcountry.value || null;
    this.runsListRequestModel.model.data.filter.endCountry = this.runsForm.controls.endcountry.value || null;
    this.runsListRequestModel.model.data.filter.startState = this.runsForm.controls.startstate.value === '' ? null : this.runsForm.controls.startstate.value;
    this.runsListRequestModel.model.data.filter.endState = this.runsForm.controls.endstate.value === '' ? null : this.runsForm.controls.endstate.value;
    this.runsListRequestModel.model.data.filter.isPublic = this.runsForm.controls.publicRuns.value === '' ? null : this.runsForm.controls.publicRuns.value;
    this.runsListRequestModel.model.data.filter.isProtected = this.runsForm.controls.protectedRuns.value === '' ? null : this.runsForm.controls.protectedRuns.value;

    this.runsListSubscription = this.service.getRuns(this.runsListRequestModel).subscribe(data => {
      this.runsList = data.result.data;

      this.runsList.map(x => {
        x._creationDate = this.datePipe.transform(x.creationDate, 'MM.dd.yyyy');
        x._startDate = this.datePipe.transform(x.startDate, 'MM.dd.yyyy');
        x._endDate = this.datePipe.transform(x.endDate, 'MM.dd.yyyy');
        x._creator = {value: x.creatorFirstName + ' ' + x.creatorLastName, href: `/userdetails/${x.creatorId}`, target: '_blank'};

        x._runStatus = x.runStatus ? this.statusList.filter(data => data.id == x.runStatus)[0].name : '';
        x._isPublic = x.isPublic == 1 ? 'public' : 'private';
        x._isProtected = x.isProtected == 1 ? 'yes' : 'no';
        x._buttonDetails = { value: 'View / Edit', class: 'btn btn-success btn-block' };
        x._buttonDelete = { value: 'Delete', class: 'btn btn-danger btn-block' };
        x._buttonCuration = { value: 'Curate Again', class: 'btn btn-warning btn-block' };
        if (!x.curated) {
          x._buttonCuration = { value: 'Curation', class: 'btn btn-warning btn-block' };
        }
      });

      this.totalCount = data.result.totalCount;
      this.totalPages = Math.ceil(this.totalCount / this.pageSize);
      this.listLoaded = true;
    })
  }

  onPageChange(pageNumber: number) {
    if (this.pageNumber === pageNumber) {
      return false;
    }
    this.pageNumber = pageNumber || 1;
    this.getRunsList();
    return false;
  }

  onPageSizeChange(event) {
    this.pageSize = event.value;
    if (this.listLoaded) {
      this.pageNumber = 1;
      this.getRunsList();
    }
  }

  sortClick(event) {
    this.sortDirection = event.sort.direction === true;
    switch (event.sort.column) {
      case '_creationDate':
        this.sortColumn.post = 'creationDate';
        this.sortColumn.table = '_creationDate';
        break;
      case '_startDate':
        this.sortColumn.post = 'startDate';
        this.sortColumn.table = '_startDate';
        break;
      case '_endDate':
        this.sortColumn.post = 'endDate';
        this.sortColumn.table = '_endDate';
        break;
      case '_runStatus':
        this.sortColumn.post = 'runStatus';
        this.sortColumn.table = '_runStatus';
        break;
      case '_isPublic':
        this.sortColumn.post = 'isPublic';
        this.sortColumn.table = '_isPublic';
        break;
      case '_isProtected':
        this.sortColumn.post = 'isProtected';
        this.sortColumn.table = '_isProtected';
        break;
      case '_isProtected':
        this.sortColumn.post = 'isProtected';
        this.sortColumn.table = '_isProtected';
        break;
      case '_creator':
        this.sortColumn.post = 'creatorFirstName';
        this.sortColumn.table = '_creator';
        break;
      default:
        this.sortColumn.post = event.sort.column;
        this.sortColumn.table = event.sort.column;
        break;
    }
    this.getRunsList();
  }

  btnTableClicked(event) {
    switch (event.column.name) {
      case '_buttonEdit':

        break;
      case '_buttonDelete':
        this.delete(event.record.id);
        break;
      case '_buttonDetails':
        this.details(event.record.id);
        break;
      case '_buttonCuration':
        this.curate(event.record.id);
        break;
      default:
        break;
    }
  }

  curate(id) {
    this.listLoaded = false;
    this.curationSubscription = this.service.curate(id).subscribe(data => {
      if (data && data.result) {
        Swal.fire(
          'Added to Curation List',
          '',
          'success'
        );
        this.getRunsList();
      }
    })
  }

  delete(id) {
    Swal.fire({
      title: 'Do you really want to delete Run?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.listLoaded = false;
        this.deleteSubscription = this.service.delete(id).subscribe(data => {
          if (data && data.result) {
            Swal.fire(
              'Deleted',
              '',
              'warning'
            );
            this.getRunsList();
          }
        })
      }
    });
  }

  details(id) {
    this.modalConfig.show = true;
    this.modalConfig.runId = id;
  }

  onCloseModal() {
    this.modalConfig.show = false;
  }

  ngOnDestroy() {
    this.countryListSubscription.unsubscribe();
    this.endcountryListSubscription.unsubscribe();
    this.runsListSubscription.unsubscribe();
    this.curationSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
  }
}
