import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PacksComponent } from './packs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared.module';
import { PackdetailesComponent } from './packdetailes/packdetailes.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [
    PacksComponent,
    PackdetailesComponent,
  ]
})
export class PacksModule { }
