import { Action } from '@ngrx/store';


export enum ERequestActions {
    SetRequesting = '[Request] SetRequesting',
    RemoveRequesting = '[Request] RemoveRequesting',
}

export class Setrequesting implements Action {
    public readonly type = ERequestActions.SetRequesting;
    constructor(){}
}

export class RemoveRequesting implements Action {
    public readonly type = ERequestActions.RemoveRequesting;
    constructor(){}
}


export type RequestActions = 
Setrequesting | 
RemoveRequesting;