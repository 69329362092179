export enum StatusStringEnum {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
  NOT_YET_STARTED = 'NOT_YET_STARTED',
  DONE = 'DONE',
  NOT_YET_CONFIRMED = 'NOT_YET_CONFIRMED',
  PENDING = 'PENDING',
  DRAFT = 'DRAFT'
}
