import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/store/states/app.state';
import { IUser } from '../models/user/user';


@Injectable({ providedIn: 'root' })
export class GuestGuard  {

    /**
     * 
     */
    private authenticated:boolean = false;

    /**
     * 
     * @param router 
     * @param _store 
     */
    constructor(private router: Router, private _store:Store<IAppState>) {
        this._store.select('user').subscribe((user:IUser) => {
            this.authenticated = user.authenticated;
        });
    }

    /**
     * 
     * @param route 
     * @param state 
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.authenticated) {
            /**
             * @todo /dashboard to be app settings constant which is easier to modify
             */
            this.router.navigate(['/dashboard']);
        }
        return this.authenticated ? false : true;
    }
}